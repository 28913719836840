import React, { FC, memo } from "react";
import { BaseTooltipProps } from "@fenx/styleguide";
import { FenLabel } from "@fenx/styleguide/components/FenLabel";
import { Box, makeStyles } from "@material-ui/core";
import { commonColors } from "../assets/themes/defaultTheme";
import { FenHelpIcon } from "./FenHelpIcon";

/**
 * FenTitle properties.
 */
export interface FenTitleProps {
  /** Assign a custom class for the component */
  className?: string;
  /** Title to be shown inside the panel. */
  title: string;
  /** Tooltip props **/
  tooltipProps?: BaseTooltipProps;
  /** Additional item(s) to be displayed after title and tooltip icon **/
  addons?: React.ReactNode;
  required?: boolean;
}
const useStyles = makeStyles({
  title: { marginRight: "1rem", color: commonColors.fenxLabelBlue },
  asterisk: {
    color: "#d91919",
    marginRight: "1rem",
  },
});
const FenTitleBase: FC<FenTitleProps> = ({ title, className, tooltipProps = {}, required }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={className}>
      <FenLabel label={title} className={classes.title} required={required} />

      <FenHelpIcon {...tooltipProps} />
    </Box>
  );
};
export const FenTitle = memo(FenTitleBase);
