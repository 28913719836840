import React from "react";
import { FenTitle } from "@fenx/styleguide";
import { FilledInputProps, InputProps, makeStyles, OutlinedInputProps } from "@material-ui/core";
import { FenInput } from "./FenInput";

type TextInputType = HTMLInputElement | HTMLTextAreaElement;

/**
 * AdditionalCommentsProps properties.
 */
export interface AdditionalCommentsProps {
  /** The value of the `input` element. */
  value: any;
  /** Is component disabled . */
  disabled?: boolean;
  /** Handler that is applied to the root DOM element and fired upon key press */
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
  /** Handler that is applied to the root DOM element and fired upon changes in element */
  onChange?: (updateEvent: React.ChangeEvent<TextInputType>) => void;
  /** Handler that is applied to the root DOM element and fired upon blur changes */
  onBlur?: (updateEvent: React.FocusEvent<TextInputType>) => void;
  /** Handler that is applied to the root DOM element and fired upon focus changes */
  onFocus?: (updateEvent: React.FocusEvent<TextInputType>) => void;
  /** Assign a custom class for the text input */
  className?: string;
  variant?: "standard" | "outlined" | "filled";
  inputProps?: FilledInputProps | OutlinedInputProps | InputProps;
}

export const AdditionalComments = ({
  value,
  onKeyPress,
  onChange,
  onBlur,
  onFocus,
  disabled,
  className,
  variant,
  inputProps,
}: AdditionalCommentsProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      <FenTitle title={"Additional Comments"} variant={"h3"} className={classes.headerStyle} />
      <FenInput
        fullWidth={true}
        name={"additionalComments"}
        value={value}
        required={true}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        data-testid={"additional-comments"}
        margin="dense"
        multiline={true}
        rows={6}
        rowsMax={6}
        className={className}
        variant={variant || "outlined"}
        inputPros={inputProps}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  headerStyle: {
    marginTop: "1rem",
  },
}));
