import {
  ClientTenantRequestDto,
  ClientUpdateTenantRequestDto,
  InternalTenantRequestDto,
  InternalUpdateTenantRequestDto,
  TenantRequestStatusDto,
} from "fenergo-nebula-cloudcontrol-command-api";
import { TenantRequestDto } from "fenergo-nebula-cloudcontrol-query-api";
import {
  getTenantRequestStatusDisplayValue,
  getTenantRequestStatusStoredValue,
} from "../constants/TenantRequestStatus";
import { addDays } from "../utils/DateHelper";
import { TenantRequestStatusViewModel } from "../viewModels/tenantRequest/columns/TenantRequestStatusViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";

export class TenantRequestMapping {
  public static mapViewModelToClientTenantRequestDto = (
    model: TenantRequestViewModel
  ): ClientTenantRequestDto => {
    const baseModel: ClientTenantRequestDto = this.mapViewModelToBaseRequestDto(model);
    baseModel.businessLines = model.businessLine ?? undefined;
    baseModel.hasContractBeenSigned = model.contractSigned ?? undefined;
    baseModel.hasContractBeenSentToCBO = model.contractSentToCbo ?? "No";
    baseModel.customerSize = model.customerSize ?? undefined;

    baseModel.isSSORequired = model.ssoRequired ?? "No";
    baseModel.ssoProvider = model.providers ?? undefined;
    baseModel.isBYOKRequired = model.byokRequired ?? "No";
    baseModel.keyARN = model.arn ?? undefined;
    baseModel.internalManagedKey = model.internalManagedKey ?? undefined;
    return baseModel;
  };

  public static mapViewModelToInternalTenantRequestDto = (
    model: TenantRequestViewModel
  ): InternalTenantRequestDto => this.mapViewModelToBaseRequestDto(model);

  private static readonly mapViewModelToBaseRequestDto = (model: TenantRequestViewModel) => ({
    id: model.tenantRequestId ?? undefined,
    requestType: model.requestType ?? undefined,
    tenantOwnerId: model.tenantOwnerId ?? undefined,
    tenantOwnerName: model.clientTeamName ?? undefined,
    environmentCollection: model.tenantEnvironment ?? [],
    tenantName: model.clientTeamName ?? undefined,
    approverId: model.approverId ?? undefined,
    department: model.departmentCostTaggedAgainst ?? undefined,
    awsRegion: model.awsRegion ?? undefined,
    customisedAwsRegion: model.otherAwsRegion ?? undefined,
    importInitialConfig: model.initialConfigToBeImported ?? undefined,
    configurations: model.configurations ?? [],
    portTenantRequired: model.portalTenant ?? undefined,
    saleForceIntegrationRequired: model.salesforceRequired ?? undefined,
    urgency: model.urgentRequest ?? "Standard",
    urgencyReason: model.urgencyReason ?? undefined,
    isEsignatureRequired: model.eSignatureRequired ?? undefined,
    dueDate: addDays(new Date(), 10),
    additionalComments: model.additionalComments ?? undefined,
  });

  public static mapDtoToViewModel = (dto?: TenantRequestDto): TenantRequestViewModel => {
    if (!dto) {
      return new TenantRequestViewModel();
    }
    const viewModel = new TenantRequestViewModel();
    viewModel.requestType = dto.requestType;
    viewModel.tenantType = dto.tenantType ?? "";
    viewModel.tenantRequestId = dto.id;
    viewModel.status = getTenantRequestStatusDisplayValue(dto.status);
    viewModel.requestor = dto.requestorName;
    viewModel.requestorId = dto.requestorId;
    viewModel.dueDate = dto.dueDate;
    viewModel.tenantOwnerId = dto.tenantOwnerId;
    viewModel.contractSentToCbo = dto.hasContractBeenSentToCBO;
    viewModel.tenantEnvironment = dto.environmentCollection;
    viewModel.approverId = dto.approverId;
    viewModel.departmentCostTaggedAgainst = dto.department;
    viewModel.additionalComments = dto.additionalComments;
    viewModel.requestorEmail = dto.requestorEmail;
    viewModel.actionedBy = dto.actionByUserName;
    viewModel.clientTeamName = dto.tenantOwnerName;
    viewModel.businessLine = dto.businessLines;
    viewModel.contractSigned = dto.hasContractBeenSigned;
    viewModel.customerSize = dto.customerSize;
    viewModel.ssoRequired = dto.isSSORequired;
    viewModel.providers = dto.ssoProvider;
    viewModel.byokRequired = dto.isBYOKRequired;
    viewModel.arn = dto.keyARN;
    viewModel.internalManagedKey = dto.internalManagedKey;
    viewModel.approverOfTheDepartment = dto.approverName;
    viewModel.approverUserId = dto.approverUserId;
    viewModel.awsRegion = dto.awsRegion;
    viewModel.otherAwsRegion = dto.customisedAwsRegion;
    viewModel.initialConfigToBeImported = dto.importInitialConfig;
    viewModel.configurations = dto.configurations;
    viewModel.portalTenant = dto.portTenantRequired;
    viewModel.salesforceRequired = dto.saleForceIntegrationRequired;
    viewModel.urgentRequest = dto.urgency;
    viewModel.urgencyReason = dto.urgencyReason;
    viewModel.eSignatureRequired = dto.isEsignatureRequired;
    viewModel.tenantOwnerStatus = dto.tenantOwnerStatus;
    return viewModel;
  };

  public static mapViewModelToInternalUpdateTenantRequestDto = (
    model: TenantRequestViewModel
  ): InternalUpdateTenantRequestDto => {
    const baseModel: InternalUpdateTenantRequestDto = this.mapViewModelToBaseRequestDto(model);
    return baseModel;
  };

  public static mapViewModelToClientUpdateTenantRequestDto = (
    model: TenantRequestViewModel
  ): ClientUpdateTenantRequestDto => {
    const baseModel: ClientUpdateTenantRequestDto = this.mapViewModelToBaseRequestDto(model);
    baseModel.businessLines = model.businessLine ?? undefined;
    baseModel.hasContractBeenSigned = model.contractSigned ?? undefined;
    baseModel.hasContractBeenSentToCBO = model.contractSentToCbo ?? "No";
    baseModel.customerSize = model.customerSize ?? undefined;
    baseModel.isSSORequired = model.ssoRequired ?? "No";
    baseModel.ssoProvider = model.providers ?? undefined;
    baseModel.isBYOKRequired = model.byokRequired ?? "No";
    baseModel.keyARN = model.arn ?? undefined;
    baseModel.internalManagedKey = model.internalManagedKey ?? undefined;
    return baseModel;
  };

  public static mapViewModelToTenantRequestStatusDto = (
    model: TenantRequestStatusViewModel
  ): TenantRequestStatusDto => ({
    id: model.tenantRequestId ?? undefined,
    status: model.status ? getTenantRequestStatusStoredValue(model.status) : undefined,
  });
}
