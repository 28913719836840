import React from "react";
import { Tooltip, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { CellProps, Column } from "react-table";
import { ReactComponent as LogoIcon } from "../../../assets/images/logo.svg";
import { StyledFenLink } from "../../../components/StyledFenLink";
import { TenantOwnerCell } from "../../../components/TenantOwnerCell";
import { TenantStatusCell } from "../../../components/TenantStatusCell";
import { routes } from "../../../routes";
import { TenantEnvironmentViewModel } from "../TenantEnvironmentViewModel";

const getColumnCell = (
  isLoading: boolean,
  component: string | JSX.Element
): string | JSX.Element => {
  if (isLoading) {
    return <Skeleton />;
  }

  return component;
};

export const getTenantListTableColumns = (
  isLoading: boolean
): Column<TenantEnvironmentViewModel>[] => {
  const twentyPercentWidth = "20%";
  const twelvePercentWidth = "12%";
  return [
    tenantOwnerColumn(isLoading, twentyPercentWidth),
    nameColumn(isLoading, twentyPercentWidth),
    statusColumn(isLoading, twelvePercentWidth),
    purposeColumn(isLoading, twelvePercentWidth),
    environmentColumn(isLoading, twelvePercentWidth),
    awsRegionColumn(isLoading, twelvePercentWidth),
    lastActivityColumn(isLoading, twelvePercentWidth),
    tenantIdColumn(isLoading),
  ];
};

export const getTenantSummaryTableColumns = (
  isLoading: boolean
): Column<TenantEnvironmentViewModel>[] => {
  return [
    nameColumn(isLoading),
    purposeColumn(isLoading),
    environmentColumn(isLoading),
    awsRegionColumn(isLoading),
    lastActivityColumn(isLoading),
  ];
};

type ColumnWidthType = number | string | undefined;

const tenantOwnerColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> =>
  ({
    accessor: "tenantOwnerName" as const,
    Header: "Client/Team Name",
    width: width,
    Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
      getColumnCell(
        isLoading,
        <TenantOwnerCell
          tenantOwnerId={row.original.tenantOwnerId}
          tenantOwnerName={row.original.tenantOwnerName}
          tenantOwnerType={row.original.tenantOwnerType}
        />
      ),
    sortType: "alphanumericNullable",
  } as Column<TenantEnvironmentViewModel>);

const statusColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> =>
  ({
    accessor: "status" as const,
    Header: "Status",
    Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
      getColumnCell(isLoading, <TenantStatusCell tenantStatus={row.original.status} />),
    sortType: "alphanumericNullable",
    width: width,
  } as Column<TenantEnvironmentViewModel>);

const nameColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> =>
  ({
    accessor: "tenantName" as const,
    Header: "Tenant Name",
    Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
      getColumnCell(
        isLoading,
        <Box component="div" display={"flex"}>
          <StyledFenLink
            to={`${routes.tenantProfilePage}/${row.original.tenantId}`}
            text={row.original.tenantName?.trim() || "-"}
          />
          <Tooltip title={`${row.original.tenantId}`}>
            <Box component="span" width="1/4" display={"flex"} marginLeft={1}>
              <LogoIcon />
            </Box>
          </Tooltip>
        </Box>
      ),
    sortType: "alphanumericNullable",
    width: width,
  } as Column<TenantEnvironmentViewModel>);

const purposeColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> => ({
  Header: "Purpose",
  accessor: "purpose",
  width: width,
  Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
    getColumnCell(isLoading, row.original.purpose?.trim() || "-"),
});

const environmentColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> => ({
  Header: "Environment",
  accessor: "environment",
  width: width,
  Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
    getColumnCell(isLoading, row.original.environment?.trim() || "-"),
});

const lastActivityColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> => ({
  Header: "Last Activity",
  accessor: "lastActivity",
  width: width,
  Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
    getColumnCell(isLoading, row.original.lastActivityString?.trim() || "-"),
});

const awsRegionColumn = (
  isLoading: boolean,
  width?: ColumnWidthType
): Column<TenantEnvironmentViewModel> => ({
  Header: "AWS Region",
  accessor: "awsRegion",
  width: width,
  Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
    getColumnCell(isLoading, row.original.awsRegion?.trim() || "-"),
});

const tenantIdColumn = (isLoading: boolean): Column<TenantEnvironmentViewModel> => ({
  Header: "Tenant Id",
  accessor: "tenantId",
  Cell: ({ row }: CellProps<TenantEnvironmentViewModel>) =>
    getColumnCell(isLoading, row.original.tenantId?.trim() || "-"),
});
