import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { commonColors } from "../assets/themes/defaultTheme";
import { getTenantStatusChipColor } from "../utils/StyleHelper";

export interface TenantStatusCellProps {
  tenantStatus?: string;
  chipColor?: string;
}

export const TenantStatusCell = ({
  tenantStatus,
  chipColor,
}: TenantStatusCellProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    chip: {
      color: commonColors.white,
      backgroundColor: chipColor || getTenantStatusChipColor(tenantStatus),
      width: "5rem",
      height: "1.5rem",
      marginLeft: "-0.875rem",
      fontFamily: "NunitoSans",
      fontSize: "0.75rem",
      fontWeight: 800,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "center",
      borderRadius: "0.25rem",
    },
  }));
  const classes = useStyles();

  return (
    <>
      {tenantStatus ? (
        <Chip label={`${tenantStatus}`} className={classes.chip} size="small" />
      ) : (
        "-"
      )}
    </>
  );
};
