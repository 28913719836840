import React from "react";
import { Avatar } from "@material-ui/core";

interface IAvatarDisplay {
  userProfilePic: string;
  className?: string;
  avatarContent: string;
}

export const AvatarDisplay: React.FC<IAvatarDisplay> = ({
  userProfilePic,
  className,
  avatarContent,
}: IAvatarDisplay) => {
  return (
    <Avatar
      alt="User Profile Picture"
      src={userProfilePic}
      className={className}
      data-testid="mui-avatar"
    >
      {avatarContent}
    </Avatar>
  );
};
