import React, { useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { useAppConfig } from "../../hooks/useAppConfig";
import { routes } from "../../routes";
import { AuthConfiguration } from "./AuthConfiguration";

export const Login: React.FC = () => {
  const history = useHistory();
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const config = useAppConfig();

  // to address in tech item
  useEffect(() => {
    const authConfig = new AuthConfiguration(
      config?.azureActiveDirectoryClientId,
      config?.azureActiveDirectoryTenatnId,
      config?.scopes,
      config?.redirectUri
    );

    if (!isAuthenticated && inProgress === InteractionStatus.HandleRedirect) {
      return;
    }

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(authConfig.getLoginRequest);
    }

    if (isAuthenticated && inProgress === InteractionStatus.None) {
      history.push(routes.home);
    }
  }, [
    config?.azureActiveDirectoryClientId,
    config?.azureActiveDirectoryTenatnId,
    config?.scopes,
    config?.redirectUri,
    history,
    inProgress,
    instance,
    isAuthenticated,
  ]);

  return null;
};
