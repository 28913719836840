import { SelectListItemDto } from "fenergo-nebula-cloudcontrol-query-api";
import { SelectListItemViewModel } from "../viewModels/tenantRequestForm/SelectListItemViewModel";

export const mapToSelectListItemViewModel = (dto?: SelectListItemDto): SelectListItemViewModel => {
  const viewModel = new SelectListItemViewModel();
  if (!dto) {
    return viewModel;
  }

  viewModel.Text = dto.text;
  viewModel.Value = dto.value;
  return viewModel;
};
