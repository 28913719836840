export enum RequestFormLabels {
  FormTitle = "Request Details",
  TeamName = "Team Name",
  ClientName = "Client Name",
  BusinessLine = "Business Line",
  ContractSigned = "Has contract been signed?",
  ContractSent = "Has contract been sent to CBO?",
  Department = "What department will the cost be tagged against?",
  Approver = "Approver of the department",
  CustomerSize = "Customer Size",
  TenantEnvironment = "Tenant Environment",
  TenantName = "Tenant Name",
  AwsRegion = "AWS Region",
  OtherRegion = "If other, please specify",
  OtherRegionToolTip = "Fen-x is currently available in the regions listed above, your specified region will be considered for our future improvement",
  SsoRequired = "Is SSO Required?",
  Providers = "Who are the providers?",
  BYOK = "Will the customer use BYOK (Bring Your Own Key)?",
  ByokToolTip = "BYOK can be updated later if required",
  Arn = "What is ARN of BAU key and ARN of backup key?",
  InternalKey = "Internal managed key",
  InitialConfigToBeImported = "Initial config to be imported?",
  InitialConfigRequired = "Initial config required",
  PortTenantRequired = "Is Portal Tenant required?",
  SaleforceRequired = "Is Salesforce integration required?",
  EsignatureRequired = "Is eSignature required?",
  Urgency = "Is this an urgent request?",
  UrgencyReason = "Urgency Reason",
  ExistingClient = "Existing Client",
  ExistingTeam = "Existing Team",
  PortalCostIncluded = "Portal cost included in contract?",
}
