import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { commonColors } from "../assets/themes/defaultTheme";
import { getTenantStatusChipColor } from "../utils/StyleHelper";

export interface TenantStatusChipProps {
  tenantStatus?: string;
  "data-testid"?: string;
}

export const TenantStatusChip = ({
  tenantStatus,
  "data-testid": testid,
}: TenantStatusChipProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    chip: {
      color: commonColors.white,
      backgroundColor: getTenantStatusChipColor(tenantStatus),
      width: "6rem",
      height: "2rem",
      margin: "2rem 0 0 2rem",
      fontFamily: "NunitoSans",
      fontSize: "1rem",
      fontWeight: 800,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "center",
      borderRadius: "0.25rem",
    },
  }));
  const classes = useStyles();

  return (
    <Chip label={`${tenantStatus}`} className={classes.chip} size="small" data-testid={testid} />
  );
};
