import React, { useCallback, useState } from "react";
import { FenDropdown, FenTextInput } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core/styles";
import { LookupType } from "../../constants/LookupType";
import { TenantType } from "../../constants/TenantType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { convertToFenListItem } from "../../utils/FenListItemsHelper";
import {
  defaultFormikDropdownFieldWithErrors,
  formikFieldWithErrors,
} from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName, getTenantOwnerSafeName } from "../../utils/stringHelper";
import { ApproverViewModel } from "../../viewModels/approver/ApproverViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenccDropdown } from "../FenccDropdown";
import { FenList, FenListItem } from "../FenList";
import { getFilteredApprovers } from "./ApproverFilter";
import { RequestFormLabels } from "./RequestFormLabels";

export interface ITenantRequestFormClientSection {
  staticLookup: LookupViewModel;
  formik: any;
  tenantOwners: TenantOwnerViewModel[];
  approvers: ApproverViewModel[];
  clientName: string;
}
export const TenantRequestFormClientSection = ({
  staticLookup,
  formik,
  tenantOwners,
  approvers,
  clientName,
}: ITenantRequestFormClientSection): JSX.Element => {
  const classes = useStyles();
  const { values, setFieldValue, errors, touched } = formik;

  const [environmentList, setEnvironmentList] = useState<FenListItem[]>();
  const [disableDepartment, setDisableDepartment] = useState<boolean>(
    values?.departmentCostTaggedAgainst !== undefined
  );

  const resetEnvironmentListObject = () => {
    const updatedList = environmentList;
    updatedList?.forEach((x) => (x.description = ""));
    setEnvironmentList(updatedList);
    setDisableDepartment(false);
  };

  const resetWholeForm = () => {
    resetEnvironmentListObject();
    formik.resetForm();
  };

  const handleExistingClientInputChange = (value: any, propertyName: string) => {
    if (values.existingClient !== value && values.existingClient) {
      resetWholeForm();
    }

    if (value === YesNoQuestion.No && values.tenantType === TenantType.Internal) {
      setFieldValue("contractSigned", YesNoQuestion.No);
      setFieldValue("contractSentToCbo", YesNoQuestion.No);
      setFieldValue("businessLine", []);
    }

    if (value === YesNoQuestion.No) {
      setFieldValue("tenantOwnerId", null);
    }

    formik.setFieldValue(propertyName, value);
  };

  const handleClientChange = (formikValue: any, propertyName: string) => {
    const selectedClient = tenantOwners?.find((x) => x.id === formikValue);
    setFieldValue("clientTeamName", selectedClient?.name ?? null);
    setFieldValue("customerSize", selectedClient?.customerSize ?? null);
    setFieldValue("businessLine", selectedClient?.businessLines ?? null);
    setFieldValue("contractSigned", selectedClient?.hasContractBeenSigned ?? null);
    setFieldValue("departmentCostTaggedAgainst", selectedClient?.department ?? null);
    if (selectedClient?.department) {
      setDisableDepartment(true);
    } else {
      setDisableDepartment(false);
    }
    formik.setFieldValue(propertyName, formikValue);
  };

  const handleBusinessLineChange = (formikValue: any, propertyName: string) => {
    setFieldValue(propertyName, formikValue);
  };

  const handleContractSignedChange = (value: any, propertyName: string) => {
    if (values.contractSigned !== value && values.contractSigned) {
      formik.setFieldValue("contractSentToCbo", null);
      formik.setFieldValue("departmentCostTaggedAgainst", null);
    }
    formik.setFieldValue(propertyName, value);
  };

  const handleDepartmentChanged = (value: any, propertyName: any) => {
    formik.setFieldValue("approverId", null);
    formik.setFieldValue("approverOfTheDepartment", null);
    formik.setFieldValue(propertyName, value);
  };

  const handleApproverChange = (value: any, propertyName: string) => {
    formik.setFieldValue("approverOfTheDepartment", approvers.find((a) => a.Id === value)?.Name);
    formik.setFieldValue(propertyName, value);
  };

  const handleEnvironmentCheckboxChange = useCallback(
    (_name: string, item: FenListItem) => {
      if (!values.tenantEnvironment) {
        values.tenantEnvironment = [];
      }

      if (values.tenantEnvironment.includes(item.id)) {
        values.tenantEnvironment = values.tenantEnvironment.filter((v: string) => v !== item.id);
      } else {
        values.tenantEnvironment.push(item.id);
      }

      setFieldValue("tenantEnvironment", values.tenantEnvironment);
    },
    [setFieldValue, values]
  );
  return (
    <>
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={
          values.tenantType === TenantType.Internal
            ? RequestFormLabels.ExistingTeam
            : RequestFormLabels.ExistingClient
        }
        name="existingClient"
        required
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        data-testid="existing-client-dropdown"
        error={touched.existingClient ? errors.existingClient : ""}
        {...defaultFormikDropdownFieldWithErrors(
          formik,
          "existingClient",
          handleExistingClientInputChange
        )}
      />
      {values.existingClient === YesNoQuestion.Yes && (
        <FenDropdown
          className={classes.infoField}
          fullWidth
          label={
            values.tenantType === TenantType.Internal
              ? RequestFormLabels.TeamName
              : RequestFormLabels.ClientName
          }
          name="tenantOwnerId"
          required
          options={tenantOwners}
          valueKey="id"
          getOptionLabel={(x) => getTenantOwnerSafeName(x)}
          error={touched.tenantOwnerId ? errors.tenantOwnerId : ""}
          data-testid="client-name"
          {...defaultFormikDropdownFieldWithErrors(formik, "tenantOwnerId", handleClientChange)}
        />
      )}
      {values.existingClient === YesNoQuestion.No && (
        <FenTextInput
          className={classes.infoField}
          fullWidth
          name="clientTeamName"
          required
          data-testid="client-name"
          label={
            values.tenantType === TenantType.Internal
              ? RequestFormLabels.TeamName
              : RequestFormLabels.ClientName
          }
          {...formikFieldWithErrors(formik, "clientTeamName")}
        />
      )}
      {values.tenantType === TenantType.Client && (
        <>
          <FenccDropdown
            className={classes.infoField}
            disabled={values.existingClient === YesNoQuestion.Yes}
            options={getSpecificSelectListItemViewModel(staticLookup, LookupType.BusinessLine)}
            selectedValue={values.businessLine ?? ""}
            label={RequestFormLabels.BusinessLine}
            name={"businessLine"}
            errors={touched.businessLine ? errors.businessLine : ""}
            required
            multiple
            data-testid="business-line"
            fullWidth
            valueKey="Value"
            displayKey="Text"
            getOptionLabel={(x) => getSelectItemSafeName(x)}
            {...defaultFormikDropdownFieldWithErrors(
              formik,
              "businessLine",
              handleBusinessLineChange
            )}
          />
          <FenccDropdown
            className={classes.infoField}
            disabled={values.existingClient === YesNoQuestion.Yes}
            options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
            selectedValue={values.contractSigned ?? ""}
            label={RequestFormLabels.ContractSigned}
            name="contractSigned"
            errors={touched.contractSigned ? errors.contractSigned : ""}
            required
            fullWidth
            data-testid="contract-signed"
            valueKey="Value"
            displayKey="Text"
            getOptionLabel={(x) => getSelectItemSafeName(x)}
            {...defaultFormikDropdownFieldWithErrors(
              formik,
              "contractSigned",
              handleContractSignedChange
            )}
          />
          {values.contractSigned === YesNoQuestion.Yes && (
            <FenDropdown
              className={classes.infoField}
              fullWidth
              label={RequestFormLabels.ContractSent}
              name="contractSentToCbo"
              required
              options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
              valueKey="Value"
              getOptionLabel={(x) => getSelectItemSafeName(x)}
              data-testid="contract-sent-to-cbo"
              {...defaultFormikDropdownFieldWithErrors(formik, "contractSentToCbo")}
            />
          )}
        </>
      )}

      {(values.contractSigned === YesNoQuestion.No ||
        values.tenantType === TenantType.Internal) && (
        <>
          <FenccDropdown
            className={classes.infoField}
            selectedValue={values.departmentCostTaggedAgainst ?? ""}
            fullWidth
            disabled={disableDepartment}
            label={RequestFormLabels.Department}
            name="departmentCostTaggedAgainst"
            required
            options={getSpecificSelectListItemViewModel(staticLookup, LookupType.Department)}
            valueKey="Value"
            displayKey="Text"
            getOptionLabel={(x) => getSelectItemSafeName(x)}
            data-testid="department-cost-tagged"
            errors={touched.departmentCostTaggedAgainst ? errors.departmentCostTaggedAgainst : ""}
            {...defaultFormikDropdownFieldWithErrors(
              formik,
              "departmentCostTaggedAgainst",
              handleDepartmentChanged
            )}
          />
          <FenccDropdown
            selectedValue={values.approverId ?? ""}
            className={classes.infoField}
            fullWidth
            label={RequestFormLabels.Approver}
            name="approverId"
            required
            options={getFilteredApprovers(approvers, values.departmentCostTaggedAgainst)}
            valueKey="Id"
            getOptionLabel={(x) => x.Name}
            data-testid="department-approver"
            errors={touched.approverId ? errors.approverId : ""}
            {...defaultFormikDropdownFieldWithErrors(formik, "approverId", handleApproverChange)}
          />
        </>
      )}

      {values.tenantType === TenantType.Client && (
        <FenccDropdown
          className={classes.infoField}
          disabled={values.existingClient === YesNoQuestion.Yes}
          options={getSpecificSelectListItemViewModel(staticLookup, LookupType.CustomerSize)}
          selectedValue={values.customerSize ?? ""}
          label={RequestFormLabels.CustomerSize}
          name="customerSize"
          required
          data-testid="customer-size"
          fullWidth
          valueKey="Value"
          displayKey="Text"
          getOptionLabel={(x) => getSelectItemSafeName(x)}
          errors={touched.customerSize ? errors.customerSize : ""}
          {...defaultFormikDropdownFieldWithErrors(formik, "customerSize")}
        />
      )}

      <FenList
        required
        className={classes.infoField}
        items={convertToFenListItem("Purpose", staticLookup, clientName)}
        title={RequestFormLabels.TenantEnvironment}
        name={"tenantEnvironment"}
        enableSelection={true}
        onSelect={handleEnvironmentCheckboxChange}
        selectedIds={values.tenantEnvironment ?? []}
        clientName={values.clientTeamName ?? ""}
        error={touched.tenantEnvironment ? errors.tenantEnvironment : ""}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: "1rem",
  },
  infoField: {
    marginBottom: "1rem",
  },
}));
