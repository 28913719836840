import { FenxServiceDto } from "fenergo-nebula-cloudcontrol-query-api";
import { FenxServiceStatus } from "../constants/FenxServiceStatus";
import { TenantFenxServicesViewModel } from "../viewModels/tenant/tenantConfig/TenantFenxServicesViewModel";

export const mapToFenxServicesViewModel = (
  fenxServicesDto?: FenxServiceDto[]
): TenantFenxServicesViewModel[] => {
  if (!fenxServicesDto) {
    return [];
  }

  return fenxServicesDto?.map(
    (x) =>
      new TenantFenxServicesViewModel(
        x.id,
        x.name,
        x.isActive ? FenxServiceStatus.Active : FenxServiceStatus.Inactive,
        x.fenxServiceType
      )
  );
};
