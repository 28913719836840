import { TenantUserExtensionDto } from "fenergo-nebula-cloudcontrol-query-api";
import { TenantUserExtensionViewModel } from "../viewModels/tenant/tenantUser/TenantUserExtensionViewModel";

export const mapTenantUserExtensionDtoToViewModel = (
  tenantUserExtensionDto?: TenantUserExtensionDto
): TenantUserExtensionViewModel => {
  const viewModel = new TenantUserExtensionViewModel();
  if (!tenantUserExtensionDto) {
    return viewModel;
  }

  viewModel.userId = tenantUserExtensionDto.id;
  viewModel.userName = tenantUserExtensionDto.username;
  viewModel.userType = tenantUserExtensionDto.userType;
  viewModel.userEmail = tenantUserExtensionDto.email;
  return viewModel;
};
