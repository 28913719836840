import React from "react";
import { FenTitle } from "@fenx/styleguide";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formikFieldWithErrors } from "../../utils/formikFieldHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { AdditionalComments } from "../AdditionalComment";
import { ReviewNavigationButtons } from "../portalRequestForm/ReviewNavigationButtons";
import { FormReviewDetailsSection } from "./FormReviewDetailsSection";
import { FormReviewGeneralSection } from "./FormReviewGeneralSection";

export interface TenantRequestReviewFormProps {
  formik: any;
  callback: any;
  submitForm: any;
  staticLookup: LookupViewModel;
}

export const TenantRequestFormReviewPage = ({
  formik,
  callback,
  submitForm,
  staticLookup,
}: TenantRequestReviewFormProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <FenTitle title="Request Summary" variant="h1" className={classes.header} />
      <FormReviewGeneralSection formik={formik} />
      <FormReviewDetailsSection formik={formik} staticLookup={staticLookup} />
      <AdditionalComments {...formikFieldWithErrors(formik, "additionalComments")} />
      <ReviewNavigationButtons callback={callback} submitForm={submitForm} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4rem",
    marginBottom: "6rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  header: {
    marginBottom: "3rem",
  },
  subHeader: {
    marginBottom: "1rem",
  },
  button: {
    width: "15rem",
    minWidth: "10.5rem",
    maxWidth: "15rem",
    marginTop: "5rem",
    fontSize: "2rem",
    fontWeight: 700,
  },
  infoField: {
    marginBottom: "2rem",
  },
}));
