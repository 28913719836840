import React from "react";
import { FenTitle } from "@fenx/styleguide";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { commonColors } from "../../assets/themes/defaultTheme";
import { LookupType } from "../../constants/LookupType";
import { TenantType } from "../../constants/TenantType";
import { UrgencyStatus } from "../../constants/UrgencyStatus";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { EnvironmentNameParser } from "../../utils/EnvironmentNameParser";
import { convertToReviewPageFormat } from "../../utils/lookupHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenReadonlyInput } from "../FenReadonlyInput";
import { RequestFormLabels } from "./RequestFormLabels";

export interface IFormReviewDetailsSection {
  formik: any;
  staticLookup: LookupViewModel;
}
export const FormReviewDetailsSection = ({
  formik,
  staticLookup,
}: IFormReviewDetailsSection): JSX.Element => {
  const classes = useStyles();
  const { values } = formik;

  return (
    <>
      <FenTitle title={RequestFormLabels.FormTitle} variant="h3" className={classes.subHeader} />
      <Box className={classes.boxContainer}>
        <FenReadonlyInput
          label={
            values.tenantType === TenantType.Internal
              ? RequestFormLabels.TeamName
              : RequestFormLabels.ClientName
          }
          value={values.clientTeamName}
          required
          fullWidth
          multiline
        />

        {values.tenantType === TenantType.Client && (
          <>
            <FenReadonlyInput
              fullWidth
              multiline
              label={RequestFormLabels.BusinessLine}
              value={convertToReviewPageFormat(
                staticLookup,
                LookupType.BusinessLine,
                values.businessLine
              )}
              required
            />
            <FenReadonlyInput
              fullWidth
              multiline
              label={RequestFormLabels.ContractSigned}
              value={values.contractSigned}
              required
            />

            {values.contractSigned === YesNoQuestion.Yes && (
              <FenReadonlyInput
                fullWidth
                multiline
                label={RequestFormLabels.ContractSent}
                value={values.contractSentToCbo}
                required
              />
            )}
          </>
        )}

        {values.contractSigned === YesNoQuestion.No && (
          <>
            <FenReadonlyInput
              fullWidth
              multiline
              label={RequestFormLabels.Department}
              value={convertToReviewPageFormat(
                staticLookup,
                LookupType.Department,
                values.departmentCostTaggedAgainst
              )}
              required
            />
            <FenReadonlyInput
              label={RequestFormLabels.Approver}
              value={values.approverOfTheDepartment}
              required
              fullWidth
              multiline
            />
          </>
        )}

        {values.tenantType === TenantType.Client && (
          <FenReadonlyInput
            fullWidth
            multiline
            label={RequestFormLabels.CustomerSize}
            value={convertToReviewPageFormat(
              staticLookup,
              LookupType.CustomerSize,
              values.customerSize
            )}
            required
          />
        )}

        <FenReadonlyInput
          fullWidth
          multiline
          label={RequestFormLabels.TenantEnvironment}
          value={convertToReviewPageFormat(
            staticLookup,
            LookupType.Purpose,
            values.tenantEnvironment
          )}
          required
        />
        <FenReadonlyInput
          fullWidth
          multiline
          label={RequestFormLabels.TenantName}
          value={EnvironmentNameParser(
            convertToReviewPageFormat(staticLookup, LookupType.Purpose, values.tenantEnvironment),
            values.clientTeamName
          )}
          required
        />
        <FenReadonlyInput
          fullWidth
          multiline
          label={RequestFormLabels.AwsRegion}
          value={convertToReviewPageFormat(staticLookup, LookupType.AwsRegion, values.awsRegion)}
          required
        />
        {values.awsRegion === "Other" && (
          <FenReadonlyInput
            fullWidth
            multiline
            label={RequestFormLabels.OtherRegion}
            value={values.otherAwsRegion}
            required
          />
        )}
        {values.tenantType === TenantType.Client && (
          <>
            <FenReadonlyInput
              label={RequestFormLabels.SsoRequired}
              value={values.ssoRequired}
              fullWidth
              multiline
            />
            {values.ssoRequired === YesNoQuestion.Yes && (
              <FenReadonlyInput
                label={RequestFormLabels.Providers}
                value={values.providers}
                required
                fullWidth
                multiline
              />
            )}
            <FenReadonlyInput
              label={RequestFormLabels.BYOK}
              value={values.byokRequired}
              fullWidth
              multiline
            />
            {values.byokRequired === YesNoQuestion.Yes && (
              <FenReadonlyInput
                label={RequestFormLabels.Arn}
                value={values.arn}
                required
                fullWidth
                multiline
              />
            )}
            {values.byokRequired === YesNoQuestion.No && values.internalManagedKey && (
              <FenReadonlyInput
                label={RequestFormLabels.InternalKey}
                value={values.internalManagedKey}
                fullWidth
                multiline
              />
            )}
          </>
        )}

        <FenReadonlyInput
          label={RequestFormLabels.InitialConfigToBeImported}
          value={values.initialConfigToBeImported}
          required
          fullWidth
          multiline
        />
        {values.initialConfigToBeImported === YesNoQuestion.Yes && (
          <FenReadonlyInput
            label={RequestFormLabels.InitialConfigRequired}
            fullWidth
            multiline
            value={convertToReviewPageFormat(
              staticLookup,
              LookupType.InitialImportConfig,
              values.configurations
            )}
            required
          />
        )}
        <FenReadonlyInput
          label={RequestFormLabels.PortTenantRequired}
          value={values.portalTenant}
          required
          fullWidth
          multiline
        />
        <FenReadonlyInput
          label={RequestFormLabels.SaleforceRequired}
          value={values.salesforceRequired}
          required
        />
        <FenReadonlyInput
          label={RequestFormLabels.EsignatureRequired}
          value={values.eSignatureRequired}
          required
          fullWidth
          multiline
        />
        <FenReadonlyInput
          label={RequestFormLabels.Urgency}
          value={values.urgentRequest}
          fullWidth
          multiline
        />
        {values.urgentRequest === UrgencyStatus.Urgent && (
          <FenReadonlyInput
            label={RequestFormLabels.UrgencyReason}
            value={values.urgencyReason}
            required
            fullWidth
            multiline
          />
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  boxContainer: {
    backgroundColor: commonColors.greyBlue,
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary,
    },
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  subHeader: {
    marginBottom: "1rem",
  },
}));
