import { TenantSummaryViewModel } from "../tenant/TenantSummaryViewModel";

export class TenantOwnerViewModel {
  id?: string;
  name?: string;
  type?: string;
  keyContact?: string;
  department?: string;
  tenantIds?: string[];
  tenantsSummary?: TenantSummaryViewModel[];
  businessLines?: string[];
  hasContractBeenSigned?: string;
  hasContractBeenSentToCBO?: string;
  customerSize?: string;
  approverId?: string;

  constructor() {
    this.tenantsSummary = [];
  }
}
