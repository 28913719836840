import { ApproverDto } from "fenergo-nebula-cloudcontrol-query-api";
import { ApproverViewModel } from "../viewModels/approver/ApproverViewModel";

export const mapToApproverViewModel = (dto?: ApproverDto): ApproverViewModel => {
  const viewModel = new ApproverViewModel();
  if (!dto) {
    return viewModel;
  }

  viewModel.Id = dto.id;
  viewModel.Name = dto.name ?? "";
  viewModel.Departments = dto.clientDepartments || [];

  return viewModel;
};
