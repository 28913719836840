import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { commonColors } from "../assets/themes/defaultTheme";
import { TenantRequestStatus } from "../constants/TenantRequestStatus";

export interface StatusChipCellProps {
  cellValue?: string;
  className?: string;
}

export const chipColours = (cellValue: string | undefined): string => {
  switch (cellValue) {
    case TenantRequestStatus.New:
      return commonColors.statusChipsNew;
    case TenantRequestStatus.InProgress:
      return commonColors.statusChipsInProgress;
    case TenantRequestStatus.Completed:
      return commonColors.statusChipsCompleted;
    case TenantRequestStatus.Rejected:
      return commonColors.error;
    case TenantRequestStatus.Archived:
      return commonColors.greysLightGrey1;
    case TenantRequestStatus.Approval:
      return commonColors.statusChipsMessagesOrangeWarning;
    case TenantRequestStatus.Approved:
      return commonColors.statusChipsNew;
    case TenantRequestStatus.RejectedByApprover:
      return commonColors.error;
  }
  return commonColors.statusChipsCompleted;
};

export const StatusChipCell = ({ cellValue, className }: StatusChipCellProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    chip: {
      color: commonColors.white,
      backgroundColor: chipColours(cellValue),
      padding: 3,
      height: 20,
      textAlign: "center",
      fontSize: "0.7rem",
      borderRadius: 3,
      minWidth: theme.spacing(12),
      minHeight: theme.spacing(3),
      fontWeight: 800,
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Chip label={`${cellValue}`} className={className || classes.chip} size="small" />
    </>
  );
};
