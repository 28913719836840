import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import MuiContainer from "@material-ui/core/Container";
import MuiTypography from "@material-ui/core/Typography";
import InProgressBackground from "../../assets/images/inProgressBackground.svg";

export const ReportingPage = (): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiContainer className={classes.container}>
      <Box className={classes.boxContainer}>
        <MuiTypography component="h1" variant="h1" align="left" className={classes.h1}>
          This feature is under construction
        </MuiTypography>
        <MuiTypography component="h1" variant="h1" align="left" className={classes.h1}>
          Check back soon!
        </MuiTypography>
      </Box>
      <img src={InProgressBackground} className={classes.image} data-testid="coming-soon-image" />
    </MuiContainer>
  );
};

const useStyles = makeStyles(() => ({
  h1: {
    fontSize: "2rem",
    background: "-webkit-linear-gradient(45deg, #60ABF9 30%, #A5B5C0 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  boxContainer: {
    marginTop: "8rem",
    padding: "1rem",
  },
  image: {
    width: "25rem",
    height: "25rem",
  },
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: "9rem",
    marginBottom: "9.5rem",
    backgroundSize: "cover",
  },
}));
