import React, { useState } from "react";
import { FenBaseModal, FenDropdown, FenFieldsGrid } from "@fenx/styleguide";
import { useHistory } from "react-router-dom";
import { RequestType } from "../../constants/RequestType";
import { TenantType } from "../../constants/TenantType";
import { routes } from "../../routes";
import { TenantRequestViewModel } from "../../viewModels/tenantRequest/TenantRequestViewModel";
import { UserInfoViewModel } from "../../viewModels/userInfo/UserInfoViewModel";

export interface TenantRequestModalProps {
  data?: TenantRequestViewModel | null;
  open: boolean;
  callback: any;
  userInfo: UserInfoViewModel;
}

export const TenantRequestModal: React.FC<TenantRequestModalProps> = ({
  data,
  open,
  callback,
  userInfo,
}) => {
  const [tenantRequestViewModel, setTenantRequestViewModel] = useState<TenantRequestViewModel>(
    data ?? new TenantRequestViewModel()
  );
  const history = useHistory();

  const redirectToRequestForm = () => {
    tenantRequestViewModel.requestType === RequestType.PortalCreation
      ? history.push(routes.newPortalRequest, {
          ...tenantRequestViewModel,
          ["requestor"]: userInfo.name,
          ["requestorEmail"]: userInfo.email,
        })
      : history.push(routes.newTenantRequest, {
          ...tenantRequestViewModel,
          ["requestor"]: userInfo.name,
          ["requestorEmail"]: userInfo.email,
        });
  };

  const tenantTypeList = [
    {
      label: "Client Tenant",
      value: TenantType.Client,
    },
    {
      label: "Internal Tenant",
      value: TenantType.Internal,
    },
  ];

  function handleDropdownChange(value: any, propertyName: string) {
    setTenantRequestViewModel({ ...tenantRequestViewModel, [propertyName]: value });
  }

  function handleMasterDropdownChange(value: any, propertyName: string) {
    if (value !== "") {
      setTenantRequestViewModel({ ...tenantRequestViewModel, [propertyName]: value });
    } else {
      setTenantRequestViewModel(new TenantRequestViewModel());
    }
  }

  function isSubmitButtonDisabled(): boolean {
    return !tenantRequestViewModel?.tenantType || !tenantRequestViewModel?.requestType;
  }

  function handleOnClose() {
    callback();
    setTenantRequestViewModel(new TenantRequestViewModel());
  }

  return (
    <>
      {open && (
        <FenBaseModal
          title="New Tenant Request"
          onClose={handleOnClose}
          disabled={isSubmitButtonDisabled()}
          onSubmit={redirectToRequestForm}
        >
          <FenFieldsGrid columns={1}>
            <FenDropdown
              fullWidth
              label="Select the request type"
              name="requestType"
              required
              options={[RequestType.TenantCreation, RequestType.PortalCreation]}
              value={tenantRequestViewModel?.requestType ?? ""}
              onChange={handleMasterDropdownChange}
              data-testid="request-type-dropdown"
            />
            {tenantRequestViewModel?.requestType && (
              <FenDropdown
                fullWidth
                label="Select the tenant type"
                name="tenantType"
                required
                options={tenantTypeList}
                value={tenantRequestViewModel?.tenantType ?? ""}
                onChange={handleDropdownChange}
                valueKey="value"
                getOptionLabel={(x) => x.label}
                data-testid="tenant-type-dropdown"
              />
            )}
          </FenFieldsGrid>
        </FenBaseModal>
      )}
    </>
  );
};
