import React from "react";
import { Icon, IconButton, ListItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { SideNavBarMenuItem } from "./models/SideNavBarMenu";
import { StyledTooltip } from "./SideNav";

export interface SideNavBarMenuProps {
  menuListItems: SideNavBarMenuItem[];
  listcontainerClassName: string;
  iconClassName: string;
  buttonClassName: string;
}

export const SideNavBarMenuList = ({
  menuListItems,
  listcontainerClassName,
  iconClassName,
  buttonClassName,
}: SideNavBarMenuProps): JSX.Element => {
  const history = useHistory();

  menuListItems.sort((a, b) => a.sortNumber - b.sortNumber);

  return (
    <div>
      {menuListItems.map((menuItem) => (
        <div key={menuItem.id}>
          <ListItem className={listcontainerClassName}>
            <StyledTooltip title={menuItem.title} placement="right">
              <IconButton
                aria-label={menuItem.label}
                aria-haspopup="true"
                color="inherit"
                className={buttonClassName}
                data-testid={menuItem.testId}
                onClick={() => {
                  history.push(menuItem.route);
                }}
              >
                <Icon component={menuItem.icon} className={iconClassName} />
              </IconButton>
            </StyledTooltip>
          </ListItem>
        </div>
      ))}
    </div>
  );
};
