import React, { FC, PropsWithChildren } from "react";
import {
  Typography,
  withStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ThemedTypography = withStyles(() => ({
  root: {
    fontWeight: 600,
  },
}))(Typography);

const ThemedAccordionDetails = withStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}))(AccordionDetails);

const ThemedAccordionSummary = withStyles({
  root: {
    "&$expanded": {
      minHeight: 0,
    },
    boxShadow: "none",
  },
  content: {
    marginTop: "1.4rem",
    marginBottom: 13,
    backgroundColor: "white",
    "&$expanded": {
      marginTop: 13,
      marginBottom: 13,
      minHeight: 0,
    },
  },
  expanded: {},
})(AccordionSummary);

export interface AccordionWrapperProps {
  title: string;
  defaultExpanded?: boolean;
}

export const AccordionWrapper: FC<PropsWithChildren<AccordionWrapperProps>> = ({
  children,
  title,
  defaultExpanded,
}) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <ThemedAccordionSummary expandIcon={<ExpandMoreIcon />}>
      <ThemedTypography variant="h2">{title}</ThemedTypography>
    </ThemedAccordionSummary>
    <ThemedAccordionDetails>{children}</ThemedAccordionDetails>
  </Accordion>
);
