import React, { useEffect } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";
interface ILogoutProps {
  userLoggedIn: boolean;
  userInfo: AccountInfo | null;
}

export const Logout: React.FC<ILogoutProps> = () => {
  const { accounts, instance } = useMsal();
  const history = useHistory();
  const logoutRequest = {
    postLogoutRedirectUri: "https://google.com",
  };
  // to address in tech item
  useEffect(() => {
    if (accounts.length > 0 && accounts != null) {
      instance.logoutRedirect(logoutRequest);
    } else {
      history.push(routes.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
