import { TenantEnvironmentViewModel } from "../viewModels/tenant/TenantEnvironmentViewModel";
import { TenantOwnerViewModel } from "../viewModels/tenantOwner/TenantOwnerViewModel";

export const MapTenantOwnerToTenantList = (
  tenantList: TenantEnvironmentViewModel[],
  tenantOwnerList: TenantOwnerViewModel[]
): TenantEnvironmentViewModel[] => {
  const mappedTenantList: TenantEnvironmentViewModel[] = [];

  tenantOwnerList.forEach((tenantOwner) => {
    tenantOwner.tenantsSummary?.forEach((tenantSummary) => {
      const tenant: TenantEnvironmentViewModel = {
        ...tenantSummary,
        tenantOwnerId: tenantOwner.id,
        tenantOwnerName: tenantOwner.name,
        tenantOwnerType: tenantOwner.type,
      };
      mappedTenantList.push(tenant);
    });
  });

  const mapppedTenantIds = tenantOwnerList
    .filter((x) => x.tenantIds?.length)
    .flatMap((x) => x.tenantIds);
  const unMappedTenantList = tenantList.filter((t) => !mapppedTenantIds.includes(t.tenantId));

  return [...mappedTenantList, ...unMappedTenantList];
};
