import React from "react";
import { BaseTooltipProps, FenTooltip } from "@fenx/styleguide";
import { makeStyles, Theme } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    marginRight: theme.spacing(2),
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(0.3),
  },
}));

export const FenHelpIcon = ({ tooltip, condition }: BaseTooltipProps): React.ReactElement => {
  const classes = useStyles();
  if (!tooltip || (condition !== undefined && !condition)) {
    return <>{null}</>;
  }

  return (
    <FenTooltip title={tooltip}>
      <HelpIcon data-testid="tooltip-icon" className={classes.tooltip} />
    </FenTooltip>
  );
};
