import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getShortName } from "@fenx/styleguide";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getAccessToken } from "../core/auth/tokenHelper";
import { routes } from "../routes";
import { ICloudControlService } from "../services";
import {
  getUserProfilePictureFromSessionStorage,
  setUserProfilePictureToSessionStorage,
} from "../utils/UserHelper";
import { AvatarDisplay } from "./AvatarDisplay";

interface IFenAvatar {
  name?: string;
  className?: string;
  cloudcontrolService: ICloudControlService;
  button?: boolean;
}

export const FenAvatar: React.FC<IFenAvatar> = ({
  className,
  cloudcontrolService,
  button,
}: IFenAvatar) => {
  const [userProfilePic, setUserProfilePic] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accounts } = useMsal();
  const avatarContent = getShortName(accounts[0]?.name ?? "");
  const getUserProfilePic = isLoading ? "" : userProfilePic;

  useEffect(() => {
    const userProfilePicture = getUserProfilePictureFromSessionStorage();
    if (userProfilePicture) {
      setUserProfilePic(userProfilePicture);
      return;
    }
    setIsLoading(true);

    const fetchUserPhoto = async () => {
      try {
        if (accounts.length === 0) {
          setIsLoading(false);
          return;
        }
        if (getAccessToken()) {
          const userPic = await cloudcontrolService.getUserProfilePicture(
            accounts[0].localAccountId
          );
          setUserProfilePic(userPic);
          setUserProfilePictureToSessionStorage(userPic);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserPhoto();
  }, [accounts, cloudcontrolService]);

  return button ? (
    <IconButton
      aria-label="account of current user"
      aria-haspopup="true"
      component={Link}
      to={routes.userProfile}
      color="inherit"
      data-testid="icon-button-account"
    >
      <AvatarDisplay
        userProfilePic={getUserProfilePic}
        className={className}
        avatarContent={avatarContent}
      />
    </IconButton>
  ) : (
    <AvatarDisplay
      userProfilePic={userProfilePic}
      className={className}
      avatarContent={avatarContent}
    />
  );
};
