import React, { useCallback } from "react";
import { FenDropdown } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core/styles";
import { LookupType } from "../../constants/LookupType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { convertToFenListItem } from "../../utils/FenListItemsHelper";
import { defaultFormikDropdownFieldWithErrors } from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName } from "../../utils/stringHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenList, FenListItem } from "../FenList";
import { RequestFormLabels } from "./RequestFormLabels";

export interface ITenantRequestFormConfigSection {
  staticLookup: LookupViewModel;
  formik: any;
  clientName: string;
}
export const TenantRequestFormConfigSection = ({
  staticLookup,
  formik,
  clientName,
}: ITenantRequestFormConfigSection): JSX.Element => {
  const classes = useStyles();
  const { values, setFieldValue, errors, touched } = formik;
  const handleInitialConfigToBeImportedChange = (value: any, propertyName: string) => {
    setFieldValue("configurations", []);
    formik.setFieldValue(propertyName, value);
  };

  const handleInitialConfigObjectListCheckboxChange = useCallback(
    (_name: string, item: FenListItem) => {
      if (!values.configurations) {
        values.configurations = [];
      }

      if (values.configurations.includes(item.id)) {
        values.configurations = values.configurations.filter((v: string) => v !== item.id);
      } else {
        values.configurations.push(item.id);
      }

      setFieldValue("configurations", values.configurations);
    },
    [setFieldValue, values]
  );

  return (
    <>
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={RequestFormLabels.InitialConfigToBeImported}
        name={"initialConfigToBeImported"}
        required
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        data-testid="initial-config-to-be-imported"
        error={touched.initialConfigToBeImported ? errors.initialConfigToBeImported : ""}
        {...defaultFormikDropdownFieldWithErrors(
          formik,
          "initialConfigToBeImported",
          handleInitialConfigToBeImportedChange
        )}
      />
      {values.initialConfigToBeImported === YesNoQuestion.Yes && (
        <FenList
          required
          className={classes.infoField}
          items={convertToFenListItem("InitialImportConfig", staticLookup, clientName)}
          title={RequestFormLabels.InitialConfigRequired}
          name={"configurations"}
          enableSelection={true}
          onSelect={handleInitialConfigObjectListCheckboxChange}
          selectedIds={values.configurations ?? []}
          clientName={""}
          error={values.configurations ? errors.configurations : ""}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  infoField: {
    marginBottom: "1rem",
  },
}));
