import { TenantRequestStatus } from "../../../constants/TenantRequestStatus";

export class TenantRequestStatusViewModel {
  tenantRequestId?: string;
  status?: TenantRequestStatus;

  constructor(tenantRequestId?: string, status?: TenantRequestStatus) {
    this.tenantRequestId = tenantRequestId;
    this.status = status;
  }
}
