import React, { ReactNode, MouseEvent } from "react";
import { FenLink } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { commonColors } from "../assets/themes/defaultTheme";

export interface StyledFenLinkProps {
  /** Text that should have link attatched. */
  text: string | ReactNode;
  /** Location where link should navigate. */
  to?: string;
  /** Function that executes on link click */
  onClick?: (ev: MouseEvent<HTMLElement>) => void;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  /** Applies the theme typography styles. */
  variant?: Variant | "inherit";
  className?: string;
}

export const StyledFenLink = ({
  text,
  to,
  onClick,
  "data-testid": testid,
  variant,
  className,
}: StyledFenLinkProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    defaultLink: {
      color: commonColors.linksBlue,
    },
  }));
  const classes = useStyles();
  return (
    <FenLink
      text={text}
      to={to}
      onClick={onClick}
      variant={variant}
      className={className ?? classes.defaultLink}
      data-testid={testid}
    />
  );
};
