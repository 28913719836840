import { S3Config } from "fenergo-nebula-cloudcontrol-query-api";
import { S3ConfigViewlModel } from "../viewModels/tenant/tenantConfig/S3ConfigViewlModel";

export const mapToS3ConfigViewModel = (dto?: S3Config): S3ConfigViewlModel => {
  const viewModel = new S3ConfigViewlModel();
  if (dto === undefined) {
    return viewModel;
  }
  viewModel.documentManagementBucketName = dto?.documentManagementBucketName;
  return viewModel;
};

export const mapToS3ConfigDto = (viewModel?: S3ConfigViewlModel): S3Config => ({
  documentManagementBucketName: viewModel?.documentManagementBucketName,
});
