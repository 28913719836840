import React from "react";
import { FenDropdown } from "@fenx/styleguide";
import { getDisplayValue } from "../../utils/lookupHelper";
import { FenInput } from "../FenInput";

export interface EditableFieldDropdownProps {
  selectedValue: string[] | string;
  /** Label to be shown alongside component. */
  label: string;
  /** Is component disabled . */
  disabled?: boolean;
  /** Handler that is applied to the root DOM element and fired upon blur changes */
  onBlur?: (updateEvent: React.FocusEvent<HTMLDivElement>) => void;
  /** Handler that is applied to the root DOM element and fired upon selecting element */
  onChange: (value: any, name: string) => void;
  /** Used to determine the string value for a given option. */
  getOptionLabel?: (option: any) => string;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  /** Name attribute of the `input` element. */
  name: string;
  /** If `true`, `value` must be an array and the menu will support multiple selections. */
  multiple?: boolean;
  /**  Error message displayed under the field.*/
  error?: string;
  /** If true, the input will take all horizontally available space. */
  fullWidth?: boolean;
  /** List of dropdown values. */
  options: any[];
  /** If the options is array of object, the value key defines the accessor. */
  valueKey?: string;
  /** If the options is array of object, the display key defines the property of the object to display. */
  displayKey?: string;
  /** Assign a custom class for the Autocomplete input */
  className?: string;
  errors: any;
  readInputclassName?: string;
}

export const EditableFieldDropdown = ({
  selectedValue,
  className,
  fullWidth,
  name,
  errors,
  multiple,
  disabled,
  valueKey,
  displayKey,
  options,
  onChange,
  onBlur,
  getOptionLabel,
  "data-testid": testid,
  readInputclassName,
  label,
}: EditableFieldDropdownProps): any => {
  const FenReadOnlyLabel = (
    <FenInput
      className={readInputclassName}
      fullWidth
      value={getDisplayValue(options, selectedValue, valueKey, displayKey, multiple)}
      name={name}
      multiline={true}
      variant="standard"
      inputPros={{ disableUnderline: true }}
    />
  );

  const FenDropDown = (
    <FenDropdown
      className={className}
      label={label}
      options={options}
      name={name}
      multiple={multiple}
      data-testid={testid}
      valueKey={valueKey}
      getOptionLabel={getOptionLabel}
      fullWidth={fullWidth}
      onBlur={onBlur}
      value={selectedValue}
      error={errors}
      onChange={onChange}
    />
  );
  return disabled ? FenReadOnlyLabel : FenDropDown;
};
