import React, { MouseEventHandler } from "react";
import { Box } from "@material-ui/core";
import MuiBox from "@material-ui/core/Box";
import MuiCard from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardContent from "@material-ui/core/CardContent";
import MuiCardMedia from "@material-ui/core/CardMedia";
import MuiHidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import MuiTypography from "@material-ui/core/Typography";
import { StatusChipCell } from "./StatusChipCell";

interface IFenCardProps {
  title: string;
  description: string;
  desktopImageSrc: string;
  imageText: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  inProgress: boolean;
}

export const FenCard: React.FC<IFenCardProps> = ({
  title,
  description,
  desktopImageSrc,
  imageText,
  onClick,
  inProgress,
}: IFenCardProps) => {
  const classes = useStyles();

  const CardTitle = (
    <MuiTypography component="h2" variant="h2" gutterBottom>
      <Box fontWeight="fontWeightBold">{title}</Box>
    </MuiTypography>
  );

  const getMediaCard = (cssClass: any, imageSrc: string) => (
    <MuiCardMedia className={cssClass} image={imageSrc} title={imageText} />
  );

  const ShowProgressChip = () => {
    return (
      <Box style={{ display: "flex" }}>
        <StatusChipCell
          cellValue="COMING SOON!!!"
          className={classes.chip}
          data-testid="in-progress-chip"
        />
      </Box>
    );
  };

  return (
    <MuiCardActionArea component="button" onClick={onClick}>
      <MuiCard className={classes.card}>
        <Box style={{ display: "flex" }}>
          <MuiHidden xsDown>{getMediaCard(classes.cardMedia, desktopImageSrc)}</MuiHidden>
          <MuiCardContent
            className={
              inProgress
                ? (classes.cardContent, classes.cardContentWithoutChip)
                : classes.cardContent
            }
          >
            {inProgress ? ShowProgressChip() : <></>}
            <MuiHidden smUp>
              <MuiBox display="flex" mb={1}>
                {getMediaCard(classes.smCardMedia, desktopImageSrc)}
                {CardTitle}
              </MuiBox>
            </MuiHidden>
            <MuiHidden xsDown>{CardTitle}</MuiHidden>
            <MuiTypography>{description}</MuiTypography>
          </MuiCardContent>
        </Box>
      </MuiCard>
    </MuiCardActionArea>
  );
};

const cardMediaCss: React.CSSProperties = {
  backgroundSize: "auto",
  backgroundPosition: "center",
};

const useStyles = makeStyles((theme) => ({
  card: {
    height: 208,
    maxWidth: 520,
    padding: theme.spacing(4),
  },
  cardContent: {
    flex: 1,
    padding: 0,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4.4),
    margin: "auto",

    "& h2": { color: "#486987" },
    "& p": { color: "#384f67" },
  },
  cardContentWithoutChip: {
    marginTop: 0,
    "& h2": { color: "#486987" },
    "& p": { color: "#384f67" },
  },
  cardMedia: {
    width: 100,
    ...cardMediaCss,
    marginLeft: theme.spacing(2),
  },
  smCardMedia: {
    width: 60,
    height: 48,
    marginRight: theme.spacing(2),
    ...cardMediaCss,
  },
  chip: {
    marginRight: "0px",
    marginLeft: "auto",
    color: "white",
    backgroundColor: "#FAB87B",
    padding: 13,
    height: 30,
    textAlign: "center",
    fontSize: "0.7rem",
    justifySelf: "flex-end",
  },
  hiddenChip: {
    opacity: 0,
  },
}));
