import React from "react";
import {
  Drawer,
  makeStyles,
  createStyles,
  Tooltip,
  withStyles,
  ListItem,
  IconButton,
  Icon,
  Box,
} from "@material-ui/core";
import MuiToolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { ReactComponent as LogoutRoundedIcon } from "../assets/images/icon-logoutRounded.svg";
import { routes } from "../routes";
import { isUserInRoles } from "./auth/roleHelper";
import { getIdToken } from "./auth/tokenHelper";
import { getNavigationData } from "./models/SideNavBarMenu";
import { SideNavBarMenuList } from "./SideNavBarMenuList";

export const SideNav = (): JSX.Element => {
  const classes = useStyles();
  const menuListItems = getNavigationData().filter((c) =>
    isUserInRoles(getIdToken(), c.accessPermission)
  );
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerClose,
      }}
    >
      <Box>
        <MuiToolbar className={classes.toolbar} />
        <SideNavBarMenuList
          menuListItems={menuListItems}
          listcontainerClassName={classes.listItemContainer}
          iconClassName={classes.iconContainer}
          buttonClassName={classes.buttonContainer}
        />
      </Box>
      <Box>
        <ListItem className={classes.listItemContainer}>
          <StyledTooltip title="Logout" placement="right">
            <IconButton
              aria-label="button for logout"
              aria-haspopup="true"
              component={Link}
              to={routes.logout}
              color="inherit"
              className={classes.buttonContainer}
              data-testid="side-bar-logout-button"
            >
              <Icon component={LogoutRoundedIcon} className={classes.iconContainer} />
            </IconButton>
          </StyledTooltip>
        </ListItem>
      </Box>
    </Drawer>
  );
};

export const StyledTooltip = withStyles({
  tooltip: {
    margin: "0 2px",
  },
})(Tooltip);

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      maxWidth: "3.38rem",
    },

    drawerClose: {
      overflowX: "hidden",
      maxWidth: "3.38rem",
      justifyContent: "space-between",
    },
    toolbar: {
      height: "64px",
    },
    buttonContainer: {
      padding: 0,
    },
    iconContainer: {
      fill: "#2e4d6b",
      "&:hover": {
        fill: "#4674A4",
      },
      width: "2.3rem",
      height: "2.3rem",
    },
    listItemContainer: {
      justifyContent: "center",
    },
  })
);
