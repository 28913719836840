export enum TenantRequestStatus {
  New = "New",
  Approval = "Approval",
  Approved = "Approved",
  InProgress = "In Progress",
  Completed = "Completed",
  Rejected = "Rejected",
  RejectedByApprover = "Declined",
  Archived = "Archived",
}

export const getTenantRequestStatusDisplayValue = (storedValue?: string): TenantRequestStatus => {
  return TenantRequestStatus[storedValue as keyof typeof TenantRequestStatus];
};

export const getTenantRequestStatusStoredValue = (displayValue: TenantRequestStatus): string => {
  return Object.keys(TenantRequestStatus)[Object.values(TenantRequestStatus).indexOf(displayValue)];
};

export const getOpenStatusList = (): string[] => {
  return [
    TenantRequestStatus.New,
    TenantRequestStatus.InProgress,
    TenantRequestStatus.Approval,
    TenantRequestStatus.Approved,
    TenantRequestStatus.Rejected,
    TenantRequestStatus.RejectedByApprover,
  ];
};
