import NunitoSansRegularWoff from "../fonts/nunito-sans-400.woff";
import NunitoSansRegularWoff2 from "../fonts/nunito-sans-400.woff2";
import NunitoSansSemiBoldWoff from "../fonts/nunito-sans-600.woff";
import NunitoSansSemiBoldWoff2 from "../fonts/nunito-sans-600.woff2";
import NunitoSansBoldWoff from "../fonts/nunito-sans-700.woff";
import NunitoSansBoldWoff2 from "../fonts/nunito-sans-700.woff2";
import QuicksandRegularWoff from "../fonts/quicksand-400.woff";
import QuicksandRegularWoff2 from "../fonts/quicksand-400.woff2";
import QuicksandMediumWoff from "../fonts/quicksand-500.woff";
import QuicksandMediumWoff2 from "../fonts/quicksand-500.woff2";

export const fontFaceDefinitions = [
  {
    fontFamily: "Quicksand",
    fontStyle: "normal",
    fontWeight: "400",
    src: `
          local('Quicksand'),
          local('Quicksand Regular'),
          url(${QuicksandRegularWoff2}) format('woff2'),
          url(${QuicksandRegularWoff}) format('woff')
        `,
  },
  {
    fontFamily: "Quicksand",
    fontStyle: "normal",
    fontWeight: "500",
    src: `
          local('Quicksand'),
          local('Quicksand Medium'),
          url(${QuicksandMediumWoff2}) format('woff2'),
          url(${QuicksandMediumWoff}) format('woff')
         `,
  },
  {
    fontFamily: "NunitoSans",
    fontStyle: "normal",
    fontWeight: "400",
    src: `
          local('NunitoSans'),
          local('NunitoSans Regular'),
          url(${NunitoSansRegularWoff2}) format('woff2'),
          url(${NunitoSansRegularWoff}) format('woff')
         `,
  },
  {
    fontFamily: "NunitoSans",
    fontStyle: "normal",
    fontWeight: "600",
    src: `
          local('NunitoSans'),
          local('NunitoSans SemiBold'),
          url(${NunitoSansSemiBoldWoff2}) format('woff2'),
          url(${NunitoSansSemiBoldWoff}) format('woff')
        `,
  },
  {
    fontFamily: "NunitoSans",
    fontStyle: "normal",
    fontWeight: "700",
    src: `
          local('NunitoSans'),
          local('NunitoSans Bold'),
          url(${NunitoSansBoldWoff2}) format('woff2'),
          url(${NunitoSansBoldWoff}) format('woff')
        `,
  },
];
