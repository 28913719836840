import React, { useEffect, useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { User } from "oidc-client";
import { BrowserRouter as Router } from "react-router-dom";
import { useAppConfig } from "../../hooks/useAppConfig";
import { routes } from "../../routes";
import { OidcAuthService } from "../../services/OidcAuthService";
import { Main } from "../Main";
import { SnackbarWrapper } from "../SnackBarWrapper";

// to cleanup in tech item
const Auth: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const config = useAppConfig();
  const [authService, setAuthService] = useState<OidcAuthService | null>(null);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (!config) {
      return;
    }

    const loadAsync = async () => {
      const newAuthService = new OidcAuthService(config.stsServer, setUser);
      setUser(await newAuthService.getUser());

      if (
        !isAuthenticated &&
        inProgress === InteractionStatus.None &&
        window.location.pathname !== routes.login &&
        window.location.pathname !== routes.loginCallback
      ) {
        window.location.href = routes.login;
      }

      setAuthService(newAuthService);
    };

    loadAsync();
  }, [config, isAuthenticated, inProgress]);

  if (authService === null) {
    return null;
  }

  return (
    <Router>
      <SnackbarWrapper>
        <Main authService={authService} user={user} userInfo={null} />
      </SnackbarWrapper>
    </Router>
  );
};

export default Auth;
