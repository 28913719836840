import { TenantRequestUrgency } from "../../constants/TenantRequestUrgency";
import { ITenantRequest } from "./ITenantRequest";

export class TenantRequestViewModel implements ITenantRequest {
  tenantRequestId?: string;
  clientTeamName?: string;
  requestType?: string;
  tenantType: string;
  status?: string;
  requestor?: string;
  requestorEmail?: string;
  requestorId?: string;
  actionedBy?: string;
  dueDate?: Date;
  dueDateString?: string;
  existingClient?: string;
  tenantOwnerId?: string;
  tenantOwnerStatus?: string;
  businessLine?: string[];
  contractSigned?: string;
  contractSentToCbo?: string;
  departmentCostTaggedAgainst?: string;
  approverOfTheDepartment?: string;
  approverId?: string;
  approverUserId?: string;
  customerSize?: string;
  tenantEnvironment?: string[];
  awsRegion?: string;
  otherAwsRegion?: string;
  ssoRequired?: string;
  providers?: string;
  byokRequired?: string;
  arn?: string;
  internalManagedKey?: string;
  initialConfigToBeImported?: string;
  configurations?: string[];
  portalTenant?: string;
  salesforceRequired?: string;
  eSignatureRequired?: string;
  urgentRequest?: string;
  urgencyReason?: string;
  additionalComments?: string;
  costIncluded?: string;

  constructor() {
    this.tenantType = "";
    this.urgentRequest = TenantRequestUrgency.Standard;
  }
}
