import { FenListItem } from "../components/FenList";
import { LookupViewModel } from "../viewModels/tenantRequestForm/LookupViewModel";

export const convertToFenListItem = (
  key: string,
  staticLookup: LookupViewModel,
  clientName?: string
): FenListItem[] => {
  const optionList = staticLookup?.List.find((x) => x.Key === key)?.Value ?? [];
  const returnList: FenListItem[] = [];
  optionList?.forEach((x) => {
    returnList.push({
      id: x.Value ?? "",
      label: x.Text ?? "",
      tooltip: x.Text ?? "",
      description: `${clientName}-${x.Text}`,
    });
  });
  return returnList;
};
