import { DynamoDbConfig } from "fenergo-nebula-cloudcontrol-query-api";
import { DynamoDbConfigViewlModel } from "../viewModels/tenant/tenantConfig/DynamoDbConfigViewlModel";

export const mapToDynamoDbConfigViewModel = (dto?: DynamoDbConfig): DynamoDbConfigViewlModel => {
  const viewModel = new DynamoDbConfigViewlModel();
  if (dto === undefined) {
    return viewModel;
  }
  viewModel.entityDataTableName = dto?.entityDataTableName;
  viewModel.productTableName = dto?.productTableName;
  viewModel.riskTableName = dto?.riskTableName;
  viewModel.dashboardsTableName = dto?.dashboardsTableName;
  return viewModel;
};

export const mapToDynamoDbConfigDto = (viewModel?: DynamoDbConfigViewlModel): DynamoDbConfig => ({
  entityDataTableName: viewModel?.entityDataTableName,
  productTableName: viewModel?.productTableName,
  riskTableName: viewModel?.riskTableName,
  dashboardsTableName: viewModel?.dashboardsTableName,
});
