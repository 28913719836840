import React from "react";
import MuiContainer from "@material-ui/core/Container";
import MuiGrid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { FenCard } from "../../components/FenCard";
import { isUserInRoles } from "../auth/roleHelper";
import { getIdToken } from "../auth/tokenHelper";
import { CardMenu } from "../models/CardMenu";

export interface HomeCardGridProps {
  cards: CardMenu[];
  containerClassname?: string;
  cardClassName?: string;
}

export const HomeCardGrid = ({
  cards,
  containerClassname,
  cardClassName,
}: HomeCardGridProps): JSX.Element => {
  const idToken = getIdToken();
  const history = useHistory();

  return (
    <MuiContainer>
      <MuiGrid container spacing={4} className={containerClassname}>
        {cards
          .filter((c) => isUserInRoles(idToken, c.accessPermission))
          .map((card) => (
            <MuiGrid key={card.id} item className={cardClassName}>
              <FenCard
                title={card.title}
                description={card.description}
                desktopImageSrc={card.desktopImageSrc}
                imageText={card.imageText}
                onClick={() => {
                  history.push(card.action, { pageId: card.id });
                }}
                inProgress={card.inProgress}
              />
            </MuiGrid>
          ))}
      </MuiGrid>
    </MuiContainer>
  );
};
