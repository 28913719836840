import { UserInfoDto } from "fenergo-nebula-cloudcontrol-query-api";
import { UserInfoViewModel } from "../viewModels/userInfo/UserInfoViewModel";

export class UserInfoMapping {
  public static mapToViewModel = (model: UserInfoDto | undefined): UserInfoViewModel => {
    const viewModel = new UserInfoViewModel();

    viewModel.id = model?.id;
    viewModel.name = model?.name;
    viewModel.role = model?.role;
    viewModel.email = model?.email;

    return viewModel;
  };
  public static mapToDto = (model: UserInfoViewModel): UserInfoDto => {
    const dto: UserInfoDto = {
      id: model.id,
      name: model.name,
      role: model.role,
      email: model.email,
    };

    return dto;
  };
}
