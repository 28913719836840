import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { SnackbarProvider } from "notistack";
import { ReactComponent as AlertsErrorWhiteIcon } from "../assets/images/AlertIconErrorWhite.svg";
import { ReactComponent as AlertsSuccessWhiteIcon } from "../assets/images/AlertIconSuccessWhite.svg";
import { commonColors } from "../assets/themes/defaultTheme";

const useStyles = makeStyles((theme: Theme) => {
  const {
    palette: { common },
  } = theme;
  const base: CSSProperties = {
    padding: 0,
    boxShadow: `0 1px 2px 0 ${common.greysMidGrey} !important`,
    "& > div": {
      fontFamily: "NunitoSans, Open Sans, Helvetica, Arial, sans-serif",
      fontWeight: 800,
      fontSize: 13,
      lineHeight: 1.85,
      padding: 0,
      paddingTop: 7,
      paddingBottom: 5,
    },
  };

  return {
    variantSuccess: {
      ...base,
      backgroundColor: commonColors.successAlertLighter,
    },
    variantError: {
      ...base,
      backgroundColor: commonColors.errorAlertLighter,
    },
    icon: {
      marginRight: "1rem",
      padding: "3px",
    },
  };
});
export const SnackbarWrapper: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={{
        error: <AlertsErrorWhiteIcon className={classes.icon} />,
        success: <AlertsSuccessWhiteIcon className={classes.icon} />,
      }}
      autoHideDuration={3000}
      preventDuplicate
      classes={{
        variantSuccess: classes.variantSuccess,
        variantError: classes.variantError,
      }}
      className={classes.icon}
    >
      {children}
    </SnackbarProvider>
  );
};
