import { User, UserManager, UserManagerSettings } from "oidc-client";
import { routes } from "../routes";

export class OidcAuthService {
  private static userManagerSettings: UserManagerSettings;
  private readonly _userManager: UserManager;

  constructor(authority: string, userChangedCallback: (user: User | null) => void) {
    OidcAuthService.userManagerSettings = {
      authority: authority,
      client_id: "fenergo-nebula-doc-portal",
      redirect_uri: `${window.location.origin}${routes.loginCallback}`,
      response_type: "code",
      scope: "openid",
      post_logout_redirect_uri: `${window.location.origin}${routes.logoutCallback}`,
    };

    this._userManager = new UserManager(OidcAuthService.userManagerSettings);
    this._userManager.events.addUserLoaded(userChangedCallback);
    this._userManager.events.addUserUnloaded(() => userChangedCallback(null));
    this.addUserSigneOutCallback();
  }

  getUserManager(): UserManager {
    return this._userManager;
  }

  getUser(): Promise<User | null> {
    return this.getUserManager().getUser();
  }

  private sessionExpiredHandler() {
    this._userManager.removeUser();
    window.location.href = routes.login;
  }

  private addUserSigneOutCallback() {
    this._userManager.events.addUserSignedOut(() => this.sessionExpiredHandler());
  }
}
