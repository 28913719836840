export const RequestHelper = {
  http(tenantId: string, accessToken?: string): any {
    const headers = {
      "Content-Type": "application/json",
      "X-TENANT-ID": "",
      Authorization: "",
    };

    if (tenantId) {
      headers["X-TENANT-ID"] = tenantId;
    }

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return {
      fetch(url: string, init: RequestInit) {
        return fetch(url, { ...init, headers });
      },
    };
  },
};
