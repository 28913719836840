import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import MuiBox from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MuiTypography from "@material-ui/core/Typography";
import BackgroundImage from "../../assets/images/background.jpg";
import ReportingIcon from "../../assets/images/icon-reporting.svg";
import TenantRequestIcon from "../../assets/images/icon-tenant-request.svg";
import TenantIcon from "../../assets/images/icon-tenant.svg";
import { routes } from "../../routes";
import { CloudControlRoles } from "../auth/CloudControlRoles";
import { CardMenu } from "../models/CardMenu";
import { HomeCardGrid } from "./HomeCardGrid";

export const Home: React.FC = () => {
  const classes = useStyles();

  return (
    <MuiBox mt={3} mb={5}>
      <MuiTypography component="h1" variant="h1" align="center" gutterBottom className={classes.h1}>
        Welcome to Fen-X Cloud Control
      </MuiTypography>
      <AuthenticatedTemplate>
        <HomeCardGrid
          cards={cards.filter((c) => c.enabled)}
          containerClassname={classes.container}
          cardClassName={classes.card}
        />
      </AuthenticatedTemplate>
    </MuiBox>
  );
};

const cards: CardMenu[] = [
  {
    id: 1,
    title: "Tenant Management",
    description: "Manage and monitor activities for all existing tenants",
    desktopImageSrc: TenantIcon,
    imageText: "Tenant Management",
    action: routes.tenantManagement,
    accessPermission: [CloudControlRoles.Admin],
    inProgress: false,
    enabled: true,
  },
  {
    id: 2,
    title: "Tenant Request",
    description: "Generate new tenant requests and view request status",
    desktopImageSrc: TenantRequestIcon,
    imageText: "Tenant Request",
    action: routes.tenantRequestList,
    accessPermission: [],
    inProgress: false,
    enabled: true,
  },
  {
    id: 3,
    title: "Reporting",
    description: "Be informed of key metrics to track and analyse",
    desktopImageSrc: ReportingIcon,
    imageText: "Reporting",
    action: routes.reporting,
    accessPermission: [],
    inProgress: true,
    enabled: false,
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
    },
  },
  container: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(4),
    justifyContent: "center",
    backgroundSize: "cover",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
    maxWidth: 520,
    margin: "auto",
  },
  h1: {
    color: theme.palette.primary.main,
    fontSize: "3rem",
    marginTop: theme.spacing(15),
  },
  h4: {
    fontWeight: 600,
    marginBottom: theme.spacing(6),
    fontSize: "1.1rem",
  },
}));
