import {
  ClientTenantOwnerDetailsDto,
  InternalTenantOwnerDetailsDto,
} from "fenergo-nebula-cloudcontrol-command-api";
import { TenantOwnerTenantDto } from "fenergo-nebula-cloudcontrol-query-api";
import { YesNoQuestion } from "../constants/YesNoQuestion";
import { TenantOwnerViewModel } from "../viewModels/tenantOwner/TenantOwnerViewModel";
import { mapTenantSummaryDtoToViewModel } from "./TenantMapping";

export const mapToTenantOwnerViewModel = (dto?: TenantOwnerTenantDto): TenantOwnerViewModel => {
  const viewModel = new TenantOwnerViewModel();
  if (!dto) {
    return viewModel;
  }

  viewModel.id = dto.id;
  viewModel.name = dto.name;
  viewModel.type = dto.type;
  viewModel.keyContact = dto.keyContact;
  viewModel.department = dto.department;
  viewModel.tenantIds = dto.tenantIds;
  viewModel.businessLines = dto.businessLines;
  viewModel.customerSize = dto.customerSize;
  viewModel.hasContractBeenSigned = dto.hasContractBeenSigned;
  const tenantsSummaryViewModel = dto.tenantsSummary?.map((t) => mapTenantSummaryDtoToViewModel(t));
  viewModel.tenantsSummary = tenantsSummaryViewModel;
  return viewModel;
};

export const mapToTenantOwnerLiteViewModel = (dto?: TenantOwnerTenantDto): TenantOwnerViewModel => {
  const viewModel = new TenantOwnerViewModel();
  if (!dto) {
    return viewModel;
  }

  viewModel.id = dto.id;
  viewModel.name = dto.name;
  viewModel.type = dto.type;
  viewModel.keyContact = dto.keyContact;
  viewModel.department = dto.department;
  viewModel.tenantIds = dto.tenantIds;
  viewModel.approverId = dto.approverId;
  viewModel.businessLines = dto.businessLines;
  viewModel.customerSize = dto.customerSize;
  viewModel.hasContractBeenSentToCBO = dto.hasContractBeenSentToCBO;
  viewModel.hasContractBeenSigned = dto.hasContractBeenSigned;
  return viewModel;
};

export const mapViewModelToClientTenantOwnerDetailsDto = (
  viewModel?: TenantOwnerViewModel
): ClientTenantOwnerDetailsDto => {
  if (!viewModel) {
    return {};
  }

  const dto: ClientTenantOwnerDetailsDto = {
    id: viewModel.id,
    keyContact: viewModel.keyContact,
    department: viewModel.department,
    businessLines: viewModel.businessLines,
    customerSize: viewModel.customerSize,
    hasContractBeenSigned: viewModel.hasContractBeenSigned,
    hasContractBeenSentToCBO: viewModel.hasContractBeenSentToCBO ?? YesNoQuestion.No,
  };

  return dto;
};

export const mapViewModelToInternalTenantOwnerDetailsDto = (
  viewModel?: TenantOwnerViewModel
): InternalTenantOwnerDetailsDto => {
  if (!viewModel) {
    return {};
  }

  const dto: InternalTenantOwnerDetailsDto = {
    id: viewModel.id,
    keyContact: viewModel.keyContact,
    department: viewModel.department,
  };

  return dto;
};
