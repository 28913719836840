import React from "react";
import { CellProps, Column } from "react-table";
import { commonColors } from "../../../assets/themes/defaultTheme";
import { TenantStatusCell } from "../../../components/TenantStatusCell";
import { FenxServiceStatus } from "../../../constants/FenxServiceStatus";
import { TenantFenxServicesViewModel } from "../tenantConfig/TenantFenxServicesViewModel";

export const getFenxServicesTableColumns = (): Column<TenantFenxServicesViewModel>[] => {
  const fortyPercentWidth = "40%";
  const thirtyPercentWidth = "30%";
  return [
    fenxServiceNameColumn(fortyPercentWidth),
    fenxServiceStatusColumn(thirtyPercentWidth),
    fenxServiceTypeColumn(thirtyPercentWidth),
  ];
};

type ColumnWidthType = number | string | undefined;

const fenxServiceNameColumn = (width?: ColumnWidthType): Column<TenantFenxServicesViewModel> =>
  ({
    Header: "Services",
    accessor: "serviceName",
    width: width,
    Cell: ({ row }: CellProps<TenantFenxServicesViewModel>) => row.original.serviceName,
    sortType: "alphanumericNullable",
  } as Column<TenantFenxServicesViewModel>);

const fenxServiceStatusColumn = (width?: ColumnWidthType): Column<TenantFenxServicesViewModel> =>
  ({
    Header: "Status",
    accessor: "serviceStatus",
    width: width,
    Cell: ({ row }: CellProps<TenantFenxServicesViewModel>) => (
      <TenantStatusCell
        tenantStatus={row.original.serviceStatus}
        chipColor={
          row.original.serviceStatus === FenxServiceStatus.Active ? "" : commonColors.statusChipsNew
        }
      />
    ),
    sortType: "alphanumericNullable",
  } as Column<TenantFenxServicesViewModel>);

const fenxServiceTypeColumn = (width?: ColumnWidthType): Column<TenantFenxServicesViewModel> => ({
  Header: "Service Type",
  accessor: "serviceType",
  width: width,
  Cell: ({ row }: CellProps<TenantFenxServicesViewModel>) => row.original.serviceType,
});
