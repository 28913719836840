import { ReactComponent as ReportingIcon } from "../../assets/images/icon-reporting-sidebar.svg";
import { ReactComponent as TenantRequestIcon } from "../../assets/images/icon-tenant-request-sidebar.svg";
import { ReactComponent as TenantIcon } from "../../assets/images/icon-tenant-sidebar.svg";
import { routes } from "../../routes";
import { CloudControlRoles } from "../auth/CloudControlRoles";
export interface SideNavBarMenuItem {
  id: number;
  sortNumber: number;
  title: string;
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  testId: string;
  route: string;
  enabled: boolean;
  accessPermission: string[];
}

const allMenuItems: SideNavBarMenuItem[] = [
  {
    id: 1,
    sortNumber: 1,
    title: "Tenant Management",
    label: "button for tenant management",
    icon: TenantIcon,
    testId: "side-bar-tenant-management-button",
    route: routes.tenantManagement,
    enabled: true,
    accessPermission: [CloudControlRoles.Admin],
  },
  {
    id: 2,
    sortNumber: 2,
    title: "Tenant Request",
    label: "button for tenant request",
    icon: TenantRequestIcon,
    testId: "side-bar-tenant-request-button",
    route: routes.tenantRequestList,
    enabled: true,
    accessPermission: [],
  },
  {
    id: 3,
    sortNumber: 3,
    title: "Reporting",
    label: "button for reporting",
    icon: ReportingIcon,
    testId: "side-bar-reporting-button",
    route: routes.reporting,
    enabled: false,
    accessPermission: [],
  },
];

export function getNavigationData(): SideNavBarMenuItem[] {
  return allMenuItems.filter((m) => m.enabled);
}
