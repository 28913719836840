import React, { useEffect, useState } from "react";
import { FenSpinner } from "@fenx/styleguide";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../constants/paramTypes";
import { TenantOwnerStatus } from "../../constants/TenantOwnerStatus";
import { TenantOwnerType } from "../../constants/TenantOwnerType";
import { TenantType } from "../../constants/TenantType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { ICloudControlService } from "../../services/ICloudControlService";
import { ApproverViewModel } from "../../viewModels/approver/ApproverViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestViewModel } from "../../viewModels/tenantRequest/TenantRequestViewModel";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { TenantRequestFormPage } from "./TenantRequestFormPage";

export interface TenantRequestEditFormProps {
  cloudcontrolService: ICloudControlService;
}

export const TenantRequestEditForm = ({
  cloudcontrolService,
}: TenantRequestEditFormProps): JSX.Element => {
  const [staticLookup, setStaticLookup] = useState<LookupViewModel>(new LookupViewModel());
  const [tenantOwners, setTenantOwners] = useState<TenantOwnerViewModel[]>([]);
  const [approver, setApprover] = useState<ApproverViewModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tenantRequest, setTenantRequest] = useState<TenantRequestViewModel>();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    const fetchTenantRequestById = async () => {
      try {
        const tenantRequestData = await cloudcontrolService.getTenantRequestById(id);
        tenantRequestData.existingClient =
          tenantRequestData.tenantOwnerStatus === TenantOwnerStatus.Active
            ? YesNoQuestion.Yes
            : YesNoQuestion.No;
        if (tenantRequestData.initialConfigToBeImported === YesNoQuestion.No) {
          tenantRequestData.configurations = [];
        }
        setTenantRequest(tenantRequestData);
        const targetTenantOwnerType: string[] =
          tenantRequestData.tenantType === TenantType.Client
            ? [TenantOwnerType.Client, TenantOwnerType.Partner]
            : [TenantOwnerType.Team];
        const lookupResponse = await cloudcontrolService.getStaticLookup(
          tenantRequestData.tenantType,
          YesNoQuestion.No
        );
        const clientResponse = await cloudcontrolService.getClients();
        const approverResponse = await cloudcontrolService.getApprovers();
        setStaticLookup(lookupResponse);
        setTenantOwners(
          clientResponse.filter((c) => c.type && targetTenantOwnerType.includes(c.type))
        );
        setApprover(approverResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTenantRequestById();
  }, [cloudcontrolService, id]);

  if (isLoading) {
    return <FenSpinner />;
  }

  return (
    <TenantRequestFormPage
      cloudcontrolService={cloudcontrolService}
      id={id}
      staticLookup={staticLookup}
      tenantOwners={tenantOwners}
      approver={approver}
      tenantRequest={tenantRequest ?? new TenantRequestViewModel()}
    />
  );
};
