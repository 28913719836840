import { TenantType } from "../constants/TenantType";

export const MapToTenantType = (tenantOwnerType?: string): any => {
  if (tenantOwnerType === "Client" || tenantOwnerType === "Partner") {
    return TenantType.Client;
  } else if (tenantOwnerType === "Team") {
    return TenantType.Internal;
  } else {
    return "";
  }
};
