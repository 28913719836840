import { StringSelectListItemDtoIEnumerableKeyValuePair } from "fenergo-nebula-cloudcontrol-query-api";
import { KeyValuePairViewModel } from "../viewModels/tenantRequestForm/KeyValuePairViewModel";
import { mapToSelectListItemViewModel } from "./SelectListItemMapping";

export const mapToKeyValuePairViewModel = (
  keyValuePair?: StringSelectListItemDtoIEnumerableKeyValuePair
): KeyValuePairViewModel => {
  const viewModel = new KeyValuePairViewModel();
  if (!keyValuePair) {
    return viewModel;
  }

  viewModel.Key = keyValuePair.key;
  viewModel.Value = keyValuePair.value?.map((t) => mapToSelectListItemViewModel(t)) ?? [];
  return viewModel;
};
