import React from "react";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";

export const NotFound: React.FC = () => (
  <MuiBox mt={5} mb={12}>
    <MuiTypography component="h1" variant="h1" align="center" gutterBottom>
      Page Not Found
    </MuiTypography>
  </MuiBox>
);
