import React, { useState } from "react";
import { FenBaseModal, FenDropdown, FenFieldsGrid } from "@fenx/styleguide";
import { useHistory } from "react-router-dom";
import { RequestType } from "../constants/RequestType";
import { routes } from "../routes";
import { ICloudControlService } from "../services/ICloudControlService";
import { GetAvailableStatusOptions } from "../utils/TenantRequestStatusHelper";
import { TenantRequestStatusViewModel } from "../viewModels/tenantRequest/columns/TenantRequestStatusViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";

export interface SetStatusModalProps {
  cloudcontrolService: ICloudControlService;
  data?: TenantRequestViewModel;
  open: boolean;
  callback: any;
  requestType: string;
}

export const SetStatusModal: React.FC<SetStatusModalProps> = ({
  cloudcontrolService,
  data,
  open,
  callback,
  requestType,
}) => {
  const [tenantRequestViewModel] = useState<TenantRequestViewModel>(
    data ?? new TenantRequestViewModel()
  );
  const [tenantRequestStatusViewModel, setTenantRequestStatusViewModel] =
    useState<TenantRequestStatusViewModel>(
      new TenantRequestStatusViewModel(tenantRequestViewModel.tenantRequestId)
    );

  const history = useHistory();

  const submitStatusAndRedirectToRequestForm = async () => {
    try {
      if (!tenantRequestViewModel?.tenantRequestId) {
        return;
      }

      await (requestType === RequestType.PortalCreation
        ? cloudcontrolService.updatePortalRequestStatus(
            tenantRequestViewModel.tenantRequestId,
            tenantRequestStatusViewModel
          )
        : cloudcontrolService.updateTenantRequestStatus(
            tenantRequestViewModel.tenantRequestId,
            tenantRequestStatusViewModel
          ));

      history.push(routes.tenantRequestList);
    } catch (error) {
      console.error("Error", error);
    }
  };

  function handleDropdownChange(value: any, propertyName: string) {
    setTenantRequestStatusViewModel({
      ...tenantRequestStatusViewModel,
      [propertyName]: value,
    });
  }

  function isSubmitButtonDisabled(): boolean {
    return !tenantRequestStatusViewModel?.status;
  }

  function handleOnClose() {
    callback();
    setTenantRequestStatusViewModel(
      new TenantRequestStatusViewModel(tenantRequestViewModel.tenantRequestId)
    );
  }

  return (
    <>
      {open && (
        <FenBaseModal
          title="Set Status"
          onClose={handleOnClose}
          disabled={isSubmitButtonDisabled()}
          onSubmit={submitStatusAndRedirectToRequestForm}
        >
          <FenFieldsGrid columns={1}>
            <FenDropdown
              fullWidth
              label="New Status"
              name="status"
              required
              options={GetAvailableStatusOptions(tenantRequestViewModel.status)}
              value={tenantRequestStatusViewModel?.status ?? "- select value -"}
              onChange={handleDropdownChange}
              data-testid="set-status-dropdown"
            />
          </FenFieldsGrid>
        </FenBaseModal>
      )}
    </>
  );
};
