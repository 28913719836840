import React, { useState } from "react";
import { FenButton, FenTitle } from "@fenx/styleguide";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormikTouched, setNestedObjectValues, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { commonColors } from "../../assets/themes/defaultTheme";
import { TenantType } from "../../constants/TenantType";
import { routes } from "../../routes";
import { ICloudControlService } from "../../services/ICloudControlService";
import { getTenantRequirementViewModelValidationSchema } from "../../utils/yupHelper";
import { ApproverViewModel } from "../../viewModels/approver/ApproverViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestViewModel } from "../../viewModels/tenantRequest/TenantRequestViewModel";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenccConfirmationPage } from "../FenccConfirmationPage";
import { TenantRequestFormAwsSection } from "./TenantRequestFormAwsSection";
import { TenantRequestFormByokSection } from "./TenantRequestFormByokSection";
import { TenantRequestFormClientSection } from "./TenantRequestFormClientSection";
import { TenantRequestFormConfigSection } from "./TenantRequestFormConfigSection";
import { TenantRequestFormOtherSection } from "./TenantRequestFormOtherSection";
import { TenantRequestFormReviewPage } from "./TenantRequestFormReviewPage";
import { TenantRequestFormSsoSection } from "./TenantRequestFormSsoSection";

export interface TenantRequestFormPageProps {
  cloudcontrolService: ICloudControlService;
  id?: string;
  staticLookup: LookupViewModel;
  tenantOwners: TenantOwnerViewModel[];
  approver: ApproverViewModel[];
  tenantRequest: TenantRequestViewModel;
}

export const TenantRequestFormPage = ({
  cloudcontrolService,
  id,
  staticLookup,
  tenantOwners,
  approver,
  tenantRequest,
}: TenantRequestFormPageProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [isReview, setIsReview] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: tenantRequest,
    validationSchema: () =>
      Yup.lazy((formikValues: any) => getTenantRequirementViewModelValidationSchema(formikValues)),
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (submitValues: TenantRequestViewModel) => {
      try {
        setIsSavingChanges(true);
        id
          ? await cloudcontrolService.updateTenantRequest(id, submitValues)
          : await cloudcontrolService.createTenantRequest(submitValues);
        history.push(routes.tenantRequestList);
      } catch (error) {
        console.error("Error", error);
      } finally {
        setIsSavingChanges(false);
      }
    },
  });

  const { values, submitForm } = formik;

  const redirectToReviewPage = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      setIsReview(true);
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<TenantRequestViewModel>>(errors, true));
    }
  };

  const handlePreviousPageCallback = () => {
    setIsReview(!isReview);
  };

  if (isSavingChanges) {
    return <FenccConfirmationPage />;
  }
  if (isReview) {
    return (
      <TenantRequestFormReviewPage
        formik={formik}
        callback={handlePreviousPageCallback}
        submitForm={submitForm}
        staticLookup={staticLookup}
      />
    );
  }
  return (
    <Box className={classes.root}>
      <FenTitle title="New Tenant Request" variant="h1" className={classes.header} />
      <TenantRequestFormClientSection
        staticLookup={staticLookup}
        formik={formik}
        tenantOwners={tenantOwners}
        approvers={approver}
        clientName={values.clientTeamName ?? ""}
      />
      <TenantRequestFormAwsSection staticLookup={staticLookup} formik={formik} />
      {values.tenantType === TenantType.Client && (
        <>
          <TenantRequestFormSsoSection staticLookup={staticLookup} formik={formik} />
          <TenantRequestFormByokSection staticLookup={staticLookup} formik={formik} />
        </>
      )}
      <TenantRequestFormConfigSection
        staticLookup={staticLookup}
        formik={formik}
        clientName={values.clientTeamName ?? ""}
      />
      <TenantRequestFormOtherSection staticLookup={staticLookup} formik={formik} />
      <FenButton
        onClick={redirectToReviewPage}
        className={classes.button}
        data-testid="submit-button"
        text="Next"
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4rem",
    marginBottom: "6rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  header: {
    marginBottom: "1rem",
  },
  button: {
    maxWidth: "14rem",
    minWidth: "8rem",
    minHeight: "4.5rem",
    marginTop: "1.5rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "20%",
    backgroundColor: commonColors.bluesPrimaryBlue,
    fontSize: "2rem",
    fontWeight: 700,
    "&:hover,&:focus": {
      backgroundColor: commonColors.bluesPrimaryBlue,
    },
    color: commonColors.greysLightGrey1,
  },
}));
