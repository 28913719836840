import React from "react";
import { EnhancedDataTable, TabPanel } from "@fenx/styleguide";
import { Tab, Tabs, Box, withStyles } from "@material-ui/core";
import { isUserInRoles } from "../core/auth/roleHelper";
import { getIdToken } from "../core/auth/tokenHelper";
import { FenccTabPanelGridProps, TabProps } from "../core/models/FenccTabPanelGridProps";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);

export const FenccTabPanelGrid = ({
  tabHeaderProps,
  tabsProps,
}: FenccTabPanelGridProps): JSX.Element => {
  const filteredTabs = tabsProps.filter((t) => isUserInRoles(getIdToken(), t.accessPermission));
  return (
    <>
      <Box padding={1}>
        <Tabs value={tabHeaderProps.tabsValue} onChange={tabHeaderProps.tabsOnChange}>
          {filteredTabs.map((tabProps: TabProps, index: number) => (
            <StyledTab label={tabProps.tabLabel} key={index} />
          ))}
        </Tabs>
        {filteredTabs.map((tabPanelProps: TabProps, index: number) => (
          <TabPanel
            value={tabPanelProps.tabPanelValue}
            index={tabPanelProps.tabPanelIndex}
            key={index}
          >
            <EnhancedDataTable<TenantRequestViewModel>
              columns={tabPanelProps.columns}
              data={tabPanelProps.data}
              unlimitedHeight
              tableTitle={tabPanelProps.tableTitle}
              addItemFunction={tabPanelProps.addItemFunction}
              enableGlobalFilter
              disableAddItem={false}
              defaultRowsPerPage={10}
              key={index}
            />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
