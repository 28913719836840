import React from "react";
import { FenDropdown, FenTextInput } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core/styles";
import { LookupType } from "../../constants/LookupType";
import { UrgencyStatus } from "../../constants/UrgencyStatus";
import {
  defaultFormikDropdownFieldWithErrors,
  formikFieldWithErrors,
} from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName } from "../../utils/stringHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { RequestFormLabels } from "./RequestFormLabels";

export interface ITenantRequestFormOtherSection {
  staticLookup: LookupViewModel;
  formik: any;
}
export const TenantRequestFormOtherSection = ({
  staticLookup,
  formik,
}: ITenantRequestFormOtherSection): JSX.Element => {
  const classes = useStyles();
  const { values } = formik;

  const handleUrgentRequestChange = (value: any, propertyName: string) => {
    if (values.urgentRequest) {
      formik.setFieldValue("urgencyReason", "");
    }
    formik.setFieldValue(propertyName, value);
  };

  return (
    <>
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={RequestFormLabels.PortTenantRequired}
        name="portalTenant"
        required
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        data-testid="portal-tenant"
        {...defaultFormikDropdownFieldWithErrors(formik, "portalTenant")}
      />
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={RequestFormLabels.SaleforceRequired}
        name="salesforceRequired"
        required
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        data-testid="salesforce-required"
        {...defaultFormikDropdownFieldWithErrors(formik, "salesforceRequired")}
      />
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={RequestFormLabels.EsignatureRequired}
        name="eSignatureRequired"
        required
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        data-testid="e-signature-required"
        {...defaultFormikDropdownFieldWithErrors(formik, "eSignatureRequired")}
      />
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={RequestFormLabels.Urgency}
        name="urgentRequest"
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.Urgency)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        required
        data-testid="urgent-request"
        {...defaultFormikDropdownFieldWithErrors(
          formik,
          "urgentRequest",
          handleUrgentRequestChange
        )}
      />
      {values.urgentRequest === UrgencyStatus.Urgent && (
        <FenTextInput
          className={classes.infoField}
          fullWidth
          multiline
          rows={5}
          rowsMax={5}
          name="urgencyReason"
          required
          data-testid="urgency-reason"
          label={RequestFormLabels.UrgencyReason}
          {...formikFieldWithErrors(formik, "urgencyReason")}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  infoField: {
    marginBottom: "1rem",
  },
}));
