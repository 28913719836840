import { SelectListItemViewModel } from "./SelectListItemViewModel";

export class KeyValuePairViewModel {
  Key?: string;
  Value: SelectListItemViewModel[];

  constructor() {
    this.Value = [];
  }
}
