import React, { FC, memo } from "react";
import { FenLink } from "@fenx/styleguide";
import { FenLabel } from "@fenx/styleguide/components/FenLabel";
import { Grid, makeStyles, Typography } from "@material-ui/core";

/**
 * FenLink properties.
 */
export interface FenccLinkProps {
  /** Label to be shown alongside component. */
  label: string;
  /** Selected value. */
  value: any;
  /** Display required indicator. */
  required?: boolean;
  /** Name attribute of the `input` element. */
  name?: string;
  /** If true, the input will take all horizontally available space. */
  fullWidth?: boolean;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  multiline?: boolean;
  labelStyle?: string;
  redirectUrl?: string;
}

const FenccLinkBase: FC<FenccLinkProps> = ({
  label,
  value,
  required,
  fullWidth,
  labelStyle,
  redirectUrl,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} item xs={fullWidth ? undefined : 4}>
      <FenLabel className={labelStyle} label={label} required={required} />
      {value ? (
        <FenLink className={classes.link} text={value} to={redirectUrl} />
      ) : (
        <Typography>-</Typography>
      )}
    </Grid>
  );
};
export const FenccLink = memo(FenccLinkBase);

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    fontSize: "1rem",
    color: "#0B79D0",
  },
}));
