import _filter from "lodash/filter";
import _sortBy from "lodash/sortBy";
import { FenxServiceStatus } from "../constants/FenxServiceStatus";
import { TenantStatus } from "../constants/TenantStatus";
import { TenantFenxServicesViewModel } from "../viewModels/tenant/tenantConfig/TenantFenxServicesViewModel";
import { TenantEnvironmentViewModel } from "../viewModels/tenant/TenantEnvironmentViewModel";
import { TenantUserExtensionViewModel } from "../viewModels/tenant/tenantUser/TenantUserExtensionViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";

export const sortByTenantName = (
  tenantList: TenantEnvironmentViewModel[]
): TenantEnvironmentViewModel[] =>
  _sortBy(tenantList, [
    (x: TenantEnvironmentViewModel) => (x.tenantName ? 0 : 1),
    (x: TenantEnvironmentViewModel) => x.tenantName?.toLocaleLowerCase(),
  ]);

export const sortByTenantOwnerName = (
  tenantList: TenantEnvironmentViewModel[]
): TenantEnvironmentViewModel[] =>
  _sortBy(tenantList, [
    (x: TenantEnvironmentViewModel) => (x.tenantOwnerName ? 0 : 1),
    (x: TenantEnvironmentViewModel) => x.tenantOwnerName?.toLocaleLowerCase(),
  ]);

const calculateSortPoint = (t: TenantEnvironmentViewModel) => {
  const statusOrder = [TenantStatus.Active, TenantStatus.Inactive, TenantStatus.Dormant];

  if (!statusOrder.includes(t.status as TenantStatus)) {
    return statusOrder.length + 1;
  }

  return statusOrder.indexOf(t.status as TenantStatus);
};

export const sortByTenantStatusThenByTenantName = (
  tenantList: TenantEnvironmentViewModel[]
): TenantEnvironmentViewModel[] => {
  return _sortBy(tenantList, [
    (x: TenantEnvironmentViewModel) => calculateSortPoint(x),
    (x: TenantEnvironmentViewModel) => x.tenantName?.toLocaleLowerCase(),
  ]);
};

export const sortByFenxServiceStatusThenByName = (
  tenantFenxServiceList: TenantFenxServicesViewModel[] | undefined
): TenantFenxServicesViewModel[] => {
  return _sortBy(tenantFenxServiceList, [
    (x: TenantFenxServicesViewModel) => calculateFenxServiceSortPoint(x),
    (x: TenantFenxServicesViewModel) => x.serviceName?.toLocaleLowerCase(),
  ]);
};

const calculateFenxServiceSortPoint = (t: TenantFenxServicesViewModel) => {
  const statusOrder = [FenxServiceStatus.Active, FenxServiceStatus.Inactive];

  if (!statusOrder.includes(t.serviceStatus as FenxServiceStatus)) {
    return statusOrder.length + 1;
  }

  return statusOrder.indexOf(t.serviceStatus as FenxServiceStatus);
};

export const sortByUserEmail = (
  tenantUserList: TenantUserExtensionViewModel[] | undefined
): TenantUserExtensionViewModel[] =>
  _sortBy(tenantUserList, [
    (x: TenantUserExtensionViewModel) => (x.userEmail ? 0 : 1),
    (x: TenantUserExtensionViewModel) => x.userEmail?.toLocaleLowerCase(),
  ]);

export const filterByInternalUserEmail = (
  tenantUserList: TenantUserExtensionViewModel[] | undefined
): TenantUserExtensionViewModel[] =>
  tenantUserList ? _filter(tenantUserList, ["userType", "Internal"]) : [];

export const filterByExternalUserEmail = (
  tenantUserList: TenantUserExtensionViewModel[] | undefined
): TenantUserExtensionViewModel[] =>
  tenantUserList ? _filter(tenantUserList, ["userType", "External"]) : [];

export const sortByDueDate = (
  tenantRequestList: TenantRequestViewModel[] | undefined
): TenantRequestViewModel[] =>
  _sortBy(tenantRequestList, [(x: TenantRequestViewModel) => x.dueDate]);
