import { TenantEnvironmentDto, TenantSummaryDto } from "fenergo-nebula-cloudcontrol-query-api";
import { TenantEnvironmentViewModel } from "../viewModels/tenant/TenantEnvironmentViewModel";
import { mapToDynamoDbConfigDto, mapToDynamoDbConfigViewModel } from "./DynamoDbConfigMapping";
import { mapToFenxServicesViewModel } from "./FenxServicesMapping";
import { mapToHostPortConfigDto, mapToHostPortConfigViewModel } from "./HostPortConfigMapping";
import {
  mapToHostPortReaderConfigDto,
  mapToHostPortReaderConfigViewModel,
} from "./HostPortReaderConfigMapping";
import { mapToS3ConfigDto, mapToS3ConfigViewModel } from "./S3ConfigMapping";

export const mapTenantSummaryDtoToViewModel = (
  model: TenantSummaryDto
): TenantEnvironmentViewModel => {
  const viewModel = new TenantEnvironmentViewModel();

  viewModel.tenantId = model.id;
  viewModel.tenantName = model.name;
  viewModel.environment = model.environment;
  viewModel.purpose = model.purpose;
  viewModel.awsRegion = model.awsRegion;
  viewModel.activeServiceIds = model.activeServiceIds;
  viewModel.lastEditedOn = model.lastEditedOn;
  viewModel.lastActivity = model.lastActivity;
  viewModel.lastActivityString = model.lastActivityString;
  viewModel.status = model.status;
  viewModel.createdOn = model.createdOn;

  return viewModel;
};
export class TenantMapping {
  public static mapToViewModel = (model: TenantEnvironmentDto): TenantEnvironmentViewModel => {
    const viewModel = mapTenantSummaryDtoToViewModel(model);

    viewModel.environmentId = model.environmentId;
    viewModel.rootUserId = model.rootUserId;
    viewModel.dynamoDbConfig = mapToDynamoDbConfigViewModel(model.dynamoDbConfig);
    viewModel.eventStoreConfig = mapToHostPortConfigViewModel(model.eventStoreConfig);
    viewModel.elasticSearchConfig = mapToHostPortConfigViewModel(model.elasticSearchConfig);
    viewModel.neptuneDbConfig = mapToHostPortReaderConfigViewModel(model.neptuneDbConfig);
    viewModel.s3Config = mapToS3ConfigViewModel(model.s3Config);
    viewModel.kmsKeyArn = model?.kmsKeyArn;
    viewModel.iamRoleArn = model?.iamRoleArn;
    viewModel.encryptionSecretArn = model?.encryptionSecretArn;
    viewModel.encryptionSecretName = model?.encryptionSecretName;
    viewModel.tenantOwnerId = model?.tenantOwnerId;
    viewModel.tenantFenxServices = mapToFenxServicesViewModel(model.tenantFenxServices);

    return viewModel;
  };

  public static mapToDto = (model: TenantEnvironmentViewModel): TenantEnvironmentDto => {
    const dto: TenantEnvironmentDto = {
      id: model.tenantId,
      name: model.tenantName,
      environment: model.environment,
      awsRegion: model.awsRegion,
      activeServiceIds: model.activeServiceIds,
      lastEditedOn: model.lastEditedOn,
      lastActivityString: model.lastActivityString,
      status: model.status,
      createdOn: model.createdOn,
      rootUserId: model.rootUserId,
      dynamoDbConfig: mapToDynamoDbConfigDto(model.dynamoDbConfig),
      eventStoreConfig: mapToHostPortConfigDto(model.eventStoreConfig),
      elasticSearchConfig: mapToHostPortConfigDto(model.elasticSearchConfig),
      neptuneDbConfig: mapToHostPortReaderConfigDto(model.neptuneDbConfig),
      s3Config: mapToS3ConfigDto(model.s3Config),
      kmsKeyArn: model.kmsKeyArn,
      iamRoleArn: model.iamRoleArn,
      encryptionSecretArn: model.encryptionSecretArn,
      encryptionSecretName: model.encryptionSecretName,
    };

    return dto;
  };
}
