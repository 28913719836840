import * as Yup from "yup";
import { TenantType } from "../constants/TenantType";
import { YesNoQuestion } from "../constants/YesNoQuestion";
import { TenantOwnerViewModel } from "../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";

export const getPortalRequirementViewModelValidationSchema = (
  values: TenantRequestViewModel
): Yup.ObjectSchema<any> => {
  return Yup.object<TenantRequestViewModel>().shape({
    tenantOwnerId: getBasicRequiredCheck(),
    clientTeamName: getBasicRequiredCheck(),
    tenantEnvironment: getBasicRequiredCheck(),
    departmentCostTaggedAgainst: getRulesForDepartmentApprover(values),
    approverId: getRulesForApproverId(values),
    costIncluded:
      values.tenantType === TenantType.Client ? getBasicRequiredCheck() : getOptionanlCheck(),
    contractSentToCbo:
      values.tenantType === TenantType.Client && values.costIncluded === YesNoQuestion.Yes
        ? getBasicRequiredCheck()
        : getOptionanlCheck(),
  });
};

export const getTenantRequirementViewModelValidationSchema = (
  values: TenantRequestViewModel
): Yup.ObjectSchema<any> => {
  return Yup.object<TenantRequestViewModel>().shape({
    existingClient: getBasicRequiredCheck(),
    clientTeamName: values.existingClient ? getBasicRequiredCheck() : getOptionanlCheck(),
    tenantOwnerId: values.existingClient === "Yes" ? getBasicRequiredCheck() : getOptionanlCheck(),
    businessLine: getRulesForClientFields(values),
    contractSigned: getRulesForClientFields(values),
    contractSentToCbo:
      values.contractSigned === "Yes" && values.tenantType === TenantType.Client
        ? getBasicRequiredCheck()
        : getOptionanlCheck(),
    departmentCostTaggedAgainst: getRulesForDepartmentApprover(values),
    approverId: getRulesForDepartmentApprover(values),
    customerSize: getRulesForClientFields(values),
    tenantEnvironment: getBasicRequiredCheck(),
    awsRegion: getBasicRequiredCheck(),
    otherAwsRegion: values.awsRegion === "Other" ? getBasicRequiredCheck() : getOptionanlCheck(),
    providers: values.ssoRequired === "Yes" ? getBasicRequiredCheck() : getOptionanlCheck(),
    arn: values.byokRequired === "Yes" ? getBasicRequiredCheck() : getOptionanlCheck(),
    internalManagedKey: getOptionanlCheck(),
    initialConfigToBeImported: getBasicRequiredCheck(),
    configurations:
      values.initialConfigToBeImported === "Yes" ? getBasicRequiredCheck() : getOptionanlCheck(),
    portalTenant: getBasicRequiredCheck(),
    salesforceRequired: getBasicRequiredCheck(),
    eSignatureRequired: getBasicRequiredCheck(),
    urgentRequest: getBasicRequiredCheck(),
    urgencyReason:
      values.urgentRequest === "Urgent"
        ? getAlphanumeircCheckWithLengthConstraint()
        : getOptionanlCheck(),
  });
};

export const getTenantOwnerViewModelValidationSchema = (
  values: TenantOwnerViewModel
): Yup.ObjectSchema<any> => {
  const isClientType = values.type === TenantType.Client;
  return Yup.object<TenantOwnerViewModel>().shape({
    businessLines: isClientType ? getBasicRequiredCheck() : getNullableCheck(),
    hasContractBeenSigned: isClientType ? getBasicRequiredCheck() : getNullableCheck(),
    department: getBasicRequiredCheck(),
    customerSize: isClientType ? getBasicRequiredCheck() : getNullableCheck(),
    keyContact: getEmailCheck(),
  });
};

const getRulesForClientFields = (values: any): any => {
  return values.tenantType === TenantType.Client && values.existingClient === YesNoQuestion.No
    ? getBasicRequiredCheck()
    : getNullableCheck();
};

const getRulesForDepartmentApprover = (values: any): any => {
  return values.contractSigned === YesNoQuestion.Yes
    ? getOptionanlCheck()
    : getBasicRequiredCheck();
};

const getRulesForApproverId = (values: TenantRequestViewModel): any => {
  if (values.tenantType === TenantType.Client) {
    return values.costIncluded === YesNoQuestion.No ? getBasicRequiredCheck() : getOptionanlCheck();
  } else {
    return getBasicRequiredCheck();
  }
};

const message = "Field must be completed.";
export const getBasicRequiredCheck = (): any => {
  return Yup.string().nullable().required(message);
};

const getOptionanlCheck = (): any => {
  return Yup.string().nullable();
};

const getNullableCheck = (): Yup.StringSchema<string | null | undefined> => {
  return Yup.string().nullable();
};

const getAlphanumeircCheckWithLengthConstraint = () => {
  return Yup.string()
    .required(message)
    .max(120, "The text entered in the field exceeds the character limit of 120");
};

const getEmailCheck = () => {
  return Yup.string()
    .nullable()
    .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, "Please enter a valid email address")
    .required(message);
};
