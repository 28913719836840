import React from "react";
import { FenTextInput } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { LookupType } from "../../constants/LookupType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { formikFieldWithErrors } from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName } from "../../utils/stringHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenInputWithHelpIcon } from "../FenInputWithHelpIcon";
import { RequestFormLabels } from "./RequestFormLabels";

export interface ITenantRequestFormSsoSection {
  staticLookup: LookupViewModel;
  formik: any;
}
export const TenantRequestFormSsoSection = ({
  staticLookup,
  formik,
}: ITenantRequestFormSsoSection): JSX.Element => {
  const classes = useStyles();
  const { values, setFieldValue } = formik;

  const updateSSORequired = (value: any, propertyName: string) => {
    if (values.ssoRequired) {
      setFieldValue("providers", null);
    }
    formik.setFieldValue(propertyName, value);
  };

  return (
    <>
      <FenInputWithHelpIcon
        tooltipDescription={"SSO can be updated later if required"}
        tooltipCondition={true}
        title={RequestFormLabels.SsoRequired}
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        valueKey="Value"
        name={"ssoRequired"}
        value={values.ssoRequired}
        onChange={updateSSORequired}
        className={clsx(classes.rowBox, classes.infoField)}
        data-tesid={"sso-required"}
        text-field-test-id={"sso-required-text-test-id"}
        fullWidth
      />
      {values.ssoRequired === YesNoQuestion.Yes && (
        <FenTextInput
          className={classes.infoField}
          name="providers"
          fullWidth
          required
          data-testid="providers"
          label={RequestFormLabels.Providers}
          {...formikFieldWithErrors(formik, "providers")}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  rowBox: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  infoField: {
    marginBottom: "1rem",
  },
}));
