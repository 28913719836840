const routeGuidRegex = "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";

export const routes = {
  login: "/",
  home: "/home",
  apiDocs: "/api-docs",
  apiDocsApi: "/api-docs/:api",
  gettingStarted: "/getting-started",
  userProfile: "/user-profile",
  userGuides: "/user-guides",
  glossary: "/glossary",
  devGuides: "/dev-guides",
  logout: "/logout",
  loginCallback: "/callback",
  logoutCallback: "/logout-callback",
  tenantManagement: "/tenant-management",
  tenantOwner: "/tenant-owner/",
  tenantOwnerDetail: `/tenant-owner/:id(${routeGuidRegex})/`,
  documentation: "/documentation",
  reporting: "/reporting",
  tenantProfilePage: "/tenant-profile",
  tenantRequestList: "/tenant-request",
  editTenantRequest: `/tenant-request/:id(${routeGuidRegex})/edit`,
  newTenantRequest: "/new-tenant-request",
  tenantRequestDetails: `/tenant-request/:id(${routeGuidRegex})`,
  portalRequestList: "/portal-request",
  newPortalRequest: "/new-portal-request",
  portalRequestDetails: `/portal-request/:id(${routeGuidRegex})`,
  editPortalRequest: `/portal-request/:id(${routeGuidRegex})/edit`,
};
