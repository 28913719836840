import React, { useCallback, useEffect, useState } from "react";
import { commonColors, FenButton, FenDropdown, FenTitle } from "@fenx/styleguide";
import { Box, makeStyles } from "@material-ui/core";
import { useFormik, setNestedObjectValues, FormikTouched } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { LookupType } from "../../constants/LookupType";
import { TenantType } from "../../constants/TenantType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { routes } from "../../routes";
import { ICloudControlService } from "../../services";
import { convertToFenListItem } from "../../utils/FenListItemsHelper";
import { defaultFormikDropdownFieldWithErrors } from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName, getTenantOwnerSafeName } from "../../utils/stringHelper";
import { getPortalRequirementViewModelValidationSchema } from "../../utils/yupHelper";
import { ApproverViewModel } from "../../viewModels/approver/ApproverViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestViewModel } from "../../viewModels/tenantRequest/TenantRequestViewModel";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenccConfirmationPage } from "../FenccConfirmationPage";
import { FenccDropdown } from "../FenccDropdown";
import { FenList, FenListItem } from "../FenList";
import { getFilteredApprovers } from "../tenantRequestForm/ApproverFilter";
import { RequestFormLabels } from "../tenantRequestForm/RequestFormLabels";
import { PortalRequestFormReviewPage } from "./PortalRequestFormReviewPage";

export interface PortalRequestFormPageProps {
  cloudcontrolService: ICloudControlService;
  id?: string;
  tenantOwners: TenantOwnerViewModel[];
  approver: ApproverViewModel[];
  portalRequest: TenantRequestViewModel;
}

export const PortalRequestFormPage = ({
  cloudcontrolService,
  tenantOwners,
  id,
  portalRequest,
  approver,
}: PortalRequestFormPageProps): JSX.Element => {
  const classStyling = useStyles();
  const history = useHistory();
  const [isReview, setIsReview] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [staticLookup, setStaticLookup] = useState<LookupViewModel>(new LookupViewModel());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: portalRequest,
    validationSchema: () =>
      Yup.lazy((formikValues: any) => getPortalRequirementViewModelValidationSchema(formikValues)),
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (submitValues: TenantRequestViewModel) => {
      try {
        setIsSavingChanges(true);
        id
          ? await cloudcontrolService.updatePortalRequest(id, submitValues)
          : await cloudcontrolService.createPortalRequest(submitValues);
        history.push(routes.tenantRequestList);
      } catch (error) {
        console.error("Error", error);
      } finally {
        setIsSavingChanges(false);
      }
    },
  });

  const { values, setFieldValue, errors, touched, submitForm } = formik;

  useEffect(() => {
    const getStaticLookup = async () => {
      const lookupResponse = await cloudcontrolService.getStaticLookup(
        values.tenantType,
        values.contractSigned ?? YesNoQuestion.Yes
      );
      setStaticLookup(lookupResponse);
    };
    getStaticLookup();
  }, [values.clientTeamName, values.contractSigned, values.tenantType, cloudcontrolService]);

  const [disableDepartment, setDisableDepartment] = useState<boolean>(
    values?.departmentCostTaggedAgainst !== undefined
  );

  const handleClientChange = (formikValue: any, propertyName: string) => {
    const selectedClient = tenantOwners?.find((x) => x.id === formikValue);
    setFieldValue("clientTeamName", selectedClient?.name ?? null);
    setFieldValue("contractSigned", selectedClient?.hasContractBeenSigned ?? null);
    setFieldValue("departmentCostTaggedAgainst", selectedClient?.department ?? null);
    setFieldValue("costIncluded", null);
    if (selectedClient?.department) {
      setDisableDepartment(true);
    } else {
      setDisableDepartment(false);
    }
    formik.setFieldValue(propertyName, formikValue);
  };

  const handleEnvironmentCheckboxChange = useCallback(
    (_name: string, _item: FenListItem) => {
      if (!values.tenantEnvironment) {
        values.tenantEnvironment = [];
      }

      if (values.tenantEnvironment.includes(_item.id)) {
        values.tenantEnvironment = values.tenantEnvironment.filter((v: string) => v !== _item.id);
      } else {
        values.tenantEnvironment.push(_item.id);
      }

      setFieldValue("tenantEnvironment", values.tenantEnvironment);
    },
    [setFieldValue, values]
  );
  const handleDepartmentChanged = (value: any, propertyName: any) => {
    formik.setFieldValue("approverId", null);
    formik.setFieldValue("approverOfTheDepartment", null);
    formik.setFieldValue(propertyName, value);
  };

  const handleApproverChange = (value: any, propertyName: string) => {
    formik.setFieldValue("approverOfTheDepartment", approver.find((a) => a.Id === value)?.Name);
    formik.setFieldValue(propertyName, value);
  };

  const handleCostIncludedChange = (value: any, propertyName: string) => {
    formik.setFieldValue("approverId", null);
    formik.setFieldValue("approverOfTheDepartment", null);
    formik.setFieldValue("approverUserId", null);
    formik.setFieldValue("contractSentToCbo", null);
    formik.setFieldValue(propertyName, value);
  };
  const redirectToReviewPage = async () => {
    const totalErrors = await formik.validateForm();
    if (Object.keys(totalErrors).length === 0) {
      setIsReview(true);
    } else {
      formik.setTouched(
        setNestedObjectValues<FormikTouched<TenantRequestViewModel>>(totalErrors, true)
      );
    }
  };

  const handlePreviousPageCallback = () => {
    setIsReview(!isReview);
  };

  if (isSavingChanges) {
    return <FenccConfirmationPage />;
  }

  if (isReview) {
    return (
      <PortalRequestFormReviewPage
        formik={formik}
        callback={handlePreviousPageCallback}
        submitForm={submitForm}
        staticLookup={staticLookup}
      />
    );
  }
  return (
    <Box className={classStyling.rootStyle}>
      <FenTitle title="New Portal Request" variant="h1" className={classStyling.headerStyle} />
      <FenDropdown
        className={classStyling.infoFieldStyle}
        fullWidth
        label={
          values.tenantType === TenantType.Client
            ? RequestFormLabels.ClientName
            : RequestFormLabels.TeamName
        }
        name="tenantOwnerId"
        required
        options={tenantOwners}
        valueKey="id"
        getOptionLabel={(x) => getTenantOwnerSafeName(x)}
        data-testid="client-name"
        {...defaultFormikDropdownFieldWithErrors(formik, "tenantOwnerId", handleClientChange)}
      />
      <FenList
        required
        className={classStyling.infoFieldStyle}
        items={convertToFenListItem("Purpose", staticLookup, values.clientTeamName)}
        title={RequestFormLabels.TenantEnvironment}
        name={"tenantEnvironment"}
        enableSelection={true}
        onSelect={handleEnvironmentCheckboxChange}
        selectedIds={values.tenantEnvironment ?? []}
        clientName={values.clientTeamName ?? ""}
        error={touched.tenantEnvironment ? errors.tenantEnvironment : ""}
      />

      {values.tenantType === TenantType.Internal && (
        <>
          <FenccDropdown
            className={classStyling.infoFieldStyle}
            selectedValue={values.departmentCostTaggedAgainst ?? ""}
            fullWidth
            disabled={disableDepartment}
            label={RequestFormLabels.Department}
            name="departmentCostTaggedAgainst"
            required
            options={getSpecificSelectListItemViewModel(staticLookup, LookupType.Department)}
            valueKey="Value"
            displayKey="Text"
            getOptionLabel={(x) => getSelectItemSafeName(x)}
            data-testid="department-cost-tagged"
            errors={touched.departmentCostTaggedAgainst ? errors.departmentCostTaggedAgainst : ""}
            {...defaultFormikDropdownFieldWithErrors(
              formik,
              "departmentCostTaggedAgainst",
              handleDepartmentChanged
            )}
          />
          <FenccDropdown
            selectedValue={values.approverId ?? ""}
            className={classStyling.infoFieldStyle}
            fullWidth
            label={RequestFormLabels.Approver}
            name="approverId"
            required
            options={getFilteredApprovers(approver, values.departmentCostTaggedAgainst || "")}
            valueKey="Id"
            getOptionLabel={(x) => x.Name}
            data-testid="department-approver"
            errors={touched.approverId ? errors.approverId : ""}
            {...defaultFormikDropdownFieldWithErrors(formik, "approverId", handleApproverChange)}
          />
        </>
      )}
      {values.tenantType === TenantType.Client && (
        <>
          <FenccDropdown
            className={classStyling.infoFieldStyle}
            selectedValue={values.costIncluded ?? ""}
            fullWidth
            label={RequestFormLabels.PortalCostIncluded}
            name="costIncluded"
            required
            options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
            valueKey="Value"
            getOptionLabel={(x) => getSelectItemSafeName(x)}
            errors={touched.costIncluded ? errors.costIncluded : ""}
            data-testid={"cost-included"}
            {...defaultFormikDropdownFieldWithErrors(
              formik,
              "costIncluded",
              handleCostIncludedChange
            )}
          />
          {values.costIncluded === YesNoQuestion.Yes && (
            <FenDropdown
              className={classStyling.infoFieldStyle}
              fullWidth
              label={RequestFormLabels.ContractSent}
              name="contractSentToCbo"
              required
              options={getSpecificSelectListItemViewModel(staticLookup, LookupType.YesNoQuestion)}
              valueKey="Value"
              getOptionLabel={(x) => getSelectItemSafeName(x)}
              data-testid="contract-sent-to-cbo"
              {...defaultFormikDropdownFieldWithErrors(formik, "contractSentToCbo")}
            />
          )}

          {values.costIncluded === YesNoQuestion.No && (
            <>
              <FenccDropdown
                className={classStyling.infoFieldStyle}
                selectedValue={values.departmentCostTaggedAgainst ?? ""}
                fullWidth
                disabled={disableDepartment}
                label={RequestFormLabels.Department}
                name="departmentCostTaggedAgainst"
                required
                options={getSpecificSelectListItemViewModel(staticLookup, LookupType.Department)}
                valueKey="Value"
                displayKey="Text"
                getOptionLabel={(x) => getSelectItemSafeName(x)}
                data-testid="department-cost-tagged"
                errors={
                  touched.departmentCostTaggedAgainst ? errors.departmentCostTaggedAgainst : ""
                }
                {...defaultFormikDropdownFieldWithErrors(
                  formik,
                  "departmentCostTaggedAgainst",
                  handleDepartmentChanged
                )}
              />

              <FenccDropdown
                selectedValue={values.approverId ?? ""}
                className={classStyling.infoFieldStyle}
                fullWidth
                label={RequestFormLabels.Approver}
                name="approverId"
                required
                options={getFilteredApprovers(approver, values.departmentCostTaggedAgainst || "")}
                valueKey="Id"
                getOptionLabel={(x) => x.Name}
                data-testid="department-approver"
                errors={touched.approverId ? errors.approverId : ""}
                {...defaultFormikDropdownFieldWithErrors(
                  formik,
                  "approverId",
                  handleApproverChange
                )}
              />
            </>
          )}
        </>
      )}

      <FenButton
        onClick={redirectToReviewPage}
        className={classStyling.buttonStyle}
        data-testid="submit-button"
        text="Next"
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  rootStyle: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4rem",
    marginBottom: "6rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  headerStyle: {
    marginBottom: "1rem",
  },
  buttonStyle: {
    maxWidth: "14rem",
    minWidth: "8rem",
    minHeight: "4.5rem",
    marginTop: "1.5rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "20%",
    backgroundColor: commonColors.bluesPrimaryBlue,
    fontSize: "2rem",
    fontWeight: 700,
    "&:hover,&:focus": {
      backgroundColor: commonColors.bluesPrimaryBlue,
    },
    color: commonColors.greysLightGrey1,
  },
  infoFieldStyle: {
    marginBottom: "1rem",
  },
}));
