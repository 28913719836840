import { LookupDto } from "fenergo-nebula-cloudcontrol-query-api";
import { LookupViewModel } from "../viewModels/tenantRequestForm/LookupViewModel";
import { mapToKeyValuePairViewModel } from "./KeyValuePairMapping";

export const mapToLookupViewModel = (dto?: LookupDto): LookupViewModel => {
  const viewModel = new LookupViewModel();
  if (!dto) {
    return viewModel;
  }

  viewModel.List = dto.allValues?.map((t) => mapToKeyValuePairViewModel(t)) ?? [];

  return viewModel;
};
