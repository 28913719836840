import { FormikContextType, FormikHandlers, getIn } from "formik";

export interface FormikBaseInputProps {
  onBlur: FormikHandlers["handleBlur"];
  multiple?: boolean;
  checked?: boolean;
  value: any;
  error?: string;
}

export interface FormikDropdownInputProps extends FormikBaseInputProps {
  onChange: (value: any, name: string) => void;
}

export interface FormikInputProps extends FormikBaseInputProps {
  onChange: FormikHandlers["handleChange"];
}

export function defaultFormikDropdownFieldWithErrors<T>(
  formik: FormikContextType<T>,
  name: string,
  onChangeFunction?: (value: any, propertyName: string) => void
): FormikDropdownInputProps {
  return {
    ...formikFieldWithErrors(formik, name),
    onChange: onChangeFunction
      ? onChangeFunction
      : (value: any, propertyName: string) => {
          formik.setFieldValue(propertyName, value);
        },
  };
}

export function formikFieldWithErrors<T>(
  formik: FormikContextType<T>,
  name: string
): FormikInputProps {
  return {
    ...formik.getFieldProps(name),
    error: getIn(formik.touched, name) ? getIn(formik.errors, name) : undefined,
  };
}
