import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { FenButton, FenSpinner, FenTitle } from "@fenx/styleguide";
import { Container, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { commonColors } from "../assets/themes/defaultTheme";
import { ParamTypes } from "../constants/paramTypes";
import { RequestType } from "../constants/RequestType";
import { TenantRequestStatus } from "../constants/TenantRequestStatus";
import { YesNoQuestion } from "../constants/YesNoQuestion";
import { CloudControlRoles } from "../core/auth/CloudControlRoles";
import { isUserInRoles } from "../core/auth/roleHelper";
import { getIdToken } from "../core/auth/tokenHelper";
import { routes } from "../routes";
import { ICloudControlService } from "../services";
import { formikFieldWithErrors } from "../utils/formikFieldHelper";
import { portalRequestEditUrl, tenantRequestEditUrl } from "../utils/routeUtil";
import { getTenantRequirementViewModelValidationSchema } from "../utils/yupHelper";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";
import { LookupViewModel } from "../viewModels/tenantRequestForm/LookupViewModel";
import { AdditionalComments } from "./AdditionalComment";
import { PortalRequestDetails } from "./portalRequestForm/PortalRequestDetails";
import { SetStatusModal } from "./SetStatusModal";
import { StatusChipCell } from "./StatusChipCell";
import { FormReviewDetailsSection } from "./tenantRequestForm/FormReviewDetailsSection";
import { FormReviewGeneralSection } from "./tenantRequestForm/FormReviewGeneralSection";

export interface IRequestSummaryProps {
  cloudcontrolService: ICloudControlService;
  requestType: RequestType;
}

export const RequestSummary = ({
  cloudcontrolService,
  requestType,
}: IRequestSummaryProps): JSX.Element => {
  const { accounts } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<TenantRequestViewModel>();
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [staticLookup, setStaticLookup] = useState<LookupViewModel>(new LookupViewModel());

  const goToEdit = () => {
    requestType === RequestType.TenantCreation
      ? history.push(tenantRequestEditUrl(id), {
          ...request,
        })
      : history.push(portalRequestEditUrl(id), {
          ...request,
        });
  };
  const idToken = getIdToken();
  const classes = useStyles();
  const initialValues: TenantRequestViewModel =
    (request as TenantRequestViewModel) ?? new TenantRequestViewModel();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: () =>
      Yup.lazy((formikValues: any) => getTenantRequirementViewModelValidationSchema(formikValues)),
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (submitValues: TenantRequestViewModel) => {
      try {
        requestType === RequestType.TenantCreation
          ? await cloudcontrolService.createTenantRequest(submitValues)
          : await cloudcontrolService.createPortalRequest(submitValues);
        history.push(routes.tenantRequestList);
      } catch (error) {
        console.error("Error", error);
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchRequestById = async () => {
      try {
        const requestData =
          requestType === RequestType.TenantCreation
            ? await cloudcontrolService.getTenantRequestById(id)
            : await cloudcontrolService.getPortalRequestById(id);
        if (!requestData) {
          setIsLoading(false);
          return;
        }
        setRequest(requestData);

        const lookupResponse = await cloudcontrolService.getStaticLookup(
          requestData.tenantType,
          requestData.contractSigned ?? YesNoQuestion.No
        );
        setStaticLookup(lookupResponse);
        setRequest(requestData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequestById();
  }, [cloudcontrolService, id, accounts, requestType]);

  if (isLoading) {
    return <FenSpinner />;
  }

  const ifEditAuthorized = (): boolean => {
    const { localAccountId } = accounts[0];
    return (
      isUserInRoles(idToken, [CloudControlRoles.Admin]) ||
      localAccountId === request?.requestorId ||
      localAccountId === request?.approverUserId
    );
  };

  const ifSetStatusAuthorized = (): boolean => {
    const { localAccountId } = accounts[0];
    return (
      isUserInRoles(idToken, [CloudControlRoles.Admin]) ||
      localAccountId === request?.approverUserId
    );
  };

  const ifCanSetStatus = (): boolean => {
    if (initialValues?.status === TenantRequestStatus.Approval) {
      return initialValues.approverUserId === accounts[0].localAccountId;
    }
    return (
      isUserInRoles(idToken, [CloudControlRoles.Admin]) &&
      initialValues?.status !== TenantRequestStatus.Completed &&
      initialValues?.status !== TenantRequestStatus.Archived
    );
  };

  const handleOnCloseModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <SetStatusModal
        requestType={requestType}
        open={modalOpen}
        callback={handleOnCloseModal}
        data={initialValues}
        cloudcontrolService={cloudcontrolService}
      />
      <Container maxWidth="md" className={classes.reviewRoot}>
        <Grid container spacing={2}>
          <Grid item xs={8} className={classes.headline}>
            <FenTitle title="Request Summary" variant="h1" />
            <StatusChipCell cellValue={initialValues.status} />
          </Grid>
          <Grid item xs={4} container>
            <Grid container justify="flex-end" spacing={1}>
              {(initialValues.status === TenantRequestStatus.New ||
                initialValues.status === TenantRequestStatus.Rejected ||
                initialValues.status === TenantRequestStatus.Approval ||
                initialValues.status === TenantRequestStatus.RejectedByApprover) &&
                ifEditAuthorized() && (
                  <Grid item>
                    <FenButton
                      onClick={goToEdit}
                      data-testid="back-button"
                      text={"Edit"}
                      secondary
                    />
                  </Grid>
                )}
              {ifSetStatusAuthorized() && ifCanSetStatus() && (
                <Grid item>
                  <FenButton
                    onClick={handleOnCloseModal}
                    className={clsx(classes.setStatusButton)}
                    data-testid="back-button"
                    text={"Set Status"}
                    secondary
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <FormReviewGeneralSection formik={formik} />
        {requestType === RequestType.PortalCreation ? (
          <PortalRequestDetails formik={formik} staticLookup={staticLookup} />
        ) : (
          <FormReviewDetailsSection formik={formik} staticLookup={staticLookup} />
        )}

        <AdditionalComments
          {...formikFieldWithErrors(formik, "additionalComments")}
          disabled
          variant="standard"
          inputProps={{ disableUnderline: true }}
          className={classes.boxContainer}
        />
      </Container>
    </>
  );
};

const useStyles = makeStyles(() => ({
  reviewRoot: {
    marginTop: "2rem",
    marginBottom: "6rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
  setStatusButton: {
    backgroundColor: commonColors.bluesPrimaryBlue,
    color: commonColors.white,
    "&:hover,&:focus": {
      backgroundColor: commonColors.bluesPrimaryBlue,
    },
  },
  headline: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  boxContainer: {
    backgroundColor: commonColors.greyBlue,
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary,
    },
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "1rem",
  },
}));
