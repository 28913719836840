/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo } from "react";
import { FenTooltip } from "@fenx/styleguide";
import {
  Theme,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";
import { FenListHeader } from "./FenListHeader";

export interface FenListItem {
  id: string;
  label: string;
  tooltip?: string;
  group?: string;
  description?: string;
}

export interface FenListProps {
  /** List items (options) */
  items: FenListItem[];
  /** List title text */
  title: string;
  /** Name property (passed to onSelect callbacks) */
  name: string;
  /** If true, each row has checkbox */
  enableSelection?: boolean;
  /** Callback when checkbox state changed */
  onSelect: (name: string, item: FenListItem, clientName?: string) => void;
  /** Selected options (ID's) */
  selectedIds?: string[];
  /** If true, title row is rendered */
  showHeader?: boolean;
  /** CSS class applied to root element */
  className?: string;
  /** Client name use to poopulate the side client-puprpose description */
  clientName: string;
  /** Required or optional */
  required?: boolean;
  /** Error message */
  error?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0,
    minHeight: 38,
    backgroundColor: theme.palette.common.white,
  },
  listItemText: {
    maxWidth: "8rem",
  },
}));

export const FenList = memo(
  ({
    items,
    title,
    name,
    onSelect,
    selectedIds,
    enableSelection,
    showHeader = true,
    className,
    clientName,
    required,
    error,
  }: FenListProps): JSX.Element => {
    const classes = useStyles();

    const renderListItem = (item: FenListItem) => (
      <FenTooltip key={item.id} title={item.tooltip} condition={!!item.tooltip}>
        <ListItem
          button
          className={classes.listItem}
          {...(enableSelection && {
            onClick: () => onSelect(name, item, clientName),
          })}
        >
          {enableSelection && (
            <ListItemIcon>
              <Checkbox
                edge="start"
                disableRipple
                checked={selectedIds?.includes(item.id)}
                data-testid={`select-${item.id}`}
                size="small"
              />
            </ListItemIcon>
          )}
          <ListItemText
            className={classes.listItemText}
            primary={item.label}
            primaryTypographyProps={{ style: { fontSize: "1rem" } }}
          />
          {selectedIds?.includes(item.id) && clientName && (
            <ListItemText
              primary={item.description?.toLowerCase() ?? ""}
              primaryTypographyProps={{ style: { fontSize: "1rem" } }}
            />
          )}
        </ListItem>
      </FenTooltip>
    );

    const renderSimple = items.map((listItem) => renderListItem(listItem));

    return (
      <List className={clsx(classes.list, className)}>
        {showHeader && <FenListHeader title={title} required={required} error={error} />}
        {renderSimple}
      </List>
    );
  }
);
