import {
  Client as CloudControlCommandClient,
  ClientPortalRequestDtoServiceRequest,
  ClientTenantOwnerDetailsDtoServiceRequest,
  ClientTenantRequestDtoServiceRequest,
  ClientUpdatePortalRequestDtoServiceRequest,
  ClientUpdateTenantRequestDtoServiceRequest,
  InternalPortalRequestDtoServiceRequest,
  InternalTenantOwnerDetailsDtoServiceRequest,
  InternalTenantRequestDtoServiceRequest,
  InternalUpdatePortalRequestDtoServiceRequest,
  InternalUpdateTenantRequestDtoServiceRequest,
  PortalRequestStatusDtoServiceRequest,
  TenantRequestStatusDtoServiceRequest,
} from "fenergo-nebula-cloudcontrol-command-api";
import {
  ApproverDto,
  Client as CloudControlQueryClient,
  LookupDto,
  PortalRequestDto,
  TenantOwnerTenantDto,
  TenantRequestDto,
  TenantUserExtensionDto,
} from "fenergo-nebula-cloudcontrol-query-api";
import { TenantType } from "../constants/TenantType";
import { mapToApproverViewModel } from "../mappings/ApproverMapping";
import { mapToLookupViewModel } from "../mappings/LookupMapping";
import { PortalRequestMapping } from "../mappings/PortalRequestMapping";
import { mapTenantSummaryDtoToViewModel, TenantMapping } from "../mappings/TenantMapping";
import {
  mapToTenantOwnerLiteViewModel,
  mapToTenantOwnerViewModel,
  mapViewModelToClientTenantOwnerDetailsDto,
  mapViewModelToInternalTenantOwnerDetailsDto,
} from "../mappings/TenantOwnerMapping";
import { TenantRequestMapping } from "../mappings/TenantRequestMapping";
import { mapTenantUserExtensionDtoToViewModel } from "../mappings/TenantUserExtensionMapping";
import { UserInfoMapping } from "../mappings/UserInfoMapping";
import { globalTenant } from "../utils/common";
import { RequestHelper } from "../utils/RequestHelper";
import { ApproverViewModel } from "../viewModels/approver/ApproverViewModel";
import { TenantEnvironmentViewModel } from "../viewModels/tenant/TenantEnvironmentViewModel";
import { TenantUserExtensionViewModel } from "../viewModels/tenant/tenantUser/TenantUserExtensionViewModel";
import { TenantOwnerViewModel } from "../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestStatusViewModel } from "../viewModels/tenantRequest/columns/TenantRequestStatusViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";
import { LookupViewModel } from "../viewModels/tenantRequestForm/LookupViewModel";
import { UserInfoViewModel } from "../viewModels/userInfo/UserInfoViewModel";
import { ICloudControlService } from "./ICloudControlService";

export class CloudControlService implements ICloudControlService {
  private readonly commandApiRoot: string;
  private readonly queryApiRoot: string;
  private readonly accessToken: string;

  constructor(commandApiRoot: string, queryApiRoot: string, accessToken: string) {
    this.commandApiRoot = commandApiRoot;
    this.queryApiRoot = queryApiRoot;
    this.accessToken = accessToken;
  }
  getQueryApiClient(): CloudControlQueryClient {
    return new CloudControlQueryClient(
      this.queryApiRoot,
      RequestHelper.http(globalTenant, this.accessToken)
    );
  }
  getCommandApiClient(): CloudControlCommandClient {
    return new CloudControlCommandClient(
      this.commandApiRoot,
      RequestHelper.http(globalTenant, this.accessToken)
    );
  }

  async getTenantsSummary(): Promise<TenantEnvironmentViewModel[]> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantsSummary(globalTenant);
    return response.data?.map((t) => mapTenantSummaryDtoToViewModel(t)) || [];
  }

  async getTenants(): Promise<TenantEnvironmentViewModel[]> {
    const api = this.getQueryApiClient();
    const response = await api.getTenants(globalTenant);
    return response.data?.map((t) => TenantMapping.mapToViewModel(t)) || [];
  }

  async getTenantById(tenantId: string): Promise<TenantEnvironmentViewModel[]> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantById(tenantId, globalTenant);
    return response.data?.map((t) => TenantMapping.mapToViewModel(t)) || [];
  }

  async getUserInfo(userId: string): Promise<UserInfoViewModel> {
    const api = this.getQueryApiClient();
    const response = await api.getUserInfo(userId, globalTenant);
    return UserInfoMapping.mapToViewModel(response.data);
  }
  async getUserProfilePicture(userId: string): Promise<string> {
    const api = this.getQueryApiClient();
    const response = await api.getUserProfilePicture(userId, globalTenant);
    return response.data ? response.data : "";
  }

  async getTenantOwners(): Promise<TenantOwnerViewModel[]> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantOwners(globalTenant);
    return response.data?.map((t) => mapToTenantOwnerViewModel(t)) || [];
  }

  async getTenantOwnerById(tenantOwnerId: string): Promise<TenantOwnerViewModel> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantOwnerById(tenantOwnerId, globalTenant);
    return mapToTenantOwnerViewModel(response?.data as TenantOwnerTenantDto);
  }

  async getUsersByTenant(
    tenantId: string,
    tenantEnvironmentId: string
  ): Promise<TenantUserExtensionViewModel[]> {
    const api = this.getQueryApiClient();
    const response = await api.getUsersByTenant(tenantId, tenantEnvironmentId, globalTenant);
    return (
      response.data?.map((t: TenantUserExtensionDto) => mapTenantUserExtensionDtoToViewModel(t)) ||
      []
    );
  }

  async getStaticLookup(
    tenantType: string,
    hasContractBeenSigned: string
  ): Promise<LookupViewModel> {
    const api = this.getQueryApiClient();
    const response = await api.staticLookUp(tenantType, hasContractBeenSigned, globalTenant);
    return mapToLookupViewModel(response?.data as LookupDto);
  }

  async getClients(): Promise<TenantOwnerTenantDto[]> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantOwnersLite(globalTenant);
    return response.data?.map((t: TenantOwnerTenantDto) => mapToTenantOwnerLiteViewModel(t)) || [];
  }

  async getClientsForTenantRequest(tenantRequestId: string): Promise<TenantOwnerTenantDto[]> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantOwnersForUpdateTenantRequest(
      tenantRequestId,
      tenantRequestId,
      globalTenant
    );
    return response.data?.map((t: TenantOwnerTenantDto) => mapToTenantOwnerLiteViewModel(t)) || [];
  }

  async getApprovers(): Promise<ApproverViewModel[]> {
    const api = this.getQueryApiClient();
    const response = await api.getAllApprovers(globalTenant);
    return response.data?.map((t: ApproverDto) => mapToApproverViewModel(t)) || [];
  }

  async getTenantRequestAndPortalRequest(): Promise<TenantRequestViewModel[]> {
    const api = this.getQueryApiClient();
    const listOfTenantRequests =
      (await api.getAllTenantRequest(globalTenant)).data?.map((t: TenantRequestDto) =>
        TenantRequestMapping.mapDtoToViewModel(t)
      ) || [];

    const listOfPortalRequests =
      (await api.getAllPortalRequest(globalTenant)).data?.map((t: PortalRequestDto) =>
        PortalRequestMapping.mapDtoToViewModel(t)
      ) || [];

    return listOfTenantRequests.concat(listOfPortalRequests);
  }

  async getPortalRequestById(portalRequestId: string): Promise<TenantRequestViewModel> {
    const api = this.getQueryApiClient();
    const response = await api.getPortalRequestById(portalRequestId, globalTenant);
    return PortalRequestMapping.mapDtoToViewModel(response.data);
  }

  async getTenantRequestById(tenantRequestId: string): Promise<TenantRequestViewModel> {
    const api = this.getQueryApiClient();
    const response = await api.getTenantRequestById(tenantRequestId, globalTenant);
    return TenantRequestMapping.mapDtoToViewModel(response.data);
  }

  async createPortalRequest(viewModel: TenantRequestViewModel): Promise<TenantRequestViewModel> {
    return viewModel.tenantType === TenantType.Client
      ? this.createClientPortalRequest(viewModel)
      : this.createInternalPortalRequest(viewModel);
  }

  async createTenantRequest(viewModel: TenantRequestViewModel): Promise<TenantRequestViewModel> {
    if (viewModel.tenantType === TenantType.Client) {
      return this.createClientTenantRequest(viewModel);
    }

    return this.createInternalTenantRequest(viewModel);
  }

  async createClientTenantRequest(
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: ClientTenantRequestDtoServiceRequest = {
      data: TenantRequestMapping.mapViewModelToClientTenantRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    const response = await api.createClientTenantRequest(globalTenant, body);
    viewModel.tenantRequestId = response.data;
    return viewModel;
  }

  async createClientPortalRequest(
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: ClientPortalRequestDtoServiceRequest = {
      data: PortalRequestMapping.mapViewModelToClientPortalRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    const response = await api.createClientPortalRequest(globalTenant, body);
    viewModel.tenantRequestId = response.data;
    return viewModel;
  }

  async createInternalTenantRequest(
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: InternalTenantRequestDtoServiceRequest = {
      data: TenantRequestMapping.mapViewModelToInternalTenantRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    const response = await api.createInternalTenantRequest(globalTenant, body);
    viewModel.tenantRequestId = response.data;
    return viewModel;
  }
  async createInternalPortalRequest(
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: InternalPortalRequestDtoServiceRequest = {
      data: PortalRequestMapping.mapViewModelToInternalPortalRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    const response = await api.createInternalPortalRequest(globalTenant, body);
    viewModel.tenantRequestId = response.data;
    return viewModel;
  }

  async updateTenantOwnerDetails(
    tenantOwnerId: string,
    viewModel: TenantOwnerViewModel
  ): Promise<TenantOwnerViewModel> {
    if (viewModel.type === TenantType.Client) {
      return this.updateClientTenantOwnerDetails(tenantOwnerId, viewModel);
    }

    return this.updateInternalTenantOwnerDetails(tenantOwnerId, viewModel);
  }

  async updateClientTenantOwnerDetails(
    tenantOwnerId: string,
    viewModel: TenantOwnerViewModel
  ): Promise<TenantOwnerViewModel> {
    const body: ClientTenantOwnerDetailsDtoServiceRequest = {
      data: mapViewModelToClientTenantOwnerDetailsDto(viewModel),
    };
    const api = this.getCommandApiClient();
    await api.updateClientTenantOwnerDetails(tenantOwnerId, globalTenant, body);
    return viewModel;
  }

  async updateInternalTenantOwnerDetails(
    tenantOwnerId: string,
    viewModel: TenantOwnerViewModel
  ): Promise<TenantOwnerViewModel> {
    const body: InternalTenantOwnerDetailsDtoServiceRequest = {
      data: mapViewModelToInternalTenantOwnerDetailsDto(viewModel),
    };
    const api = this.getCommandApiClient();
    await api.updateInternalTenantOwnerDetails(tenantOwnerId, globalTenant, body);
    return viewModel;
  }

  async updatePortalRequest(
    portalRequestId: string,
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    return viewModel.tenantType === TenantType.Client
      ? this.updateClientPortalRequest(portalRequestId, viewModel)
      : this.updateInternalPortalRequest(portalRequestId, viewModel);
  }

  async updateTenantRequest(
    tenantRequestId: string,
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    if (viewModel.tenantType === TenantType.Client) {
      return this.updateClientTenantRequest(tenantRequestId, viewModel);
    }

    return this.updateInternalTenantRequest(tenantRequestId, viewModel);
  }

  async updateClientPortalRequest(
    tenantRequestId: string,
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: ClientUpdatePortalRequestDtoServiceRequest = {
      data: PortalRequestMapping.mapViewModelToClientUpdatePortalRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    await api.updateClientPortalRequest(tenantRequestId, globalTenant, body);
    return viewModel;
  }

  async updateClientTenantRequest(
    tenantRequestId: string,
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: ClientUpdateTenantRequestDtoServiceRequest = {
      data: TenantRequestMapping.mapViewModelToClientUpdateTenantRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    await api.updateClientTenantRequest(tenantRequestId, globalTenant, body);
    return viewModel;
  }

  async updateInternalTenantRequest(
    tenantRequestId: string,
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: InternalUpdateTenantRequestDtoServiceRequest = {
      data: TenantRequestMapping.mapViewModelToInternalUpdateTenantRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    await api.updateInternalTenantRequest(tenantRequestId, globalTenant, body);
    return viewModel;
  }

  async updateInternalPortalRequest(
    portalRequestId: string,
    viewModel: TenantRequestViewModel
  ): Promise<TenantRequestViewModel> {
    const body: InternalUpdatePortalRequestDtoServiceRequest = {
      data: PortalRequestMapping.mapViewModelToInternalUpdatePortalRequestDto(viewModel),
    };
    const api = this.getCommandApiClient();
    await api.updateInternalPortalRequest(portalRequestId, globalTenant, body);
    return viewModel;
  }

  async updateTenantRequestStatus(
    tenantRequestId: string,
    viewModel: TenantRequestStatusViewModel
  ): Promise<void> {
    const body: TenantRequestStatusDtoServiceRequest = {
      data: TenantRequestMapping.mapViewModelToTenantRequestStatusDto(viewModel),
    };
    const api = this.getCommandApiClient();
    return api.updateTenantRequestStatus(tenantRequestId, globalTenant, body);
  }

  async updatePortalRequestStatus(
    portalRequestId: string,
    viewModel: TenantRequestStatusViewModel
  ): Promise<void> {
    const body: PortalRequestStatusDtoServiceRequest = {
      data: PortalRequestMapping.mapViewModelToPortalRequestStatusDto(viewModel),
    };

    const api = this.getCommandApiClient();
    return api.updatePortalRequestStatus(portalRequestId, globalTenant, body);
  }
}
