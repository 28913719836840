import React from "react";
import { AccountInfo } from "@azure/msal-browser";
import MuiContainer from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { User } from "oidc-client";
import { Route, Switch } from "react-router-dom";
import { CreateFormPage } from "../components/CreateFormPage";
import { PortalRequestEditForm } from "../components/portalRequestForm/PortalRequestEditForm";
import { ReportingPage } from "../components/reporting/ReportingPage";
import { RequestSummary } from "../components/RequestSummary";
import { TenantList } from "../components/tenantManagement/TenantList";
import { TenantOwnerProfile } from "../components/tenantManagement/TenantOwnerProfile";
import { TenantProfilePage } from "../components/tenantManagement/TenantProfilePage";
import { TenantRequestPage } from "../components/tenantRequest/TenantRequestPage";
import { TenantRequestEditForm } from "../components/tenantRequestForm/TenantRequestEditForm";
import { ProfilePage } from "../components/userProfile/ProfilePage";
import { RequestType } from "../constants/RequestType";
import { routes } from "../routes";
import { CloudControlService } from "../services/CloudControlService";
import { OidcAuthService } from "../services/OidcAuthService";
import { Login } from "./auth/Login";
import { LoginCallback } from "./auth/LoginCallback";
import { Logout } from "./auth/Logout";
import { LogoutCallback } from "./auth/LogoutCallback";
import { Home } from "./views/Home";
import { NotFound } from "./views/NotFound";

interface IContentProps {
  user: User | null;
  authService: OidcAuthService;
  cloudcontrolService: CloudControlService;
  userInfo: AccountInfo | null;
}

export const Content: React.FC<IContentProps> = ({
  authService,
  cloudcontrolService,
  user,
  userInfo,
}: IContentProps) => {
  const classes = useStyles();
  const userLoggedIn = (user && !user.expired) ?? false;

  return (
    <MuiContainer disableGutters className={classes.root} component="main" maxWidth={false}>
      <Switch>
        <Route exact path={routes.login} render={() => <Login />} />
        <Route
          path={routes.loginCallback}
          render={() => <LoginCallback userManager={authService.getUserManager()} />}
        />
        <Route
          path={routes.logout}
          render={() => <Logout userInfo={userInfo} userLoggedIn={userLoggedIn} />}
        />
        <Route
          path={routes.logoutCallback}
          render={() => <LogoutCallback userManager={authService.getUserManager()} />}
        />
        <Route path={routes.home} component={Home} />
        <Route
          path={routes.tenantManagement}
          render={() => <TenantList cloudcontorlService={cloudcontrolService} />}
        />
        <Route
          path={routes.userProfile}
          render={() => <ProfilePage cloudcontrolService={cloudcontrolService} />}
        />
        <Route
          exact
          path={routes.tenantOwnerDetail}
          render={() => <TenantOwnerProfile cloudcontrolService={cloudcontrolService} />}
        />
        <Route
          path={`${routes.tenantProfilePage}/:id`}
          render={() => <TenantProfilePage cloudcontrolService={cloudcontrolService} />}
        />
        <Route
          path={routes.tenantRequestDetails}
          exact
          render={() => (
            <RequestSummary
              cloudcontrolService={cloudcontrolService}
              requestType={RequestType.TenantCreation}
            />
          )}
        />
        <Route
          path={routes.tenantRequestList}
          exact
          render={() => <TenantRequestPage cloudcontrolService={cloudcontrolService} />}
        />
        <Route path={`${routes.reporting}`} render={() => <ReportingPage />} />
        <Route
          path={routes.editTenantRequest}
          exact
          render={() => <TenantRequestEditForm cloudcontrolService={cloudcontrolService} />}
        />
        <Route
          path={routes.newTenantRequest}
          render={() => (
            <CreateFormPage
              cloudcontrolService={cloudcontrolService}
              requestType={RequestType.TenantCreation}
            />
          )}
        />
        <Route
          path={routes.editPortalRequest}
          exact
          render={() => <PortalRequestEditForm cloudcontrolService={cloudcontrolService} />}
        />
        <Route
          path={routes.newPortalRequest}
          render={() => (
            <CreateFormPage
              cloudcontrolService={cloudcontrolService}
              requestType={RequestType.PortalCreation}
            />
          )}
        />
        <Route
          path={routes.portalRequestDetails}
          render={() => (
            <RequestSummary
              cloudcontrolService={cloudcontrolService}
              requestType={RequestType.PortalCreation}
            />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </MuiContainer>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 112px)",
    marginTop: "3.438rem",
    paddingLeft: "3.438rem",
  },
}));
