import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { UserManager } from "oidc-client";
import { useHistory } from "react-router-dom";
import { useAppConfig } from "../../hooks/useAppConfig";
import { routes } from "../../routes";
import { AuthConfiguration } from "./AuthConfiguration";

interface ILoginCallbackProps {
  userManager: UserManager;
}

export const LoginCallback: React.FC<ILoginCallbackProps> = ({
  userManager,
}: ILoginCallbackProps) => {
  const history = useHistory();
  const { instance } = useMsal();
  const config = useAppConfig();
  const authConfig = new AuthConfiguration(
    config?.azureActiveDirectoryClientId,
    config?.azureActiveDirectoryTenatnId,
    config?.scopes,
    config?.redirectUri
  );
  // to address in tech item
  useEffect(() => {
    instance.loginRedirect(authConfig.getLoginRequest);
    userManager
      .signinCallback()
      .then(() => {
        history.push(routes.home);
      })
      .catch(() => {
        history.push(routes.login);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authConfig]);

  return null;
};
