export class ApproverViewModel {
  Id?: string;
  Name: string;
  Departments: string[];

  constructor() {
    this.Departments = [];
    this.Name = "";
  }
}
