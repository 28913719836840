import { Theme } from "@material-ui/core/styles";
import { createTheme } from "../utilities/createTheme";
import { defaultTheme, IThemeConfig } from "./defaultTheme";

interface IThemeManager {
  addTheme: (themeName: string, themeObject: IThemeConfig) => void;
  getThemeByName: (themeName: string) => Theme;
  getDefaultTheme: () => Theme;
}

class ThemeManager implements IThemeManager {
  private readonly _defaultTheme: string;

  private _themeMap: {
    [key: string]: Theme;
  };

  constructor() {
    this._defaultTheme = "defaultTheme";
    this._themeMap = {
      [this._defaultTheme]: createTheme(defaultTheme),
    };
  }

  addTheme = (themeName: string, themeObject: IThemeConfig): void => {
    this._themeMap[themeName] = createTheme(themeObject);
  };

  getThemeByName = (themeName: string) => this._themeMap[themeName] ?? this.getDefaultTheme();

  getDefaultTheme = () => this._themeMap[this._defaultTheme];
}

const instance: IThemeManager = new ThemeManager();
export { instance as ThemeManager };
