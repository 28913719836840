import React, { useEffect, useState } from "react";
import { FenSpinner } from "@fenx/styleguide";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../constants/paramTypes";
import { TenantOwnerStatus } from "../../constants/TenantOwnerStatus";
import { TenantOwnerType } from "../../constants/TenantOwnerType";
import { TenantType } from "../../constants/TenantType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { ICloudControlService } from "../../services";
import { ApproverViewModel } from "../../viewModels/approver/ApproverViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestViewModel } from "../../viewModels/tenantRequest/TenantRequestViewModel";
import { PortalRequestFormPage } from "./PortalRequestFormPage";

export interface IPortalRequestEditForm {
  cloudcontrolService: ICloudControlService;
}

export const PortalRequestEditForm = ({
  cloudcontrolService,
}: IPortalRequestEditForm): JSX.Element => {
  const [tenantOwners, setTenantOwners] = useState<TenantOwnerViewModel[]>([]);
  const [approver, setApprover] = useState<ApproverViewModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [portalRequest, setPortalRequest] = useState<TenantRequestViewModel>();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    const fetchPortalRequestById = async () => {
      try {
        const portalRequestData = await cloudcontrolService.getPortalRequestById(id);
        portalRequestData.existingClient =
          portalRequestData.tenantOwnerStatus === TenantOwnerStatus.Active
            ? YesNoQuestion.Yes
            : YesNoQuestion.No;
        setPortalRequest(portalRequestData);
        const targetTenantOwnerType: string[] =
          portalRequestData.tenantType === TenantType.Client
            ? [TenantOwnerType.Client, TenantOwnerType.Partner]
            : [TenantOwnerType.Team];
        const clientResponse = await cloudcontrolService.getClients();
        const approverResponse = await cloudcontrolService.getApprovers();
        setTenantOwners(
          clientResponse.filter((c) => c.type && targetTenantOwnerType.includes(c.type))
        );
        setApprover(approverResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPortalRequestById();
  }, [cloudcontrolService, id]);

  if (isLoading) {
    return <FenSpinner />;
  }
  return (
    <PortalRequestFormPage
      cloudcontrolService={cloudcontrolService}
      id={id}
      tenantOwners={tenantOwners}
      approver={approver}
      portalRequest={portalRequest ?? new TenantRequestViewModel()}
    />
  );
};
