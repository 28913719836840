import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeManager } from "./ThemeManager";

export interface IThemeProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const ThemeProvider: React.FC<IThemeProviderProps> = ({ children }: IThemeProviderProps) => {
  const activeTheme = ThemeManager.getDefaultTheme();
  return <MuiThemeProvider theme={activeTheme}>{children}</MuiThemeProvider>;
};
