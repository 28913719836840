import React, { memo } from "react";
import { FenDropdown } from "@fenx/styleguide";
import { getDisplayValue } from "../utils/lookupHelper";
import { FenReadonlyInput } from "./FenReadonlyInput";

export interface FenccDropdownProps {
  selectedValue: string[] | string;
  /** Label to be shown alongside component. */
  label: string;

  /** Default value for no object array options. */
  defaultValue?: boolean | string | string[];
  /** Display required indicator. */
  required?: boolean;
  /** Is component disabled . */
  disabled?: boolean;
  /** Handler that is applied to the root DOM element and fired upon blur changes */
  onBlur?: (updateEvent: React.FocusEvent<HTMLDivElement>) => void;
  /** Handler that is applied to the root DOM element and fired upon selecting element */
  onChange: (value: any, name: string) => void;
  /** Used to determine the string value for a given option. */
  getOptionLabel?: (option: any) => string;

  /** Used to determine the string value for a given option. */
  getDisabledValue?: (option: any) => string;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  /** Tooltip title. Zero-length titles string are never displayed. */
  tooltip?: string;
  /** Name attribute of the `input` element. */
  name: string;
  /** If `true`, `value` must be an array and the menu will support multiple selections. */
  multiple?: boolean;
  /**  Error message displayed under the field.*/
  error?: string;
  /** If true, the input will take all horizontally available space. */
  fullWidth?: boolean;
  /** List of dropdown values. */
  options: any[];
  /** If the options is array of object, the value key defines the accessor. */
  valueKey?: string;
  /** If the options is array of object, the display key defines the property of the object to display. */
  displayKey?: string;
  /** Variant to use. */
  variant?: "standard" | "outlined" | "filled";
  /** No Options text. */
  noOptionsText?: string;
  /** Assign a custom class for the Autocomplete input */
  className?: string;
  /** If true, any text can be provided. */
  allowFreeText?: boolean;
  /** Remove clear button on Autocomplete and show only text */
  disableClearable?: boolean;
  errors: any;
}

const FenccDropdownBase = ({
  selectedValue,
  className,
  fullWidth,
  label,
  name,
  required,
  errors,
  multiple,
  disabled,
  valueKey,
  displayKey,
  options,
  onChange,
  onBlur,
  getOptionLabel,
  "data-testid": testid,
}: FenccDropdownProps) => {
  const FenReadOnlyInput = (
    <FenReadonlyInput
      label={label}
      className={className}
      data-testid={testid}
      value={getDisplayValue(options, selectedValue, valueKey, displayKey, multiple)}
    />
  );

  const FenDropDown = (
    <FenDropdown
      className={className}
      disabled={disabled}
      options={options}
      label={label}
      name={name}
      required={required}
      multiple={multiple}
      data-testid={testid}
      valueKey={valueKey}
      getOptionLabel={getOptionLabel}
      fullWidth={fullWidth}
      onBlur={onBlur}
      value={selectedValue}
      error={errors}
      onChange={onChange}
    />
  );
  return disabled ? FenReadOnlyInput : FenDropDown;
};

export const FenccDropdown = memo(FenccDropdownBase);
