import jwtDecode from "jwt-decode";

export const isUserInRoles = (idToken: string | null, roles: string[]): boolean => {
  if (roles.length === 0) {
    return true;
  }

  if (!idToken) {
    return false;
  }

  const userRoles = getRolesFromIdToken(idToken, "roles");

  if (!userRoles) {
    return false;
  }

  return roles.every((r) => userRoles.includes(r));
};

const getRolesFromIdToken = (idToken: string, property: string): [string] => {
  const idTokenPayload: any = jwtDecode(idToken);
  return idTokenPayload[property];
};
