import React from "react";
import { commonColors, FenButton } from "@fenx/styleguide";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";

export interface ReviewNavigationButtonsProps {
  callback: any;
  submitForm: any;
}

export const ReviewNavigationButtons = ({
  callback,
  submitForm,
}: ReviewNavigationButtonsProps): JSX.Element => {
  const classes = useStyles();
  const handlePreviousClick = () => {
    callback();
  };

  return (
    <Box className={classes.boxContainer}>
      <FenButton
        onClick={handlePreviousClick}
        className={clsx(classes.previousButton, classes.button)}
        data-testid="back-button"
        text="Previous"
        secondary
      />
      <FenButton
        className={clsx(classes.submitButton, classes.button)}
        data-testid="submit-button"
        text="Submit"
        onClick={submitForm}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: "5rem",
  },
  previousButton: {
    backgroundColor: commonColors.white,
    color: commonColors.bluesPrimaryBlue,
    "&:hover,&:focus": {
      backgroundColor: commonColors.white,
    },
    borderColor: commonColors.bluesPrimaryBlue,
  },
  submitButton: {
    backgroundColor: commonColors.bluesPrimaryBlue,
    color: commonColors.white,
    "&:hover,&:focus": {
      backgroundColor: commonColors.bluesPrimaryBlue,
    },
  },
  button: {
    width: "15rem",
    minWidth: "10.5rem",
    maxWidth: "15rem",
    marginTop: "5rem",
    fontSize: "2rem",
    fontWeight: 700,
  },
}));
