import { TenantRequestStatus } from "../constants/TenantRequestStatus";

export const GetAvailableStatusOptions = (tenantRequestStatus?: string): TenantRequestStatus[] => {
  if (
    tenantRequestStatus === TenantRequestStatus.New ||
    tenantRequestStatus === TenantRequestStatus.Approved
  ) {
    return [
      TenantRequestStatus.InProgress,
      TenantRequestStatus.Completed,
      TenantRequestStatus.Rejected,
      TenantRequestStatus.Archived,
    ];
  }
  if (tenantRequestStatus === TenantRequestStatus.InProgress) {
    return [
      TenantRequestStatus.Completed,
      TenantRequestStatus.Rejected,
      TenantRequestStatus.Archived,
    ];
  }
  if (tenantRequestStatus === TenantRequestStatus.Rejected) {
    return [TenantRequestStatus.InProgress, TenantRequestStatus.Archived];
  }

  if (tenantRequestStatus === TenantRequestStatus.Approval) {
    return [TenantRequestStatus.Approved, TenantRequestStatus.RejectedByApprover];
  }

  if (tenantRequestStatus === TenantRequestStatus.RejectedByApprover) {
    return [TenantRequestStatus.Archived];
  }

  return [];
};
