export enum LookupType {
  YesNoQuestion = "YesNoQuestion",
  BusinessLine = "BusinessLine",
  CustomerSize = "CustomerSize",
  Urgency = "Urgency",
  Department = "Department",
  AwsRegion = "AwsRegion",
  InitialImportConfig = "InitialImportConfig",
  Purpose = "Purpose",
}
