import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { commonColors } from "../assets/themes/defaultTheme";
import { TenantOwnerType } from "../constants/TenantOwnerType";
import { tenantOwnerProfileUrl } from "../utils/routeUtil";
import { StyledFenLink } from "./StyledFenLink";

export interface TenantOwnerCellProps {
  tenantOwnerId?: string;
  tenantOwnerType?: string;
  tenantOwnerName?: string;
}

export const TenantOwnerCell = ({
  tenantOwnerId,
  tenantOwnerType,
  tenantOwnerName,
}: TenantOwnerCellProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    chip: {
      color: commonColors.white,
      backgroundColor: commonColors.chipOrangeBackground,
      marginLeft: 12,
      padding: 3,
      height: 20,
      fontWeight: 600,
      textAlign: "center",
      fontSize: "0.7rem",
    },
  }));
  const classes = useStyles();

  return (
    <>
      {tenantOwnerId && tenantOwnerName ? (
        <StyledFenLink to={tenantOwnerProfileUrl(tenantOwnerId)} text={`${tenantOwnerName}`} />
      ) : (
        "N/A"
      )}
      {tenantOwnerType === TenantOwnerType.Client && (
        <Chip label={`${tenantOwnerType}`} className={classes.chip} size="small" />
      )}
    </>
  );
};
