import {
  ClientPortalRequestDto,
  ClientUpdatePortalRequestDto,
  InternalTenantRequestDto,
  InternalUpdatePortalRequestDto,
  PortalRequestStatusDto,
} from "fenergo-nebula-cloudcontrol-command-api";
import { PortalRequestDto } from "fenergo-nebula-cloudcontrol-query-api";
import {
  getTenantRequestStatusDisplayValue,
  getTenantRequestStatusStoredValue,
} from "../constants/TenantRequestStatus";
import { addDays } from "../utils/DateHelper";
import { TenantRequestStatusViewModel } from "../viewModels/tenantRequest/columns/TenantRequestStatusViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";

export class PortalRequestMapping {
  private static readonly mapViewModelToBaseRequestDto = (model: TenantRequestViewModel) => ({
    id: model.tenantRequestId ?? undefined,
    requestType: model.requestType ?? undefined,
    tenantOwnerId: model.tenantOwnerId ?? undefined,
    environmentCollection: model.tenantEnvironment ?? [],
    tenantName: model.clientTeamName ?? undefined,
    approverId: model.approverId ?? undefined,
    department: model.departmentCostTaggedAgainst ?? undefined,
    dueDate: addDays(new Date(), 10),
    additionalComments: model.additionalComments ?? undefined,
    actionByUserId: model.actionedBy ?? undefined,
  });

  public static mapViewModelToInternalPortalRequestDto = (
    model: TenantRequestViewModel
  ): InternalTenantRequestDto => this.mapViewModelToBaseRequestDto(model);

  public static mapViewModelToClientPortalRequestDto = (
    model: TenantRequestViewModel
  ): ClientPortalRequestDto => {
    const baseModel: ClientPortalRequestDto = this.mapViewModelToBaseRequestDto(model);
    baseModel.hasContractBeenSigned = model.contractSigned ?? undefined;
    baseModel.hasContractBeenSentToCBO = model.contractSentToCbo ?? "No";
    baseModel.costIncluded = model.costIncluded ?? undefined;
    return baseModel;
  };

  public static mapDtoToViewModel = (dto?: PortalRequestDto): TenantRequestViewModel => {
    if (!dto) {
      return new TenantRequestViewModel();
    }
    const viewModel = new TenantRequestViewModel();
    viewModel.requestType = dto.requestType;
    viewModel.clientTeamName = dto.tenantOwnerName ?? "";
    viewModel.tenantType = dto.tenantType ?? "";
    viewModel.tenantRequestId = dto.id;
    viewModel.status = getTenantRequestStatusDisplayValue(dto.status);
    viewModel.requestor = dto.requestorName;
    viewModel.requestorId = dto.requestorId;
    viewModel.dueDate = dto.dueDate;
    viewModel.tenantOwnerId = dto.tenantOwnerId;
    viewModel.contractSentToCbo = dto.hasContractBeenSentToCBO;
    viewModel.tenantEnvironment = dto.environmentCollection;
    viewModel.approverId = dto.approverId;
    viewModel.departmentCostTaggedAgainst = dto.department;
    viewModel.additionalComments = dto.additionalComments ?? "";
    viewModel.requestorEmail = dto.requestorEmail;
    viewModel.costIncluded = dto.costIncluded;
    viewModel.actionedBy = dto.actionByUserName;
    viewModel.approverOfTheDepartment = dto.approverName;
    viewModel.contractSigned = dto.hasContractBeenSigned;
    viewModel.approverUserId = dto.approverUserId;
    return viewModel;
  };

  public static mapViewModelToInternalUpdatePortalRequestDto = (
    model: TenantRequestViewModel
  ): InternalUpdatePortalRequestDto => {
    const baseModel: InternalUpdatePortalRequestDto = this.mapViewModelToBaseRequestDto(model);
    return baseModel;
  };

  public static mapViewModelToClientUpdatePortalRequestDto = (
    model: TenantRequestViewModel
  ): ClientUpdatePortalRequestDto => {
    const baseModel: ClientUpdatePortalRequestDto = this.mapViewModelToBaseRequestDto(model);
    baseModel.costIncluded = model.costIncluded ?? "No";
    baseModel.hasContractBeenSigned = model.contractSigned ?? undefined;
    baseModel.hasContractBeenSentToCBO = model.contractSentToCbo ?? "No";
    return baseModel;
  };

  public static mapViewModelToPortalRequestStatusDto = (
    model: TenantRequestStatusViewModel
  ): PortalRequestStatusDto => ({
    id: model.tenantRequestId ?? undefined,
    status: model.status ? getTenantRequestStatusStoredValue(model.status) : undefined,
  });
}
