import React, { memo } from "react";
import { FilledInputProps, InputProps, OutlinedInputProps, TextField } from "@material-ui/core";

/**
 * FenReadonlyInput properties.
 */

type TextInputType = HTMLInputElement | HTMLTextAreaElement;
type Margin = "none" | "dense" | "normal";

export interface FenInputProps {
  /** Selected value. */
  value: any;
  /** Display required indicator. */
  required?: boolean;
  /** Name attribute of the `input` element. */
  name?: string;
  /** If true, the input will take all horizontally available space. */
  fullWidth?: boolean;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  /** Number of rows to display when multiline option is set to true.*/
  rows?: string | number;
  /** Maximum number of rows to display when multiline option is set to true.*/
  rowsMax?: string | number;
  /** Attribute to create scroll if multiple lines present*/
  multiline?: boolean;
  className?: string;
  disabled?: boolean;
  /** Handler that is applied to the root DOM element and fired upon key press */
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
  /** Handler that is applied to the root DOM element and fired upon changes in element */
  onChange?: (updateEvent: React.ChangeEvent<TextInputType>) => void;
  /** Handler that is applied to the root DOM element and fired upon blur changes */
  onBlur?: (updateEvent: React.FocusEvent<TextInputType>) => void;
  /** Handler that is applied to the root DOM element and fired upon focus changes */
  onFocus?: (updateEvent: React.FocusEvent<TextInputType>) => void;
  margin?: Margin;
  variant?: "standard" | "outlined" | "filled";
  inputPros?: FilledInputProps | OutlinedInputProps | InputProps;
}

const FenInputBase = ({
  value,
  name,
  required,
  fullWidth,
  "data-testid": testid,
  rows,
  rowsMax,
  className,
  multiline,
  disabled,
  onKeyPress,
  onChange,
  onBlur,
  onFocus,
  margin,
  variant,
  inputPros,
}: FenInputProps): React.ReactElement => {
  return (
    <TextField
      className={className}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      value={value}
      name={name}
      disabled={disabled}
      InputProps={inputPros}
      data-testid={testid}
      rows={rows}
      rowsMax={rowsMax}
      multiline={multiline}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      margin={margin}
    />
  );
};

export const FenInput = memo(FenInputBase);
