import { routes } from "../routes";

export const tenantOwnerProfileUrl = (id: string): string => `${routes.tenantOwner}${id}`;

export const tenantRequestDetailsUrl = (id: string): string => `${routes.tenantRequestList}/${id}`;

export const portalRequestDetailsUrl = (id: string): string => `${routes.portalRequestList}/${id}`;

export const tenantRequestEditUrl = (id: string): string =>
  `${routes.tenantRequestList}/${id}/edit`;

export const portalRequestEditUrl = (id: string): string =>
  `${routes.portalRequestList}/${id}/edit`;
