import { HostPortReaderConfig } from "fenergo-nebula-cloudcontrol-query-api";
import { HostPortReaderConfigViewlModel } from "../viewModels/tenant/tenantConfig/HostPortReaderConfigViewlModel";

export const mapToHostPortReaderConfigViewModel = (
  dto?: HostPortReaderConfig
): HostPortReaderConfigViewlModel => {
  const viewModel = new HostPortReaderConfigViewlModel();
  if (dto === undefined) {
    return viewModel;
  }
  viewModel.host = dto?.host;
  viewModel.port = dto?.port;
  viewModel.reader = dto?.reader;
  return viewModel;
};

export const mapToHostPortReaderConfigDto = (
  viewModel?: HostPortReaderConfigViewlModel
): HostPortReaderConfig => ({
  host: viewModel?.host,
  port: viewModel?.port,
  reader: viewModel?.reader,
});
