import React from "react";

export type ItemColumns = 1 | 2 | 3;

type ChildrenType = React.ReactNode | boolean | null;

export interface FenccFieldsGridProps {
  children: ChildrenType[] | ChildrenType;
  columns: ItemColumns;
  className: string;
  /** Attribute that test scripts will be able to find this component  */
  "data-testid"?: string;
}

export const FenccFieldsGrid = ({
  className,
  children,
  "data-testid": testid,
}: FenccFieldsGridProps): React.ReactElement => {
  const items = Array.isArray(children) ? children : [children];
  return (
    <div data-testid={testid} className={className}>
      {items
        .filter((x) => x !== false && x !== null)
        .map((x, i) => (
          <React.Fragment key={i}>{x}</React.Fragment>
        ))}
    </div>
  );
};
