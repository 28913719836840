import React, { useEffect, useState } from "react";
import { EnhancedDataTable } from "@fenx/styleguide";
import { ICloudControlService } from "../../services/ICloudControlService";
import { sortByTenantStatusThenByTenantName } from "../../utils/sort";
import { MapTenantOwnerToTenantList } from "../../utils/TenantHelper";
import { getTenantListTableColumns } from "../../viewModels/tenant/columns/TenantColumns";
import { TenantEnvironmentViewModel } from "../../viewModels/tenant/TenantEnvironmentViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";

export interface TenantListProps {
  cloudcontorlService: ICloudControlService;
}

export const TenantList = ({ cloudcontorlService }: TenantListProps): JSX.Element => {
  const [tenantList, setTenantList] = useState<TenantEnvironmentViewModel[]>([{}]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const GetTenantWithTenantOwnerList = async (): Promise<TenantEnvironmentViewModel[]> => {
      const tenantOwnerList: TenantOwnerViewModel[] = await cloudcontorlService.getTenantOwners();
      const tenantSummaryList: TenantEnvironmentViewModel[] =
        await cloudcontorlService.getTenantsSummary();
      const tenantListWithTenantOwner = MapTenantOwnerToTenantList(
        tenantSummaryList,
        tenantOwnerList
      );
      return sortByTenantStatusThenByTenantName(tenantListWithTenantOwner);
    };

    const fetchData = async () => {
      try {
        const tenantWithTenantOwnerList = await GetTenantWithTenantOwnerList();
        setIsLoading(false);
        setTenantList(tenantWithTenantOwnerList);
      } catch (error) {
        console.error("Error fetching Tenant Summary List", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [cloudcontorlService]);

  return (
    <div id="tenant-list-table">
      <EnhancedDataTable<TenantEnvironmentViewModel>
        columns={getTenantListTableColumns(isLoading)}
        data={tenantList}
        tableTitle="Tenant Management"
        enableGlobalFilter={!isLoading}
        unlimitedHeight
        defaultRowsPerPage={10}
      />
    </div>
  );
};
