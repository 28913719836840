import createMuiTheme, { Theme } from "@material-ui/core/styles/createMuiTheme";
import {
  SimplePaletteColorOptions,
  TypeBackground,
  TypeText,
} from "@material-ui/core/styles/createPalette";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import { commonColors, IThemeConfig } from "../themes/defaultTheme";
import { fontFaceDefinitions } from "./getFonts";
import { appTypography } from "./getTypography";

export const createTheme = (themeObject: IThemeConfig): Theme => {
  const { palette } = themeObject;
  const bgColor = (palette.background as Partial<TypeBackground>).default;

  return responsiveFontSizes(
    createMuiTheme({
      palette,
      typography: appTypography,
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": fontFaceDefinitions,
            body: {
              color: (palette.text as Partial<TypeText>).primary,
              backgroundColor: bgColor,
            },
          },
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: bgColor,
            color: (palette.primary as SimplePaletteColorOptions).main,
            boxShadow: "none",
          },
        },
        MuiCard: {
          root: {
            borderRadius: 4,
            boxShadow: "1px 1px 5px 0 rgba(74, 141, 211, 0.4)",
          },
        },
        MuiCardActionArea: {
          root: {
            width: "inherit",
          },
        },
        MuiIconButton: {
          root: {
            "&:hover": {
              backgroundColor: "none",
            },
            "&:focus, &:active": {
              backgroundColor: "none",
            },
          },
        },
        MuiButton: {
          root: {
            "&:focus, &:active": {
              backgroundColor: "rgba(56, 79, 103, 0.1)",
            },
          },
        },
        MuiDivider: {
          root: {
            "&$light": {
              opacity: 0.16,
              backgroundColor: "#e0e6ea",
            },
          },
        },
        MuiDrawer: {
          root: {
            "& $paper": {
              backgroundColor: commonColors.bluesLightBlue2,
            },
          },
        },
        MuiList: {
          root: {
            color: "#384f67",
          },
        },
        MuiListItem: {
          root: {
            "&$selected": {
              borderRight: "4px solid #55a6f9",
              backgroundColor: "rgba(56, 79, 103, 0.1)",
              "& span": {
                fontWeight: 700,
              },
            },
          },
        },
        MuiListItemText: {
          root: {
            "& $primary": {
              fontSize: "1.5rem",
              lineHeight: 1.71,
            },
          },
        },
        MuiOutlinedInput: {
          root: {
            backgroundColor: commonColors.bluesLightBlue2,
            "&$error:after": {
              backgroundColor: commonColors.inputSelectionStatesError,
            },
          },
        },
        MuiFormHelperText: {
          contained: {
            marginLeft: "0",
          },
        },
        MuiInputBase: {
          root: {
            "&$error": {
              borderWidth: 2,
              backgroundColor: commonColors.inputSelectionStatesError,
              borderColor: commonColors.error,
            },
          },
        },
      },
    })
  );
};
