import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { FenSpinner, FenTitle } from "@fenx/styleguide";
import { Box } from "@material-ui/core";
import { Column } from "react-table";
import { getOpenStatusList } from "../../constants/TenantRequestStatus";
import { CloudControlRoles } from "../../core/auth/CloudControlRoles";
import { TabProps, TabHeaderProps } from "../../core/models/FenccTabPanelGridProps";
import { ICloudControlService } from "../../services";
import { sortByDueDate } from "../../utils/sort";
import {
  tenantRequestIdColumn,
  clientTeamNameColumn,
  requestTypeColumn,
  statusColumn,
  requestorColumn,
  actionedByColumn,
  dueDateColumn,
} from "../../viewModels/tenantRequest/columns/TenantRequestColumns";
import { TenantRequestViewModel } from "../../viewModels/tenantRequest/TenantRequestViewModel";
import { UserInfoViewModel } from "../../viewModels/userInfo/UserInfoViewModel";
import { FenccTabPanelGrid } from "../FenccTabPanelGrid";
import { TenantRequestModal } from "./TenantRequestModal";

const tenantSummaryColumns = (isLoading: boolean): Column<TenantRequestViewModel>[] => [
  tenantRequestIdColumn(isLoading),
  clientTeamNameColumn,
  requestTypeColumn,
  statusColumn,
  requestorColumn,
  actionedByColumn,
  dueDateColumn,
];

export interface TenantRequestPageProps {
  cloudcontrolService: ICloudControlService;
}

export const TenantRequestPage = ({ cloudcontrolService }: TenantRequestPageProps): JSX.Element => {
  const { accounts } = useMsal();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [requestData, setRequestData] = useState<TenantRequestViewModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<UserInfoViewModel>({});
  useEffect(() => {
    const getStaticLookup = async () => {
      try {
        const tenantRequesResponse = await cloudcontrolService.getTenantRequestAndPortalRequest();
        setRequestData(tenantRequesResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchUserInfo = async () => {
      try {
        const userInformation = await cloudcontrolService.getUserInfo(accounts[0].localAccountId);
        setUserInfo(userInformation);
      } catch (error) {
        console.error(error);
      }
    };
    getStaticLookup();
    fetchUserInfo();
  }, [accounts, cloudcontrolService]);

  const handleTabChange = (_event: any, newValue: any) => {
    setCurrentTab(newValue);
  };

  const handleOnCloseModal = () => {
    setModalOpen(!modalOpen);
  };

  const tabHeader: TabHeaderProps = { tabsOnChange: handleTabChange, tabsValue: currentTab };

  const tabsProps: TabProps[] = [
    {
      tabLabel: "My Requests",
      tabsOnChange: handleTabChange,
      tabPanelIndex: 0,
      tabPanelValue: currentTab,
      columns: tenantSummaryColumns(isLoading),
      data: sortByDueDate(
        requestData.filter(
          (x: TenantRequestViewModel) =>
            x.requestorId === accounts[0].localAccountId ||
            x.approverUserId === accounts[0].localAccountId
        )
      ),
      tableTitle: "",
      addItemFunction: handleOnCloseModal,
      accessPermission: [],
    },
    {
      tabLabel: "Open Requests",
      tabsOnChange: handleTabChange,
      tabPanelIndex: 1,
      tabPanelValue: currentTab,
      columns: tenantSummaryColumns(isLoading),
      data: sortByDueDate(
        requestData.filter(
          (x: TenantRequestViewModel) => x.status && getOpenStatusList().includes(x.status)
        )
      ),
      tableTitle: "",
      addItemFunction: handleOnCloseModal,
      accessPermission: [CloudControlRoles.Admin],
    },
    {
      tabLabel: "All Requests",
      tabsOnChange: handleTabChange,
      tabPanelIndex: 2,
      tabPanelValue: currentTab,
      columns: tenantSummaryColumns(isLoading),
      data: sortByDueDate(requestData),
      tableTitle: "",
      addItemFunction: handleOnCloseModal,
      accessPermission: [CloudControlRoles.Admin],
    },
  ];

  if (isLoading) {
    return <FenSpinner />;
  }
  return (
    <>
      <TenantRequestModal open={modalOpen} callback={handleOnCloseModal} userInfo={userInfo} />
      <Box padding={3}>
        <FenTitle title="Requests" />
        <FenccTabPanelGrid tabHeaderProps={tabHeader} tabsProps={tabsProps} />
      </Box>
    </>
  );
};
