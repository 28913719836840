import React, { FC } from "react";
import { ListItem, Typography, createStyles, makeStyles, Theme } from "@material-ui/core";
import { commonColors } from "../assets/themes/defaultTheme";

export interface FenListHeaderProps {
  title: string;
  required?: boolean;
  error?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterSelectInput: {
      minWidth: 120,
      height: "2rem",
    },
    headerListItem: {
      padding: 0,
      backgroundColor: theme.palette.common.greysWhite,
    },
    listTypography: {
      color: commonColors.fenxLabelBlue,
    },
    asterisk: {
      color: "#d91919",
    },
    error: {
      color: commonColors.error,
      marginLeft: "2rem",
    },
  })
);

export const FenListHeader: FC<FenListHeaderProps> = ({ title, required, error }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.headerListItem}>
      <Typography
        variant="caption"
        className={classes.listTypography}
        data-testid={"fen-list-header"}
      >
        {title}
        {required && (
          <span className={classes.asterisk} aria-hidden="true">
            *
          </span>
        )}
        {error && <span className={classes.error}>{error}</span>}
      </Typography>
    </ListItem>
  );
};
