import { TenantOwnerViewModel } from "../viewModels/tenantOwner/TenantOwnerViewModel";
import { SelectListItemViewModel } from "../viewModels/tenantRequestForm/SelectListItemViewModel";

export const getSelectItemSafeName = (item: SelectListItemViewModel): string => {
  return item.Text ?? "";
};

export const getTenantOwnerSafeName = (item: TenantOwnerViewModel): string => {
  return item.name ?? "";
};
