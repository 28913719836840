import { DynamoDbConfigViewlModel } from "./tenantConfig/DynamoDbConfigViewlModel";
import { HostPortConfigViewlModel } from "./tenantConfig/HostPortConfigViewlModel";
import { HostPortReaderConfigViewlModel } from "./tenantConfig/HostPortReaderConfigViewlModel";
import { S3ConfigViewlModel } from "./tenantConfig/S3ConfigViewlModel";
import { TenantFenxServicesViewModel } from "./tenantConfig/TenantFenxServicesViewModel";
import { TenantSummaryViewModel } from "./TenantSummaryViewModel";

export class TenantEnvironmentViewModel implements TenantSummaryViewModel {
  environmentId?: string;
  tenantId?: string;
  tenantName?: string;
  environment?: string;
  awsRegion?: string;
  activeServiceIds?: string[];
  createdOn?: Date;
  lastEditedOn?: Date;
  lastActivity?: Date;
  lastActivityString?: string;
  rootUserId?: string;
  dynamoDbConfig?: DynamoDbConfigViewlModel;
  eventStoreConfig?: HostPortConfigViewlModel;
  neptuneDbConfig?: HostPortReaderConfigViewlModel;
  elasticSearchConfig?: HostPortConfigViewlModel;
  s3Config?: S3ConfigViewlModel;
  kmsKeyArn?: string;
  iamRoleArn?: string;
  encryptionSecretArn?: string;
  encryptionSecretName?: string;
  purpose?: string;
  tenantOwnerId?: string;
  tenantOwnerName?: string;
  tenantOwnerType?: string;
  status?: string;
  tenantFenxServices?: TenantFenxServicesViewModel[];

  constructor() {
    this.dynamoDbConfig = new DynamoDbConfigViewlModel();
    this.eventStoreConfig = new HostPortConfigViewlModel();
    this.elasticSearchConfig = new HostPortConfigViewlModel();
    this.neptuneDbConfig = new HostPortReaderConfigViewlModel();
    this.s3Config = new S3ConfigViewlModel();
  }
}
