import React from "react";
import { FenReadonlyInput } from "@fenx/styleguide";
import { Box, makeStyles } from "@material-ui/core";
import { commonColors } from "../../assets/themes/defaultTheme";
import { LookupType } from "../../constants/LookupType";
import { TenantType } from "../../constants/TenantType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { EnvironmentNameParser } from "../../utils/EnvironmentNameParser";
import { convertToReviewPageFormat } from "../../utils/lookupHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { RequestFormLabels } from "../tenantRequestForm/RequestFormLabels";

export interface PortalRequestDetailsProps {
  formik: any;
  staticLookup: LookupViewModel;
}

export const PortalRequestDetails = ({
  formik,
  staticLookup,
}: PortalRequestDetailsProps): JSX.Element => {
  const classes = useStyles();
  const { values } = formik;

  return (
    <Box className={classes.boxContainer}>
      <FenReadonlyInput
        label={
          values.tenantType === TenantType.Internal
            ? RequestFormLabels.TeamName
            : RequestFormLabels.ClientName
        }
        value={values.clientTeamName}
        required
        fullWidth
        multiline
      />
      <FenReadonlyInput
        fullWidth
        multiline
        label={RequestFormLabels.TenantEnvironment}
        value={EnvironmentNameParser(
          convertToReviewPageFormat(staticLookup, LookupType.Purpose, values.tenantEnvironment),
          values.clientTeamName
        )}
        required
      />
      {values.tenantType === TenantType.Internal && (
        <>
          <FenReadonlyInput
            fullWidth
            multiline
            label={RequestFormLabels.Department}
            value={convertToReviewPageFormat(
              staticLookup,
              LookupType.Department,
              values.departmentCostTaggedAgainst
            )}
            required
          />
          <FenReadonlyInput
            label={RequestFormLabels.Approver}
            value={values.approverOfTheDepartment}
            required
            fullWidth
            multiline
          />
        </>
      )}

      {values.tenantType === TenantType.Client && (
        <>
          <FenReadonlyInput
            fullWidth
            multiline
            label={RequestFormLabels.PortalCostIncluded}
            value={values.costIncluded}
            required
          />
          {values.costIncluded === YesNoQuestion.No && (
            <>
              <FenReadonlyInput
                fullWidth
                multiline
                label={RequestFormLabels.Department}
                value={convertToReviewPageFormat(
                  staticLookup,
                  LookupType.Department,
                  values.departmentCostTaggedAgainst
                )}
                required
              />
              <FenReadonlyInput
                label={RequestFormLabels.Approver}
                value={values.approverOfTheDepartment}
                required
                fullWidth
                multiline
              />
            </>
          )}

          {values.costIncluded === YesNoQuestion.Yes && (
            <FenReadonlyInput
              label={RequestFormLabels.ContractSent}
              value={values.contractSentToCbo}
              required
              fullWidth
              multiline
            />
          )}
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  boxContainer: {
    backgroundColor: commonColors.greyBlue,
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary,
    },
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "1rem",
  },
}));
