import React from "react";
import { Skeleton } from "@material-ui/lab";
import { CellProps, Column } from "react-table";
import { StatusChipCell } from "../../../components/StatusChipCell";
import { ToFormatteddString } from "../../../utils/DateHelper";
import { TenantRequestViewModel } from "../TenantRequestViewModel";
import { TenantRequestIdCell } from "./TenantRequestIdCell";

const getColumnCell = (
  isLoading: boolean,
  component: string | JSX.Element
): string | JSX.Element => {
  if (isLoading) {
    return <Skeleton />;
  }

  return component;
};

export const tenantRequestIdColumn = (isLoading: boolean): Column<TenantRequestViewModel> =>
  ({
    Header: "Request ID",
    accessor: "tenantRequestId",
    Cell: ({ row }: CellProps<TenantRequestViewModel>) =>
      getColumnCell(
        isLoading,
        <TenantRequestIdCell
          requestType={row.original.requestType ?? ""}
          tenantRequestId={row.original.tenantRequestId ?? ""}
          urgent={row.original.urgentRequest ?? ""}
        />
      ),
  } as Column<TenantRequestViewModel>);

export const clientTeamNameColumn: Column<TenantRequestViewModel> = {
  Header: "Client/Team",
  accessor: "clientTeamName",
  width: "15%",
  Cell: ({ row }: CellProps<TenantRequestViewModel>) => row.original.clientTeamName?.trim() || "-",
};

export const requestTypeColumn: Column<TenantRequestViewModel> = {
  Header: "Request Type",
  accessor: "requestType",
  Cell: ({ row }: CellProps<TenantRequestViewModel>) => row.original.requestType?.trim() || "-",
};

export const statusColumn: Column<TenantRequestViewModel> = {
  Header: "Status",
  accessor: "status",
  Cell: ({ row }: CellProps<TenantRequestViewModel>) => (
    <StatusChipCell cellValue={row.original.status?.trim() || "-"} />
  ),
} as Column<TenantRequestViewModel>;

export const requestorColumn: Column<TenantRequestViewModel> = {
  Header: "Requestor",
  accessor: "requestor",
  Cell: ({ row }: CellProps<TenantRequestViewModel>) => row.original.requestor?.trim() || "-",
};

export const actionedByColumn: Column<TenantRequestViewModel> = {
  Header: "Actioned By",
  accessor: "actionedBy",
  Cell: ({ row }: CellProps<TenantRequestViewModel>) => row.original.actionedBy?.trim() || "-",
};

export const dueDateColumn: Column<TenantRequestViewModel> = {
  Header: "Due Date",
  accessor: "dueDate",
  Cell: ({ row }: CellProps<TenantRequestViewModel>) =>
    ToFormatteddString(row.original.dueDate) || "-",
};
