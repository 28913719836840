import React from "react";
import { FenDropdown } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { LookupType } from "../../constants/LookupType";
import { defaultFormikDropdownFieldWithErrors } from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName } from "../../utils/stringHelper";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenInputWithHelpIcon } from "../FenInputWithHelpIcon";
import { RequestFormLabels } from "./RequestFormLabels";

export interface ITenantRequestFormAwsSection {
  staticLookup: LookupViewModel;
  formik: any;
}
export const TenantRequestFormAwsSection = ({
  staticLookup,
  formik,
}: ITenantRequestFormAwsSection): JSX.Element => {
  const classes = useStyles();
  const { values, errors, touched } = formik;

  const updateAwsRegionHandler = (value: any, propertyName: string) => {
    formik.setFieldValue("otherAwsRegion", "");
    formik.setFieldTouched("otherAwsRegion", false);
    formik.setFieldValue(propertyName, value);
  };

  return (
    <>
      <FenDropdown
        className={classes.infoField}
        fullWidth
        label={RequestFormLabels.AwsRegion}
        name="awsRegion"
        required
        data-testid="aws-region"
        options={getSpecificSelectListItemViewModel(staticLookup, LookupType.AwsRegion)}
        valueKey="Value"
        getOptionLabel={(x) => getSelectItemSafeName(x)}
        error={touched.awsRegion ? errors.awsRegion : ""}
        {...defaultFormikDropdownFieldWithErrors(formik, "awsRegion", updateAwsRegionHandler)}
      />
      {values.awsRegion === "Other" && (
        <FenInputWithHelpIcon
          tooltipDescription={RequestFormLabels.OtherRegionToolTip}
          tooltipCondition
          title={RequestFormLabels.OtherRegion}
          name={"otherAwsRegion"}
          className={clsx(classes.rowBox, classes.infoField)}
          data-testid="other-aws-region"
          text-field-test-id={"other-aws-region-text-test-id"}
          fullWidth
          required
          freeText
          error={touched.otherAwsRegion ? errors.otherAwsRegion : ""}
          {...defaultFormikDropdownFieldWithErrors(formik, "otherAwsRegion")}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  infoField: {
    marginBottom: "1rem",
  },
  rowBox: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));
