import React, { ChangeEvent, memo } from "react";
import { BaseTooltipProps } from "@fenx/styleguide";
import { Box, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { commonColors } from "../assets/themes/defaultTheme";
import { FenTitle } from "./FenTitle";
export interface FenInputWithHelpIconProps {
  /** Display required indicator. */
  required?: boolean;
  /** If true, the input will take all horizontally available space. */
  fullWidth?: boolean;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  /** Attribute to create scroll if multiple lines present*/
  multiline?: boolean;
  /** Attribute to create custom style */
  className?: string;
  /** Attribute to disabled the autocomplete */
  disabled?: boolean;
  /** Attribute for the text of tooltip desacription */
  tooltipDescription: string;
  /** Attribute for the label of this component   */
  title: string;
  /** Name property (passed to onSelect callbacks) */
  name: string;
  /** Placeholder text */
  placeholderText?: string;
  /** Value of the component */
  value: any;
  /** Handler that is applied to the root DOM element and fired upon blur changes */
  onBlur?: (updateEvent: React.FocusEvent<HTMLDivElement>) => void;
  /** On change event */
  onChange: (value: any, name: string) => void;
  onTextChange?: React.KeyboardEventHandler<HTMLDivElement>;
  /** Condition to display help icon */
  tooltipCondition?: boolean;
  /** Error message */
  error?: string;
  /** Test id for textField */
  "text-field-test-id"?: string;
  /** Condition to render textField or AutoDropdown */
  freeText?: boolean;
  /** List of dropdown values. */
  options?: any[];
  /** If the options is array of object, the value key defines the accessor. */
  valueKey?: string;
  /** Used to determine the string value for a given option. */
  getOptionLabel?: (option: any) => string;
}

const useStyles = makeStyles(() => ({
  box: {
    marginBottom: "1rem",
  },
  title: {
    marginBottom: "0.5rem",
  },
  label: { marginRight: "1rem", color: commonColors.fenxLabelBlue },
}));

const FenInputWithHelpIconBase = ({
  required,
  fullWidth,
  "data-testid": testid,
  "text-field-test-id": textFieldTestId,
  multiline,
  disabled,
  tooltipDescription,
  title,
  name,
  placeholderText,
  value,
  tooltipCondition,
  error,
  freeText,
  onChange,
  onBlur,
  options,
  valueKey,
  getOptionLabel,
}: FenInputWithHelpIconProps) => {
  const tooltipProps: BaseTooltipProps = {
    tooltip: tooltipDescription,
    condition: tooltipCondition,
  };
  const classes = useStyles();

  let fieldValue: any;

  if (valueKey) {
    const filteredValues = options?.filter((x) => x[valueKey] === value) ?? [];
    fieldValue = filteredValues.length > 0 ? filteredValues[0] : null;
  } else {
    fieldValue = value === undefined || (Array.isArray(value) && value.length === 0) ? null : value;
  }

  const handleChange = (_: ChangeEvent<any>, selectedOptions: any | any[]) => {
    if (!selectedOptions) {
      onChange(null, name);
      return;
    }

    if (!valueKey) {
      onChange(selectedOptions, name);
      return;
    }

    const returnValue = selectedOptions[valueKey];
    onChange(returnValue, name);
  };

  const onTextChange = (updateEvent: ChangeEvent<HTMLInputElement>) => {
    onChange(updateEvent.target.value, name);
  };

  const dropdownOptions = options ?? [];

  return (
    <Box pr={fullWidth ? undefined : 6} className={classes.box}>
      <FenTitle
        title={title}
        tooltipProps={tooltipProps}
        className={classes.title}
        required={required}
      />

      <Autocomplete
        disablePortal
        freeSolo={freeText}
        autoSelect={freeText}
        id="combo-box"
        options={dropdownOptions}
        onChange={handleChange}
        onBlur={onBlur}
        data-testid={testid}
        value={fieldValue}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              multiline={multiline}
              name={name}
              placeholder={placeholderText}
              size={"small"}
              helperText={error}
              error={!!error}
              data-testid={textFieldTestId}
              onChange={onTextChange}
            />
          );
        }}
      />
    </Box>
  );
};

export const FenInputWithHelpIcon = memo(FenInputWithHelpIconBase);
