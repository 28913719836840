import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

export const FenccConfirmationPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.boxContainer}>
      <Typography
        variant="h5"
        gutterBottom
        className={classes.textField}
        data-testid={"loading-text"}
      >
        We have recevied your request.
        <br />
        You will be redirected to the request list shortly.
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  boxContainer: {
    margin: "Auto",
    textAlign: "center",
  },
  textField: {
    fontSize: "2rem",
  },
}));
