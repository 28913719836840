import { commonColors } from "../assets/themes/defaultTheme";
import { TenantStatus } from "../constants/TenantStatus";

export const getTenantStatusChipColor = (tenantStatus?: string): string | undefined => {
  switch (tenantStatus) {
    case TenantStatus.Inactive:
      return commonColors.statusChipsMessagesOrangeWarning;
    case TenantStatus.Active:
      return commonColors.statusChipsCompleted;
    case TenantStatus.Dormant:
      return commonColors.statusChipsNew;
  }
};
