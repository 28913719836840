import React from "react";
import { FenTitle } from "@fenx/styleguide";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { commonColors } from "../../assets/themes/defaultTheme";
import { FenReadonlyInput } from "../FenReadonlyInput";

export interface IFormReviewGeneralSection {
  formik: any;
}
export const FormReviewGeneralSection = ({ formik }: IFormReviewGeneralSection): JSX.Element => {
  const classes = useStyles();
  const { values } = formik;

  return (
    <>
      <FenTitle title="General Information" variant="h3" className={classes.subHeader} />
      <Box className={classes.boxContainer}>
        <FenReadonlyInput
          label={"Requestor Name"}
          value={values.requestor}
          required
          fullWidth
          multiline
        />
        <FenReadonlyInput
          label={"Requestor Email"}
          value={values.requestorEmail}
          required
          fullWidth
          multiline
        />
        <FenReadonlyInput
          label={"Requestor Type"}
          value={values.requestType}
          required
          fullWidth
          multiline
        />
        <FenReadonlyInput
          label={"Tenant Type"}
          value={values.tenantType}
          required
          fullWidth
          multiline
        />
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  boxContainer: {
    backgroundColor: commonColors.greyBlue,
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary,
    },
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "1rem",
  },
  subHeader: {
    marginBottom: "1rem",
  },
}));
