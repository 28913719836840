import { useEffect } from "react";
import { useSessionStorage } from "react-use-storage";

const ApiConfigKey = "API_CONFIG_KEY";

export interface IFeatureFlags {
  DisableDevGuides: boolean;
}

export interface AppConfiguration {
  baseDomain: string;
  apiNames: string[];
  stsServer: string;
  featureFlags: IFeatureFlags;
  azureActiveDirectoryClientId: string;
  azureActiveDirectoryTenatnId: string;
  scopes: string[];
  cloudcontrolQueryApiRoot: string;
  cloudcontrolCommandApiRoot: string;
  redirectUri: string;
}

export function useAppConfig(): AppConfiguration | null {
  const [config, setConfig] = useSessionStorage<AppConfiguration | null>(ApiConfigKey, null);

  useEffect(() => {
    if (config !== null) {
      return;
    }

    async function loadAsync() {
      const response = await fetch("/config/config.json");
      const data = await response.json();
      setConfig(data);
    }

    loadAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return config;
}
