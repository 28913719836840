import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { FenFieldsGrid, FenHeader } from "@fenx/styleguide";
import { makeStyles } from "@material-ui/core/styles";
import { commonColors } from "../../assets/themes/defaultTheme";
import { ICloudControlService } from "../../services/ICloudControlService";
import { UserInfoViewModel } from "../../viewModels/userInfo/UserInfoViewModel";
import { FenAvatar } from "../FenAvatar";
import { FenReadonlyInput } from "../FenReadonlyInput";

export interface IProfilePageProps {
  cloudcontrolService: ICloudControlService;
}
export const ProfilePage = ({ cloudcontrolService }: IProfilePageProps): JSX.Element => {
  const { accounts } = useMsal();
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState<UserInfoViewModel>();
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (accounts.length === 0) {
          return;
        }
        const userInformation = await cloudcontrolService.getUserInfo(accounts[0].localAccountId);
        setUserInfo(userInformation);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserInfo();
  }, [accounts, cloudcontrolService]);

  return (
    <div className={classes.root}>
      <FenHeader title="User Profile" className={classes.headerProps} variant="h2" />
      <div className={classes.content}>
        <FenFieldsGrid columns={3}>
          <FenAvatar className={classes.bigAvatar} cloudcontrolService={cloudcontrolService} />
          <FenFieldsGrid columns={1}>
            <FenReadonlyInput
              label="Name"
              value={userInfo?.name}
              fullWidth
              data-testid="profileName"
              gridStyle={classes.grid}
              labelStyle={classes.label}
              className={classes.textField}
            />
            <FenReadonlyInput
              label="User ID"
              value={userInfo?.id}
              data-testid="userId"
              fullWidth
              gridStyle={classes.grid}
              labelStyle={classes.label}
              className={classes.textField}
            />
          </FenFieldsGrid>
          <FenFieldsGrid columns={1}>
            <FenReadonlyInput
              label="Role"
              value={userInfo?.role}
              data-testid="role"
              fullWidth
              gridStyle={classes.grid}
              labelStyle={classes.label}
              className={classes.textField}
            />
            <FenReadonlyInput
              label="E-Mail"
              value={userInfo?.email}
              data-testid="e-mail"
              fullWidth
              gridStyle={classes.grid}
              labelStyle={classes.label}
              className={classes.textField}
            />
          </FenFieldsGrid>
        </FenFieldsGrid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "1px 1px 5px 0 rgba(74, 141, 211, 0.4)",
  },

  content: {
    padding: "3rem",
    fontSize: "1rem",
    fontWeight: 800,
    lineHeight: 1.5,
  },
  bigAvatar: {
    width: "9rem",
    height: "9rem",
    fontSize: "3rem",
  },
  headerProps: {
    boxShadow: "none",
  },
  label: {
    fontSize: "1.1rem",
    color: commonColors.primary,
  },
  grid: {
    fontWeight: 800,
  },
  textField: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary, // (default alpha is 0.38)
    },
  },
}));
