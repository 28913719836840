import React, { memo } from "react";
import { FenLabel } from "@fenx/styleguide/components/FenLabel";
import { Grid } from "@material-ui/core";
import { FenInput } from "./FenInput";

/**
 * FenReadonlyInput properties.
 */
export interface FenReadonlyInputProps {
  /** Label to be shown alongside component. */
  label: string;
  /** Selected value. */
  value: any;
  /** Display required indicator. */
  required?: boolean;
  /** Name attribute of the `input` element. */
  name?: string;
  /** If true, the input will take all horizontally available space. */
  fullWidth?: boolean;
  /** Attribute that test scripts will be able to find this element  */
  "data-testid"?: string;
  /** Number of rows to display when multiline option is set to true.*/
  rows?: string | number;
  /** Maximum number of rows to display when multiline option is set to true.*/
  rowsMax?: string | number;
  /** Attribute to create scroll if multiple lines present*/
  multiline?: boolean;
  className?: string;
  gridStyle?: string;
  labelStyle?: string;
}

const FenReadonlyInputBase = ({
  label,
  value,
  name,
  required,
  fullWidth,
  "data-testid": testid,
  rows,
  rowsMax,
  gridStyle,
  labelStyle,
  multiline,
  className,
}: FenReadonlyInputProps): React.ReactElement => {
  return (
    <Grid className={gridStyle} item xs={fullWidth ? undefined : 4}>
      <FenLabel className={labelStyle} label={label} required={required} />
      <FenInput
        className={className}
        fullWidth
        required={required}
        value={value || "-"}
        name={name}
        disabled={true}
        data-testid={testid}
        rows={rows}
        rowsMax={rowsMax}
        multiline={multiline}
        variant="standard"
        inputPros={{ disableUnderline: true }}
      />
    </Grid>
  );
};

export const FenReadonlyInput = memo(FenReadonlyInputBase);
