import { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom";
import Auth from "./core/auth/Auth";
import { getMsalConfig } from "./core/auth/MsalConfigProvider";
import { useAppConfig } from "./hooks/useAppConfig";
import reportWebVitals from "./reportWebVitals";
import "./assets/themes/main.css";

const AppProvider: React.FC = () => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
  const config = useAppConfig();

  useEffect(() => {
    const msal = new PublicClientApplication(getMsalConfig(config));

    const fetchMsalInstance = () => {
      setMsalInstance(msal);
    };
    fetchMsalInstance();
  }, [config]);

  if (!msalInstance) {
    return <></>;
  }
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Auth />
      </MsalProvider>
    </>
  );
};
ReactDOM.render(<AppProvider />, document.querySelector("#root"));
reportWebVitals();
