import { LookupViewModel } from "../viewModels/tenantRequestForm/LookupViewModel";
import { SelectListItemViewModel } from "../viewModels/tenantRequestForm/SelectListItemViewModel";

export const getSpecificSelectListItemViewModel = (
  staticLookup: LookupViewModel,
  key: string
): SelectListItemViewModel[] => {
  return staticLookup?.List.find((x) => x.Key?.toUpperCase() === key.toUpperCase())?.Value ?? [];
};

export const getDisplayText = (option: string, staticLookup: LookupViewModel, key: string): any => {
  return staticLookup?.List.find((y) => y.Key?.toUpperCase() === key.toUpperCase())?.Value.find(
    (j) => j.Value === option
  );
};

export const convertToReviewPageFormat = (
  staticLookup: LookupViewModel,
  key: string,
  value: string | string[]
): string => {
  if (Array.isArray(value)) {
    const textList = value.map((x) => getDisplayText(x, staticLookup, key)?.Text);
    return textList.join("\r\n");
  }
  return getDisplayText(value, staticLookup, key)?.Text ?? "-";
};

export const getDisplayValue = (
  options: any[],
  selectedValue: boolean | string | string[],
  valueKey?: string,
  displayKey?: string,
  multiple = false,
  emptyValuePlaceHolder = "-"
): string => {
  if (!options || options.length === 0) {
    return emptyValuePlaceHolder;
  }

  if (!selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0)) {
    return emptyValuePlaceHolder;
  }

  if (valueKey && displayKey) {
    let selectedValueList: string[] = [];

    if (typeof selectedValue === "string" || typeof selectedValue === "boolean") {
      selectedValueList.push(selectedValue.toString());
    } else {
      selectedValueList = selectedValue;
    }

    const displayValues = options
      .filter((x) => selectedValueList.includes(x[valueKey]))
      .map((v) => v[displayKey]);

    return displayValues.join(", ");
  }

  if (!multiple && !valueKey && Array.isArray(selectedValue)) {
    return selectedValue.join(", ");
  }

  if (!multiple && !valueKey && typeof selectedValue == "string") {
    return selectedValue;
  }

  return selectedValue.toString();
};
