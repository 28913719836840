import React, { useEffect } from "react";
import { UserManager } from "oidc-client";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";

interface ILogoutCallbackProps {
  userManager: UserManager;
}

export const LogoutCallback: React.FC<ILogoutCallbackProps> = ({
  userManager,
}: ILogoutCallbackProps) => {
  const history = useHistory();

  useEffect(() => {
    userManager
      .signoutCallback()
      .then(() => {
        history.push(routes.login);
      })
      .catch(() => {
        history.push(routes.login);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
