import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { commonColors } from "../../../assets/themes/defaultTheme";
import { StyledFenLink } from "../../../components/StyledFenLink";
import { RequestType } from "../../../constants/RequestType";
import { UrgencyStatus } from "../../../constants/UrgencyStatus";
import { portalRequestDetailsUrl, tenantRequestDetailsUrl } from "../../../utils/routeUtil";

export interface TenantRequestIdCellProps {
  tenantRequestId: string;
  urgent: string;
  requestType: string;
}

export const TenantRequestIdCell = ({
  tenantRequestId,
  urgent,
  requestType,
}: TenantRequestIdCellProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    chip: {
      color: commonColors.white,
      backgroundColor: commonColors.chipOrangeBackground,
      marginLeft: 12,
      padding: 3,
      height: 20,
      fontWeight: 600,
      textAlign: "center",
      fontSize: "0.7rem",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <StyledFenLink
        to={
          requestType === RequestType.PortalCreation
            ? portalRequestDetailsUrl(tenantRequestId)
            : tenantRequestDetailsUrl(tenantRequestId)
        }
        text={tenantRequestId}
      />
      {urgent === UrgencyStatus.Urgent && (
        <Chip label={urgent} className={classes.chip} size="small" />
      )}
    </>
  );
};
