import { HostPortConfig } from "fenergo-nebula-cloudcontrol-query-api";
import { HostPortConfigViewlModel } from "../viewModels/tenant/tenantConfig/HostPortConfigViewlModel";

export const mapToHostPortConfigViewModel = (dto?: HostPortConfig): HostPortConfigViewlModel => {
  const viewModel = new HostPortConfigViewlModel();
  if (dto === undefined) {
    return viewModel;
  }
  viewModel.host = dto?.host;
  viewModel.port = dto?.port;
  return viewModel;
};

export const mapToHostPortConfigDto = (viewModel?: HostPortConfigViewlModel): HostPortConfig => ({
  host: viewModel?.host,
  port: viewModel?.port,
});
