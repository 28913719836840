import React, { useEffect, useState } from "react";
import { handleSuccess, handleError } from "@fenx/core";
import {
  CustomAvatar,
  EnhancedDataTable,
  FenButton,
  FenFieldsGrid,
  FenSpinner,
  FenTextInput,
} from "@fenx/styleguide";
import { FenLabel } from "@fenx/styleguide/components/FenLabel";
import { Box, Chip } from "@material-ui/core";
import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { FormikTouched, setNestedObjectValues, useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { ReactComponent as EditIcon } from "../../assets/images/editIcon.svg";
import { commonColors } from "../../assets/themes/defaultTheme";
import { Department } from "../../constants/Department";
import { LookupType } from "../../constants/LookupType";
import { TenantOwnerType } from "../../constants/TenantOwnerType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { CloudControlRoles } from "../../core/auth/CloudControlRoles";
import { isUserInRoles } from "../../core/auth/roleHelper";
import { getIdToken } from "../../core/auth/tokenHelper";
import { ICloudControlService } from "../../services/ICloudControlService";
import {
  defaultFormikDropdownFieldWithErrors,
  formikFieldWithErrors,
} from "../../utils/formikFieldHelper";
import { getSpecificSelectListItemViewModel } from "../../utils/lookupHelper";
import { getSelectItemSafeName } from "../../utils/stringHelper";
import { MapToTenantType } from "../../utils/TenantTypeMapper";
import { getTenantOwnerViewModelValidationSchema } from "../../utils/yupHelper";
import { getTenantSummaryTableColumns } from "../../viewModels/tenant/columns/TenantColumns";
import { TenantEnvironmentViewModel } from "../../viewModels/tenant/TenantEnvironmentViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { FenInput } from "../FenInput";
import { FenReadonlyInput } from "../FenReadonlyInput";
import { EditableFieldDropdown } from "./EditableFieldDropdown";
export interface ITenantOwnerProfileProps {
  cloudcontrolService: ICloudControlService;
}

export const TenantOwnerProfile = ({
  cloudcontrolService,
}: ITenantOwnerProfileProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [staticLookup, setStaticLookup] = useState<LookupViewModel>(new LookupViewModel());
  const [contractSignedOptionLookup, setContractSignedOptionLookup] = useState<LookupViewModel>(
    new LookupViewModel()
  );
  const classes = useStyles();
  const [tenantOwner, setTenantOwner] = useState<TenantOwnerViewModel>();
  const [isEditable, setEditable] = useState<boolean>(false);

  const { id } = useParams<{
    id: string;
  }>();

  const initialValues = tenantOwner as TenantOwnerViewModel;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: () =>
      Yup.lazy((formikValues: any) => getTenantOwnerViewModelValidationSchema(formikValues)),
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (submitValues: TenantOwnerViewModel) => {
      if (tenantOwner?.id) {
        try {
          await cloudcontrolService.updateTenantOwnerDetails(tenantOwner.id, submitValues);
          handleSuccess("Changes saved");
          setEditable(false);
        } catch (error: any) {
          handleError("Error saving data: ", error);
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const { values, submitForm, setFieldValue, errors, touched } = formik;

  const reviewForm = async () => {
    if (isFormValid) {
      setFieldValue("hasContractBeenSentToCBO", tenantOwner?.hasContractBeenSentToCBO);
      setIsLoading(true);
      submitForm();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!tenantOwner) {
        try {
          const tenantOwnerViewModel = await cloudcontrolService.getTenantOwnerById(id);
          setTenantOwner(tenantOwnerViewModel);
          setEditable(false);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const getStaticDepartmentLookup = async () => {
      if (tenantOwner?.type) {
        try {
          const lookupResponse = await cloudcontrolService.getStaticLookup(
            MapToTenantType(tenantOwner?.type),
            YesNoQuestion.No
          );
          setStaticLookup(lookupResponse);
          setEditable(false);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const getStaticClientLookup = async () => {
      if (tenantOwner?.type === TenantOwnerType.Client) {
        try {
          const lookupResponse = await cloudcontrolService.getStaticLookup(
            MapToTenantType(tenantOwner?.type),
            YesNoQuestion.Yes
          );
          setContractSignedOptionLookup(lookupResponse);
          setEditable(false);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
    getStaticDepartmentLookup();
    getStaticClientLookup();
  }, [cloudcontrolService, id, tenantOwner]);

  if (isLoading) {
    return <FenSpinner backdrop={true} />;
  }

  const handleEditButton = async () => {
    setEditable(true);
    const initialFormErrors = await formik.validateForm();
    if (Object.keys(initialFormErrors).length === 0) {
      setIsFormValid(true);
    }
  };

  const handlePropertyChange = async (formikValue: any, propertyName: string) => {
    await setFieldValue(propertyName, formikValue);
    const formErrors = await formik.validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
      formik.setTouched(
        setNestedObjectValues<FormikTouched<TenantOwnerViewModel>>(formErrors, true)
      );
    }
  };

  const isClientType = values?.type === TenantOwnerType.Client;

  const onChangeCommercialCoverage = (formikValue: any, propertyName: string) => {
    if (
      !formikValue ||
      (formikValue === YesNoQuestion.No && values?.department === Department.Client)
    ) {
      values.department = "";
    } else if (formikValue === YesNoQuestion.Yes) {
      values.department = Department.Client;
    }
    handlePropertyChange(formikValue, propertyName);
  };

  function showError() {
    return touched.keyContact ? errors.keyContact : "";
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.headline}>
          <CustomAvatar className={classes.clientAvatar} name={tenantOwner?.name || "-"} />
          <h1> {tenantOwner?.name} </h1>
          {isClientType && (
            <Chip label={`${tenantOwner?.type}`} className={classes.chip} size="small" />
          )}
          {!isEditable && authenticateRole() && (
            <MuiIconButton
              aria-label="Edit"
              data-testid="edit-button"
              onClick={handleEditButton}
              component={EditIcon}
              className={classes.buttonStyle}
            />
          )}
          {isEditable && (
            <FenButton
              onClick={reviewForm}
              data-testid="save-button"
              text={"Save"}
              disabled={!isFormValid}
              className={classes.saveButton}
            />
          )}
        </div>
        <h2> Details </h2>
        <FenFieldsGrid columns={3}>
          <FenFieldsGrid columns={1}>
            <Box className={classes.root}>
              <FenReadonlyInput
                label={`${tenantOwner?.type} Name`}
                value={tenantOwner?.name}
                fullWidth
                data-testid="tenantOwnerName"
                gridStyle={classes.boxStyle}
                labelStyle={classes.label}
                className={classes.text}
              />
              {isClientType && (
                <Box>
                  <FenLabel
                    label="Business Line"
                    data-testid="business-line-label"
                    className={classes.label}
                  />
                  <EditableFieldDropdown
                    label={""}
                    disabled={!isEditable}
                    readInputclassName={classes.textValue}
                    className={classes.infofield}
                    options={getSpecificSelectListItemViewModel(
                      staticLookup,
                      LookupType.BusinessLine
                    )}
                    selectedValue={values.businessLines ?? ""}
                    name={"businessLines"}
                    errors={touched.businessLines ? errors.businessLines : ""}
                    multiple
                    data-testid="business-line"
                    fullWidth
                    valueKey="Value"
                    displayKey="Text"
                    getOptionLabel={(x) => getSelectItemSafeName(x)}
                    {...defaultFormikDropdownFieldWithErrors(
                      formik,
                      "businessLines",
                      handlePropertyChange
                    )}
                  />
                </Box>
              )}
              <Box className={classes.boxStyle}>
                <FenLabel
                  label={!isClientType ? "Department" : "Cost Allocation"}
                  data-testid="department"
                  className={classes.label}
                />
                <EditableFieldDropdown
                  label={""}
                  disabled={!isEditable}
                  readInputclassName={classes.textValue}
                  selectedValue={values.department ?? ""}
                  fullWidth
                  name="department"
                  options={
                    values?.hasContractBeenSigned === YesNoQuestion.No || !isClientType
                      ? getSpecificSelectListItemViewModel(staticLookup, LookupType.Department)
                      : getSpecificSelectListItemViewModel(
                          contractSignedOptionLookup,
                          LookupType.Department
                        )
                  }
                  valueKey="Value"
                  displayKey="Text"
                  getOptionLabel={(x) => getSelectItemSafeName(x)}
                  data-testid="department"
                  errors={touched.department ? errors.department : ""}
                  {...defaultFormikDropdownFieldWithErrors(
                    formik,
                    "department",
                    handlePropertyChange
                  )}
                />
              </Box>
            </Box>
          </FenFieldsGrid>
          <FenFieldsGrid columns={1}>
            <Box>
              <FenReadonlyInput
                label={`${tenantOwner?.type} ID`}
                value={tenantOwner?.id}
                data-testid="tenantOwnerId"
                fullWidth
                gridStyle={classes.boxStyle}
                labelStyle={classes.label}
                className={classes.text}
              />
              {isClientType && (
                <Box className={classes.boxStyle}>
                  <FenLabel
                    label="Customer Size"
                    data-testid="customer-size"
                    className={classes.label}
                  />
                  <EditableFieldDropdown
                    label={""}
                    disabled={!isEditable}
                    readInputclassName={classes.textValue}
                    selectedValue={values.customerSize ?? ""}
                    options={getSpecificSelectListItemViewModel(
                      staticLookup,
                      LookupType.CustomerSize
                    )}
                    name="customerSize"
                    data-testid="customer-size"
                    fullWidth
                    valueKey="Value"
                    displayKey="Text"
                    getOptionLabel={(x) => getSelectItemSafeName(x)}
                    errors={touched.customerSize ? errors.customerSize : ""}
                    {...defaultFormikDropdownFieldWithErrors(
                      formik,
                      "customerSize",
                      handlePropertyChange
                    )}
                  />
                </Box>
              )}
            </Box>
          </FenFieldsGrid>
          <FenFieldsGrid columns={1}>
            <Box>
              <Box className={classes.boxStyle}>
                <FenLabel
                  label="Fenergo Key Contact"
                  data-testid="key-contact"
                  className={classes.label}
                />
                {isEditable ? (
                  <FenTextInput
                    fullWidth
                    name="keyContact"
                    required={false}
                    data-testid="keyContact"
                    className={""}
                    label={""}
                    {...formikFieldWithErrors(formik, "keyContact")}
                    error={showError()}
                    onChange={(value) => handlePropertyChange(value.target.value, "keyContact")}
                  />
                ) : (
                  <FenInput
                    className={classes.textValue}
                    fullWidth
                    value={values?.keyContact || "-"}
                    multiline={true}
                    variant="standard"
                    inputPros={{ disableUnderline: true }}
                  />
                )}
              </Box>
              {isClientType && (
                <Box className={classes.boxStyle}>
                  <FenLabel
                    label="Commercial Coverage"
                    data-testid="commercial-coverage"
                    className={classes.label}
                  />
                  <EditableFieldDropdown
                    label={""}
                    disabled={!isEditable}
                    readInputclassName={classes.textValue}
                    selectedValue={values.hasContractBeenSigned ?? ""}
                    options={getSpecificSelectListItemViewModel(
                      staticLookup,
                      LookupType.YesNoQuestion
                    )}
                    name="hasContractBeenSigned"
                    fullWidth
                    data-testid="contract-signed"
                    valueKey="Value"
                    displayKey="Text"
                    getOptionLabel={(x) => getSelectItemSafeName(x)}
                    errors={touched.hasContractBeenSigned ? errors.hasContractBeenSigned : ""}
                    {...defaultFormikDropdownFieldWithErrors(
                      formik,
                      "hasContractBeenSigned",
                      onChangeCommercialCoverage
                    )}
                  />
                </Box>
              )}
            </Box>
          </FenFieldsGrid>
        </FenFieldsGrid>
      </div>
      <EnhancedDataTable<TenantEnvironmentViewModel>
        columns={getTenantSummaryTableColumns(isLoading)}
        data={tenantOwner?.tenantsSummary || []}
        tableTitle="Tenant List"
        enableGlobalFilter
        unlimitedHeight
        defaultRowsPerPage={10}
      />
    </div>
  );
};

const authenticateRole = (): boolean => {
  return isUserInRoles(getIdToken(), [CloudControlRoles.Admin]);
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
  },
  content: {
    padding: "3rem",
    fontSize: "1rem",
    fontWeight: 800,
    lineHeight: 1.5,
  },
  clientAvatar: {
    width: "9rem",
    height: "9rem",
    fontSize: "3rem",
    marginRight: "3rem",
  },
  headline: {
    display: "flex",
    alignItems: "center",
  },
  chip: {
    color: commonColors.white,
    backgroundColor: commonColors.chipOrangeBackground,
    padding: 10,
    marginLeft: 12,
  },
  label: {
    fontSize: "1.1rem",
    color: commonColors.primary,
    fontWeight: 800,
  },
  textField: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary,
      marginBottom: "2rem",
    },
  },
  textValue: {
    marginBottom: "3.438rem",
  },
  saveButton: {
    margin: "1rem",
    backgroundColor: commonColors.bluesPrimaryBlue,
    color: commonColors.white,
    "&:hover,&:focus": {
      backgroundColor: commonColors.bluesPrimaryBlue,
    },
    "&:disabled": {
      color: commonColors.white,
      backgroundColor: commonColors.greysLightGrey1,
      borderColor: commonColors.white,
    },
  },
  buttonStyle: {
    width: "3.5rem",
    height: "3.5rem",
  },
  text: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.primary,
    },
  },
  infofield: {
    marginBottom: "2.5rem",
  },
  boxStyle: {
    height: "7.5rem",
  },
}));
