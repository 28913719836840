import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const appTypography: TypographyOptions = {
  fontFamily: "NunitoSans, sans-serif",
  h1: {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 500,
    lineHeight: 1.33,
  },
  h2: {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 500,
  },
  h3: {
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: 1.33,
  },
  h4: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1.33,
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
};
