import { ApproverViewModel } from "../../viewModels/approver/ApproverViewModel";

export const getFilteredApprovers = (
  allApprovers: ApproverViewModel[],
  department: string
): ApproverViewModel[] => {
  if (!department || allApprovers.length === 0) {
    return [];
  }

  return allApprovers.filter((x) => x.Departments.find((v) => v === department));
};
