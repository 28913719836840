export class TenantFenxServicesViewModel {
  serviceId?: string;
  serviceName?: string;
  serviceStatus?: string;
  serviceType?: string;

  public constructor(
    id: string | undefined,
    name: string | undefined,
    serviceStatus: string | undefined,
    type: string | undefined
  ) {
    this.serviceId = id;
    this.serviceName = name;
    this.serviceStatus = serviceStatus;
    this.serviceType = type;
  }
}
