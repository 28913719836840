import { LogLevel } from "@azure/msal-browser";
import { AppConfiguration } from "../../hooks/useAppConfig";

/* istanbul ignore next */
export const getMsalConfig = (config: AppConfiguration | null): any => ({
  auth: {
    clientId: config?.azureActiveDirectoryClientId || "",
    authority: `https://login.microsoftonline.com/${config?.azureActiveDirectoryTenatnId}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        if (level !== LogLevel.Error) {
          return;
        }
        console.error(message);
      },
    },
  },
});
