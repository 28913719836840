import { RedirectRequest } from "@azure/msal-browser";

export class AuthConfiguration {
  azureActiveDirectoryClientId: string;
  azureActiveDirectoryTenatnId: string;
  scopes: string[];
  redirectUri: string;

  constructor(
    azureActiveDirectoryClientId: string | undefined,
    azureActiveDirectoryTenatnId: string | undefined,
    scopes: string[] | undefined,
    redirectUri: string | undefined
  ) {
    this.azureActiveDirectoryClientId = azureActiveDirectoryClientId || "";
    this.azureActiveDirectoryTenatnId = azureActiveDirectoryTenatnId || "";
    this.scopes = scopes || [];
    this.redirectUri = redirectUri || "";
  }

  get getScopes(): string[] {
    return this.scopes.map((s) => `api://${this.azureActiveDirectoryClientId}/${s}`);
  }

  get getLoginRequest(): RedirectRequest {
    return {
      scopes: this.getScopes,
      prompt: "select_account",
      redirectUri: this.redirectUri,
    };
  }
}
