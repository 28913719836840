import React from "react";
import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../assets/images/logo.svg";
import { FenAvatar } from "../components/FenAvatar";
import { ICloudControlService } from "../services/ICloudControlService";

interface IHeaderProps extends RouteComponentProps<any> {
  notificationCentreApiRoot: string;
  cloudcontrolService: ICloudControlService;
}

const HeaderComponent: React.FC<IHeaderProps> = ({
  cloudcontrolService,
}: IHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <MuiToolbar className={classes.toolbar}>
        <MuiIconButton aria-label="logo" href="/home">
          <LogoIcon className={classes.logoContainer} />
        </MuiIconButton>
        <MuiLink variant="h1" underline="none" color="inherit" href="/home">
          Cloud Control
        </MuiLink>
        <Box className={classes.right}>
          <FenAvatar
            className={classes.fenAvatar}
            cloudcontrolService={cloudcontrolService}
            button={true}
          />
        </Box>
      </MuiToolbar>
    </AppBar>
  );
};

export const Header = withRouter(HeaderComponent);

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "1px 1px 5px 0 rgba(74, 141, 211, 0.4)",
    maxHeight: "3.438rem",
  },
  toolbar: {
    justifyContent: "space-between",
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "3.438rem",

    "& a": {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  fenAvatar: {
    width: "2.2rem",
    height: "2.2rem",
  },
  logoContainer: {
    width: 35,
    height: 35,
  },
}));
