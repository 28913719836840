import React, { useEffect, useMemo, useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { notifications$ } from "@fenx/core";
import { FenSpinner } from "@fenx/styleguide";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useSnackbar } from "notistack";
import { User } from "oidc-client";
import { ThemeProvider } from "../assets/themes/ThemeProvider";
import { AppConfiguration, useAppConfig } from "../hooks/useAppConfig";
import { CloudControlService } from "../services/CloudControlService";
import { OidcAuthService } from "../services/OidcAuthService";
import { AuthConfiguration } from "./auth/AuthConfiguration";
import { saveAccessToken, saveIdToken, getAccessToken } from "./auth/tokenHelper";
import { Content } from "./Content";
import { Header } from "./Header";
import { SideNav } from "./SideNav";

interface IMainProps {
  user: User | null;
  authService: OidcAuthService;
  userInfo: AccountInfo | null;
}

const createServices = (appConfiguration: AppConfiguration | null, accessToken: string) => {
  if (!appConfiguration) {
    return null;
  }
  const cloudcontrolCommandApiRoot = `${appConfiguration.baseDomain}${appConfiguration.cloudcontrolCommandApiRoot}`;
  const cloudcontrolqueryApiRoot = `${appConfiguration.baseDomain}${appConfiguration.cloudcontrolQueryApiRoot}`;

  return {
    cloudcontrolService: new CloudControlService(
      cloudcontrolCommandApiRoot,
      cloudcontrolqueryApiRoot,
      accessToken
    ),
  };
};

export const Main: React.FC<IMainProps> = ({ authService, user, userInfo }: IMainProps) => {
  const appConfig = useAppConfig();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string>(getAccessToken() ?? "");
  const [acquireTokenCompleted, setAcquireTokenCompleted] = useState<boolean>(false);
  const authConfig = new AuthConfiguration(
    appConfig?.azureActiveDirectoryClientId,
    appConfig?.azureActiveDirectoryTenatnId,
    appConfig?.scopes,
    appConfig?.redirectUri
  );
  const intervalPeriodInMs = 30_000;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const subNotifications = notifications$.subscribe((notification) => {
      if (notification.error) {
        console.error(notification.error);
      }
      enqueueSnackbar(notification.text, {
        variant: notification.type,
        autoHideDuration: notification.autoHideDuration,
        style: { whiteSpace: "pre-line" },
      });
    });

    return () => {
      subNotifications.unsubscribe();
    };
  }, [enqueueSnackbar]);

  useEffect(() => {
    const requestToken = () => {
      if (!accounts || accounts.length === 0) {
        setAcquireTokenCompleted(true);
        return;
      }

      const tokenRequest = {
        scopes: authConfig.getScopes,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(tokenRequest)
        .then((tokenResponse: { idToken: any; accessToken: any }) => {
          saveIdToken(tokenResponse.idToken);
          saveAccessToken(tokenResponse.accessToken);
          setAccessToken(tokenResponse.accessToken);
          setAcquireTokenCompleted(true);
        })
        .catch((error: any) => {
          console.error("Error fetching token from azure", error);
          instance.acquireTokenRedirect(authConfig.getLoginRequest);
        });
    };

    requestToken();
    const interval = setInterval(() => {
      requestToken();
    }, intervalPeriodInMs);

    return () => clearInterval(interval);
  }, [accounts, authConfig.getLoginRequest, authConfig.getScopes, instance]);

  const services = useMemo(() => createServices(appConfig, accessToken), [accessToken, appConfig]);

  if (!services || !acquireTokenCompleted) {
    return <FenSpinner />;
  }

  return (
    <ThemeProvider>
      <CssBaseline />
      <Header notificationCentreApiRoot={""} cloudcontrolService={services.cloudcontrolService} />
      <SideNav />
      <Content
        authService={authService}
        user={user}
        userInfo={userInfo}
        cloudcontrolService={services.cloudcontrolService}
      />
    </ThemeProvider>
  );
};
