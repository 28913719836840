import React, { useEffect, useState } from "react";
import { commonColors, CustomAvatar, EnhancedDataTable, FenSpinner } from "@fenx/styleguide";
import { makeStyles, Typography, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { LookupType } from "../../constants/LookupType";
import { ParamTypes } from "../../constants/paramTypes";
import { TenantOwnerType } from "../../constants/TenantOwnerType";
import { YesNoQuestion } from "../../constants/YesNoQuestion";
import { ICloudControlService } from "../../services";
import { ToFormatteddString } from "../../utils/DateHelper";
import { getDisplayText } from "../../utils/lookupHelper";
import { tenantOwnerProfileUrl } from "../../utils/routeUtil";
import {
  sortByFenxServiceStatusThenByName,
  filterByInternalUserEmail,
  filterByExternalUserEmail,
} from "../../utils/sort";
import { MapToTenantType } from "../../utils/TenantTypeMapper";
import { getFenxServicesTableColumns } from "../../viewModels/tenant/columns/FenxServiceColumns";
import { getUserManagementTableColumns } from "../../viewModels/tenant/columns/TenantUserColumns";
import { TenantFenxServicesViewModel } from "../../viewModels/tenant/tenantConfig/TenantFenxServicesViewModel";
import { TenantEnvironmentViewModel } from "../../viewModels/tenant/TenantEnvironmentViewModel";
import { TenantUserExtensionViewModel } from "../../viewModels/tenant/tenantUser/TenantUserExtensionViewModel";
import { TenantOwnerViewModel } from "../../viewModels/tenantOwner/TenantOwnerViewModel";
import { LookupViewModel } from "../../viewModels/tenantRequestForm/LookupViewModel";
import { AccordionWrapper } from "../AccordionWrapper";
import { FenccFieldsGrid } from "../FenccFieldsGrid";
import { FenccLink } from "../FenccLink";
import { FenReadonlyInput } from "../FenReadonlyInput";
import { TenantStatusChip } from "../TenantStatusChip";

export interface ITenantProfilePageProps {
  cloudcontrolService: ICloudControlService;
}

export const TenantProfilePage = ({
  cloudcontrolService,
}: ITenantProfilePageProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [tenantEnvironment, setTenantEnvironment] = useState<TenantEnvironmentViewModel>();
  const [tenantOwner, setTenantOwner] = useState<TenantOwnerViewModel>();
  const [tenantUsers, setTenantUsers] = useState<TenantUserExtensionViewModel[]>();
  const [staticLookup, setStaticLookup] = useState<LookupViewModel>(new LookupViewModel());
  const { id } = useParams<ParamTypes>();
  const classes = useStyles(tenantEnvironment);

  useEffect(() => {
    setIsLoading(true);
    const fetchTenantProfileById = async () => {
      try {
        const tenantEnvironmentData = await cloudcontrolService.getTenantById(id);
        setTenantEnvironment(tenantEnvironmentData[0]);
        if (tenantEnvironment?.tenantOwnerId) {
          const tenantOwnerData = await cloudcontrolService.getTenantOwnerById(
            tenantEnvironment.tenantOwnerId
          );
          setTenantOwner(tenantOwnerData);
        }
        if (tenantEnvironment?.environmentId && tenantEnvironment?.tenantId) {
          const tenantUserData = await cloudcontrolService.getUsersByTenant(
            tenantEnvironment.tenantId,
            tenantEnvironment.environmentId
          );
          setTenantUsers(tenantUserData);
        }
        if (tenantEnvironmentData.length === 0) {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getStaticLookup = async () => {
      if (tenantOwner?.type) {
        try {
          const lookupResponse = await cloudcontrolService.getStaticLookup(
            MapToTenantType(tenantOwner?.type),
            tenantOwner?.hasContractBeenSigned ?? YesNoQuestion.No
          );
          setStaticLookup(lookupResponse);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTenantProfileById();
    getStaticLookup();
  }, [
    cloudcontrolService,
    id,
    tenantEnvironment?.environmentId,
    tenantEnvironment?.tenantId,
    tenantEnvironment?.tenantOwnerId,
    tenantOwner?.hasContractBeenSigned,
    tenantOwner?.type,
  ]);

  if (isLoading) {
    return <FenSpinner />;
  }

  function showDepartmentLabel() {
    return tenantOwner?.department
      ? getDisplayText(tenantOwner?.department, staticLookup, LookupType.Department)?.Text
      : "";
  }

  const externalUsers = filterByExternalUserEmail(tenantUsers);
  const internalUsers = filterByInternalUserEmail(tenantUsers);

  return (
    <>
      <Paper className={classes.tenantDetailsMain}>
        <div className={classes.avatarArea}>
          <CustomAvatar
            className={classes.tenantAvatar}
            name={tenantEnvironment?.tenantName ?? "-"}
          />
          <Typography className={classes.tenantName}>{tenantEnvironment?.tenantName}</Typography>
          {tenantEnvironment?.status && (
            <TenantStatusChip tenantStatus={tenantEnvironment.status} data-testid="status-chip" />
          )}
        </div>
        <Typography variant="h2" className={classes.fieldGridHeader}>
          Tenant Details
        </Typography>
        <FenccFieldsGrid columns={3} className={classes.tenantGrid}>
          <FenReadonlyInput
            label="Tenant ID:"
            value={tenantEnvironment?.tenantId}
            data-testid="tenant-id"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
            multiline
          />
          <FenReadonlyInput
            label="Purpose:"
            value={tenantEnvironment?.purpose}
            data-testid="testing-purpose"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
          />
          <FenReadonlyInput
            label="Environment:"
            value={tenantEnvironment?.environment}
            data-testid="tenant-id"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
          />
          <FenReadonlyInput
            label="AWS Region:"
            value={tenantEnvironment?.awsRegion}
            data-testid="testing-purpose"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
            multiline
          />
          <FenccLink
            label="Team:"
            value={tenantOwner?.name}
            data-testid="team-id"
            fullWidth
            labelStyle={classes.label}
            redirectUrl={tenantOwner?.id ? tenantOwnerProfileUrl(tenantOwner.id) : ""}
          />
          {(tenantOwner?.type === TenantOwnerType.Team || TenantOwnerType.Partner) && (
            <FenReadonlyInput
              label="Department:"
              value={showDepartmentLabel()}
              data-testid="department-test-id"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField2}
            />
          )}
          <FenReadonlyInput
            label="Created On:"
            value={ToFormatteddString(tenantEnvironment?.createdOn)}
            data-testid="created-on-test-id"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
          />
          <FenReadonlyInput
            label="Key Contact - Name:"
            value={"-"}
            data-testid="key-contact-name-test-id"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
          />
          <FenReadonlyInput
            label="Key Contact - Email:"
            value={"-"}
            data-testid="key-contact-email-test-id"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
          />
          <FenReadonlyInput
            label="Last Activity:"
            value={tenantEnvironment?.lastActivityString}
            data-testid="last-activity-test-id"
            fullWidth
            labelStyle={classes.label}
            className={classes.textField}
            multiline
          />
        </FenccFieldsGrid>
      </Paper>
      <Paper elevation={2} className={classes.backendCard}>
        <AccordionWrapper key={"fenxServices"} title={"Fen-X Services"}>
          <EnhancedDataTable<TenantFenxServicesViewModel>
            columns={getFenxServicesTableColumns()}
            data={sortByFenxServiceStatusThenByName(tenantEnvironment?.tenantFenxServices)}
            enableGlobalFilter
            unlimitedHeight
            disablePaging
          />
        </AccordionWrapper>
        <AccordionWrapper
          key={"intuserManagement"}
          title={`Internal Users (${internalUsers?.length})`}
        >
          <EnhancedDataTable<TenantUserExtensionViewModel>
            columns={getUserManagementTableColumns()}
            data={internalUsers}
            enableGlobalFilter
            unlimitedHeight
            defaultRowsPerPage={10}
          />
        </AccordionWrapper>
        <AccordionWrapper
          key={"extuserManagement"}
          title={`External Users (${externalUsers?.length})`}
        >
          <EnhancedDataTable<TenantUserExtensionViewModel>
            columns={getUserManagementTableColumns()}
            data={externalUsers}
            enableGlobalFilter
            unlimitedHeight
            defaultRowsPerPage={10}
          />
        </AccordionWrapper>
        <AccordionWrapper key={"backendConfig"} title={"Backend Configuration"}>
          <Typography variant="h3" className={classes.fieldGridSubHeader}>
            Event Store:
          </Typography>
          <FenccFieldsGrid columns={2} className={classes.backendGrid}>
            <FenReadonlyInput
              label="Host:"
              value={tenantEnvironment?.eventStoreConfig?.host}
              data-testid="event-store-host-id"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
              multiline
            />
            <FenReadonlyInput
              label="Port:"
              value={tenantEnvironment?.eventStoreConfig?.port}
              data-testid="event-store-port"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
            />
          </FenccFieldsGrid>
          <Typography variant="h3" className={classes.fieldGridSubHeader}>
            NeptuneDB:
          </Typography>
          <FenccFieldsGrid columns={2} className={classes.backendGrid}>
            <FenReadonlyInput
              label="Host:"
              value={tenantEnvironment?.neptuneDbConfig?.host}
              data-testid="neptunedb-host-id"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
              multiline
            />
            <FenReadonlyInput
              label="Port:"
              value={tenantEnvironment?.neptuneDbConfig?.port}
              data-testid="neptunedb-port"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
            />
          </FenccFieldsGrid>
          <Typography variant="h3" className={classes.fieldGridSubHeader}>
            ElasticSearch:
          </Typography>
          <FenccFieldsGrid columns={2} className={classes.backendGrid}>
            <FenReadonlyInput
              label="Host:"
              value={tenantEnvironment?.elasticSearchConfig?.host}
              data-testid="elasticsearch-host-id"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
              multiline
            />
            <FenReadonlyInput
              label="Port:"
              value={tenantEnvironment?.elasticSearchConfig?.port}
              data-testid="elasticsearch-port"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
            />
          </FenccFieldsGrid>
          <Typography variant="h3" className={classes.fieldGridSubHeader}>
            S3:
          </Typography>
          <FenccFieldsGrid columns={2} className={classes.backendGrid}>
            <FenReadonlyInput
              label="Document Management Bucket Name:"
              value={tenantEnvironment?.s3Config?.documentManagementBucketName}
              data-testid="s3-host-id"
              fullWidth
              labelStyle={classes.label}
              className={classes.textField}
              multiline
            />
          </FenccFieldsGrid>
        </AccordionWrapper>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(() => ({
  tenantAvatar: {
    width: "9rem",
    height: "9rem",
    fontSize: "3rem",
    marginRight: "3rem",
  },
  avatarArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "7rem",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  tenantGrid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridRowGap: "1.5rem",
    paddingLeft: "7rem",
    paddingRight: "1.5rem",
    paddingBottom: "1rem",
  },
  tenantName: {
    fontSize: "3rem",
    fontWeight: 600,
    paddingTop: "2rem",
    paddingLeft: "8rem",
  },
  tenantDetailsMain: {
    paddingLeft: "5rem",
  },
  backendCard: {
    paddingLeft: "11rem",
    paddingRight: "5rem",
    paddingBottom: "3rem",
    boxShadow: "none",
  },
  backendGrid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridRowGap: "1.5rem",
    paddingRight: "1.5rem",
    paddingBottom: "1rem",
  },
  fieldGridHeader: {
    paddingLeft: "7rem",
    paddingBottom: "1rem",
    fontWeight: 600,
  },
  fieldGridSubHeader: {
    paddingBottom: "1rem",
  },
  backendConfig: {
    marginLeft: "12rem",
    maxWidth: "75%",
    borderWidth: 0,
  },
  label: {
    fontSize: "1.1rem",
    color: commonColors.black,
  },
  textField: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.bluesPrimaryBlue, // (default alpha is 0.38)
    },
  },
  textField2: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: commonColors.bluesPrimaryBlue, // (default alpha is 0.38)
    },
    fontSize: "12rem",
  },
}));
