import React, { useEffect, useState } from "react";
import { FenSpinner } from "@fenx/styleguide";
import { useLocation } from "react-router-dom";
import { RequestType } from "../constants/RequestType";
import { TenantOwnerType } from "../constants/TenantOwnerType";
import { TenantType } from "../constants/TenantType";
import { YesNoQuestion } from "../constants/YesNoQuestion";
import { ICloudControlService } from "../services";
import { ApproverViewModel } from "../viewModels/approver/ApproverViewModel";
import { TenantOwnerViewModel } from "../viewModels/tenantOwner/TenantOwnerViewModel";
import { TenantRequestViewModel } from "../viewModels/tenantRequest/TenantRequestViewModel";
import { LookupViewModel } from "../viewModels/tenantRequestForm/LookupViewModel";
import { PortalRequestFormPage } from "./portalRequestForm/PortalRequestFormPage";
import { TenantRequestFormPage } from "./tenantRequestForm/TenantRequestFormPage";

export interface CreateFormPageProps {
  cloudcontrolService: ICloudControlService;
  requestType: string;
}

export const CreateFormPage = ({
  cloudcontrolService,
  requestType,
}: CreateFormPageProps): JSX.Element => {
  const [staticLookup, setStaticLookup] = useState<LookupViewModel>(new LookupViewModel());
  const [tenantOwners, setTenantOwners] = useState<TenantOwnerViewModel[]>([]);
  const [approver, setApprover] = useState<ApproverViewModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  const initialValues: TenantRequestViewModel =
    (location.state as TenantRequestViewModel) ?? new TenantRequestViewModel();

  useEffect(() => {
    const getStaticLookup = async () => {
      try {
        const targetTenantOwnerType: string[] =
          initialValues.tenantType === TenantType.Client
            ? [TenantOwnerType.Client, TenantOwnerType.Partner]
            : [TenantOwnerType.Team];
        const lookupResponse = await cloudcontrolService.getStaticLookup(
          initialValues.tenantType,
          YesNoQuestion.No
        );
        setStaticLookup(lookupResponse);
        const clientResponse = await cloudcontrolService.getClients();
        const approverResponse = await cloudcontrolService.getApprovers();
        setTenantOwners(
          clientResponse.filter((c) => c.type && targetTenantOwnerType.includes(c.type))
        );
        setApprover(approverResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getStaticLookup();
  }, [cloudcontrolService, initialValues.tenantType, initialValues.contractSigned]);

  if (isLoading) {
    return <FenSpinner />;
  }
  return (
    <>
      {requestType === RequestType.PortalCreation ? (
        <PortalRequestFormPage
          cloudcontrolService={cloudcontrolService}
          approver={approver}
          tenantOwners={tenantOwners}
          portalRequest={initialValues}
        />
      ) : (
        <TenantRequestFormPage
          cloudcontrolService={cloudcontrolService}
          staticLookup={staticLookup}
          tenantOwners={tenantOwners}
          approver={approver}
          tenantRequest={initialValues}
        />
      )}
    </>
  );
};
