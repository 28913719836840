import { lighten } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

/**
 * Overrides the default @material-ui color palette object
 */
export const commonColors = {
  primary: "#4e6e8b",
  secondary: "#5282B4",
  success: "#0f7864",
  info: "#256997",
  warning: "#b64f00",
  error: "#f26c6c",
  errorAlertLighter: lighten("#f26c6c", 0.16),
  successAlertLighter: lighten("#78d690", 0.16),
  white: "#ffffff",
  fenxLabelBlue: "#2382c1",
  black: "#000000",
  fenBlue: "#0070b4",
  assessibleBlue: "#0051a8",
  bluesLightBlue1: "#edf2f7",
  bluesLightBlue2: "#f8fafc",
  bluesMidBlue: "#bdcbd8",
  bluesPrimaryBlue: "#4674a4",
  bluesSecondaryBlue: "#557391",
  greysDarkGrey: "#4a5868",
  greysLightGrey1: "#e4e8ec",
  greysLightGrey2: "#edf2f6",
  inputSelectionStatesActive: "#eff8ff",
  linksBlue: "#0B79D0",
  statusChipsMessagesOrangeWarning: "#fd9c34",
  graphsBlue: "#6099d3",
  graphsGreen: "#709d83",
  graphsBrown: "#ae7474",
  graphsPurple: "#ac75c9",
  graphsOrange: "#cf8217",
  chipOrangeBackground: "#FAB87B",
  statusChipsNew: "#8da2b7",
  statusChipsInProgress: "#66b1ff",
  statusChipsCompleted: "#78d690",
  inputSelectionStatesError: "#ffeceb",
  greyBlue: "#f5f7f9",
};

const palette: PaletteOptions = {
  background: {
    default: commonColors.white,
  },
  text: {
    primary: commonColors.primary,
  },
  primary: {
    main: commonColors.primary,
  },
  secondary: {
    main: commonColors.secondary,
  },
  success: {
    main: commonColors.success,
  },
  info: {
    main: commonColors.info,
  },
  warning: {
    main: commonColors.warning,
  },
  error: {
    main: commonColors.error,
  },
  common: commonColors,
};

export interface IThemeConfig {
  palette: PaletteOptions;
}

export const defaultTheme: IThemeConfig = {
  palette,
};
