import { CellProps, Column } from "react-table";
import { TenantUserExtensionViewModel } from "../tenantUser/TenantUserExtensionViewModel";

export const getUserManagementTableColumns = (): Column<TenantUserExtensionViewModel>[] => {
  const fortyPercentWidth = "40%";
  const thirtyPercentWidth = "30%";
  return [
    tenantUserNameColumn(fortyPercentWidth),
    tenantUserTypeColumn(thirtyPercentWidth),
    tenantUserEmailColumn(thirtyPercentWidth),
  ];
};

type ColumnWidthType = number | string | undefined;

const tenantUserNameColumn = (width?: ColumnWidthType): Column<TenantUserExtensionViewModel> =>
  ({
    Header: "Name",
    accessor: "userName",
    width: width,
    Cell: ({ row }: CellProps<TenantUserExtensionViewModel>) => row.original.userName,
    sortType: "alphanumericNullable",
  } as Column<TenantUserExtensionViewModel>);

const tenantUserTypeColumn = (width?: ColumnWidthType): Column<TenantUserExtensionViewModel> =>
  ({
    Header: "Type",
    accessor: "userType",
    width: width,
    Cell: ({ row }: CellProps<TenantUserExtensionViewModel>) => row.original.userType,
    sortType: "alphanumericNullable",
  } as Column<TenantUserExtensionViewModel>);

const tenantUserEmailColumn = (width?: ColumnWidthType): Column<TenantUserExtensionViewModel> =>
  ({
    Header: "Email",
    accessor: "userEmail",
    width: width,
    Cell: ({ row }: CellProps<TenantUserExtensionViewModel>) => row.original.userEmail,
    sortType: "alphanumericNullable",
  } as Column<TenantUserExtensionViewModel>);
